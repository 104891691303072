<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Create Permission</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Create Permission</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Permission</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                        data-toggle="tooltip" title="Collapse">
                                        <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="myform">
                                    <div class="form-group">
                                        <label for="inputName">Name</label>
                                        <input type="text" id="inputName" class="form-control" formControlName="name"
                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required">Name is required</div>
                                              </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-3">
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div class="col-md-2">
                                                <label for="inputName">Create</label>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="inputName">Read</label>
                                            </div>&nbsp;
                                            <div class="col-md-2">
                                                <label for="inputName">Edit</label>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="inputName">Delete</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row" *ngFor="let item of menulist">
                                            <div class="col-md-4">
                                                <label for="inputName">{{ item.menu_name }}</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox4"
                                                    [(ngModel)]="item.isSelected3" [ngModelOptions]="{standalone: true}"
                                                    (change)="getProoduct(item.isSelected3, item,'create')">
                                            </div>
                                            <div class="col-md-2">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                    [(ngModel)]="item.isSelected" [ngModelOptions]="{standalone: true}"
                                                    (change)="getProoduct(item.isSelected, item,'read')">
                                            </div>
                                            <div class="col-md-2">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
                                                    [(ngModel)]="item.isSelected1" [ngModelOptions]="{standalone: true}"
                                                    (change)="getProoduct(item.isSelected1, item,'write')">
                                            </div>
                                            <div class="col-md-2">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3"
                                                    [(ngModel)]="item.isSelected2" [ngModelOptions]="{standalone: true}"
                                                    (change)="getProoduct(item.isSelected2, item,'delete')">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <input type="submit" value="Save Changes" class="btn btn-success" (click)="submit()">
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
</div>