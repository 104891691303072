<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <a href="#" class="brand-link">
    <img src="assets/img/caprics-logo.png" alt="Logo" class="brand-image">
    <span class="brand-text font-weight-light">&nbsp;</span>
  </a>
  <div class="sidebar">
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="assets/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
      </div>
      <div class="info">
        <a href="#" class="d-block">Administrator</a>
      </div>
    </div>
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link active">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Dashboard
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a href="#" class="nav-link active">
                <i class="far fa-circle nav-icon"></i>
                <p>Profile</p>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="logout()" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>LogOut</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-header">MASTERS</li>
        <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link active">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Application Setup
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" >
            <li class="nav-item">
              <a [routerLink]="['/ListIntelligent']" class="nav-link">
                <i class="nav-icon far fa-calendar-alt"></i>
                <p>
                  Manage Multiple Intelligence
                  <span class="badge badge-info right"></span>
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ListSkill']" class="nav-link">
                <i class="nav-icon far fa-calendar-alt"></i>
                <p>
                  Manage Skills
                  <span class="badge badge-info right"></span>
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/SubjectList']" class="nav-link">
                <i class="nav-icon far fa-calendar-alt"></i>
                <p>
                  Manage Activity Master
                  <span class="badge badge-info right"></span>
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ProjectList']" class="nav-link">
                <i class="nav-icon far fa-address-card"></i>
                <p>
                  Manage Programme Offerings
                  <span class="badge badge-info right"></span>
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/AcademicList']" class="nav-link">
                <i class="nav-icon far fa-image"></i>
                <p>
                  Manage Academic Year
                </p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link active">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Curriculum Setup
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/ListActivity']" class="nav-link">
                <i class="nav-icon far fa-user"></i>
                <p>
                  Activity Manager
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ThemeList']" class="nav-link">
                <i class="nav-icon far fa-image"></i>
                <p>
                  Theme Manager
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ListMonth']" class="nav-link">
                <i class="nav-icon far fa-address-card"></i>
                <p>
                  Monthly Planner
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ListCurriculam']" class="nav-link">
                <i class="nav-icon far fa-address-card"></i>
                <p>
                  Curriculum Planner
                </p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link active">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Organization Setup
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/ListSection']" class="nav-link">
                <i class="nav-icon far fa-image"></i>
                <p>
                  Manage Sections
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ListInstitute']" class="nav-link">
                <i class="nav-icon far fa-image"></i>
                <p>
                  Manage Institutions
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ListBranch']" class="nav-link">
                <i class="nav-icon far fa-image"></i>
                <p>
                  Manage Branches
                </p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link active">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Roles and Permissions
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/ListPermission']" class="nav-link">
                <i class="nav-icon far fa-user"></i>
                <p>
                  Manage Permissions
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ListRole']" class="nav-link">
                <i class="nav-icon far fa-user"></i>
                <p>
                  Manage Roles
                </p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/ListUser']" class="nav-link">
                <i class="nav-icon far fa-user"></i>
                <p>
                  Manage Users
                </p>
              </a>
            </li>
          </ul>
        </li>

        <!-- <li class="nav-item">
          <a [routerLink]="['/ListIntelligent']" class="nav-link">
            <i class="nav-icon far fa-calendar-alt"></i>
            <p>
              Manage Multiple Intelligence
              <span class="badge badge-info right"></span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListSkill']" class="nav-link">
            <i class="nav-icon far fa-calendar-alt"></i>
            <p>
              Manage Skills
              <span class="badge badge-info right"></span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/SubjectList']" class="nav-link">
            <i class="nav-icon far fa-calendar-alt"></i>
            <p>
              Manage Activity Master
              <span class="badge badge-info right"></span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ProjectList']" class="nav-link">
            <i class="nav-icon far fa-address-card"></i>
            <p>
              Manage Programme Offerings
              <span class="badge badge-info right"></span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/AcademicList']" class="nav-link">
            <i class="nav-icon far fa-image"></i>
            <p>
              Manage Academic Year
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ThemeList']" class="nav-link">
            <i class="nav-icon far fa-image"></i>
            <p>
              Theme Manager
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListCurriculam']" class="nav-link">
            <i class="nav-icon far fa-address-card"></i>
            <p>
              Curriculum Planner
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListMonth']" class="nav-link">
            <i class="nav-icon far fa-address-card"></i>
            <p>
              Monthly Planner
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListPermission']" class="nav-link">
            <i class="nav-icon far fa-user"></i>
            <p>
              Manage Permissions
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListRole']" class="nav-link">
            <i class="nav-icon far fa-user"></i>
            <p>
              Manage Roles
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListSection']" class="nav-link">
            <i class="nav-icon far fa-image"></i>
            <p>
              Manage Sections
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListInstitute']" class="nav-link">
            <i class="nav-icon far fa-image"></i>
            <p>
              Manage Institutions
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListBranch']" class="nav-link">
            <i class="nav-icon far fa-image"></i>
            <p>
              Manage Branches
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListUser']" class="nav-link">
            <i class="nav-icon far fa-user"></i>
            <p>
              Manage Users
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/ListActivity']" class="nav-link">
            <i class="nav-icon far fa-user"></i>
            <p>
              Activity Manager
            </p>
          </a>
        </li> -->
      </ul>
    </nav>
  </div>
</aside>