import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { swal } from 'src/app/Common-Service/swal.service';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};

@Injectable({ providedIn: 'root' })

export class api {

 private apilink: any = "/api/";
//  private apilink: any = "http://demo-nurturelab.caprics.in/admin/api/";
  //  https://cors-anywhere.herokuapp.com/

  constructor(public http: HttpClient, private swalservice: swal) {

  }

  private opts = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  apipost(data: any, mytype: any) {
    const body = JSON.stringify(data);
    console.log("request data", body);
    return this.http.post(`${this.apilink}` + mytype, body, httpOptions)
  }

  apiget(mytype: any) {
    return this.http.get(`${this.apilink}` + mytype, httpOptions)
  }
}