import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { User } from '../Model/user';

@Component({
  selector: 'app-addacademic',
  templateUrl: './addacademic.component.html',
  styleUrls: ['./addacademic.component.css']
})
export class AddacademicComponent implements OnInit {

  capricsAdminUser: User;
  userid:any; 
  myform: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router,private swalservice: swal) { 
    this.myform = this.formBuilder.group({
      code: ['', Validators.required],
      year_from: ['', Validators.required],
      year_to: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if(this.capricsAdminUser != undefined){
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  get f() {
    return this.myform.controls;
  }
 
  submit(){
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }  
    let mytype = "AddAcademic";
    let mydata = {
      "secret_key":"2020140715947422055f0dd5bd3ba8f",
      "AcademicCode": this.myform.value.code,
      "FromYear": this.myform.value.year_from,
      "ToYear": this.myform.value.year_to,
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata,mytype)
    .subscribe((data: any) => {
      console.log("AddAcademic",data);
      if(data.result == 1){
      this.swalservice.successalert(data.msg);
      this.submitted = false;
        this.myform.reset();
      }else{
       this.swalservice.warningalert(data.msg);
      }     
    });
  }

  ngOnInit(): void {
    
  }

}
