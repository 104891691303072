import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { DatePipe } from '@angular/common';
import { User } from '../Model/user';
import Swal from 'sweetalert2'

interface item {
  sno: any;
  program: any;
  programid: any;
  section: any;
  sectionid: any;
}

@Component({
  selector: 'app-addbranch',
  templateUrl: './addbranch.component.html',
  styleUrls: ['./addbranch.component.css']
})
export class AddbranchComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  myform: FormGroup;
  submitted = false;
  institutelist = [];
  sectionlist = [];
  instituteid: any;
  instituteSelect: any;
  programlist = [];
  orderItem: item;
  items: Array<item>;
  program_id: any;
  program_name: any;
  section_id: any;
  section_name: any;
  mySelect: any;
  mySelect1: any;
  selectedSection = [];
  addedSection = [];
  old_institute: any;

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, public datepipe: DatePipe, private swalservice: swal) {
    this.items = [];
    this.myform = this.formBuilder.group({
      branch: ['', Validators.required],
      branch_code: ['', Validators.required],
      institution_name: ['', Validators.required],
      start_date: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      alter_email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      address: [''],
      program: [''],
      section: ['']
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  get f() {
    return this.myform.controls;
  }
  get Email() {
    return this.myform.get('email');
  }
  get AlterEmail() {
    return this.myform.get('alter_email');
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "AddBranch";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "Branch": this.myform.value.branch,
      "Branchcode": this.myform.value.branch_code,
      "InstitutionId": this.instituteid,
      "StartDate": this.myform.value.start_date,
      "Email": this.myform.value.email,
      "AlternateEmail": this.myform.value.alter_email,
      "Phone": this.myform.value.phone,
      "Address": this.myform.value.address,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];
    this.items.forEach(item => {
      var child = {};
      child["program_id"] = item.programid;
      let sectiondata = {};
      item.section.forEach(item => {
        if (sectiondata["section_id"]) {
          sectiondata["section_id"] = sectiondata["section_id"] + "," + item.id;
        } else {
          sectiondata["section_id"] = item.id;
        }
      });
      if (item.section.length == 0) {
        sectiondata["section_id"] = "0";
      }
      child["section_id"] = sectiondata["section_id"];
      mydata["child_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddBranch", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.ngOnInit();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }

  ngOnInit(): void {
    this.myform.controls['start_date'].setValue(this.datepipe.transform(new Date(), 'dd-MM-yyyy'));
    this.myform.controls['institution_name'].setValue("");
    this.items = [];
    let mytype = "List_ActiveInstitute";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveInstitute", data);
        if (data.result == 1) {
          this.institutelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "Get_ActiveClass";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Get_ActiveClass", data);
        if (data.result == 1) {
          this.sectionlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectprogram() {
    this.program_id = this.mySelect.program_id;
    this.program_name = this.mySelect.ProgramName;
  }
  selectsection() {
    this.section_id = this.mySelect1.id;
    this.section_name = this.mySelect1.ClassCode;
  }
  selectInstitute() {
    if (this.items.length != 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'If you change the institution can removed the program details!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes remove it',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.items = [];
        } else {
          this.instituteSelect =this.old_institute +": Object";
        }
      });
    } else {
      this.old_institute = this.instituteSelect.InstituteId;
    }
    this.instituteid = this.instituteSelect.InstituteId;
    this.programlist = [];
    let mytype = "GetInstituteByid";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "InstituteId": this.instituteid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("GetInstituteByid", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  addItems() {
    let sectiondata = {};
    // this.selectedSection.forEach(item => {
    //   if (sectiondata["section_code"]) {
    //     sectiondata["section_code"] = sectiondata["section_code"] + "," + item.ClassCode;
    //     sectiondata["section_id"] = sectiondata["section_id"] + "," + item.id;
    //   } else {
    //     sectiondata["section_code"] = item.ClassCode;
    //     sectiondata["section_id"] = item.id;
    //   }
    // });
    if (this.program_id != "") {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        program: this.program_name,
        programid: this.program_id,
        section: this.selectedSection,
        sectionid: sectiondata,
        sno: index,
      };
      this.items.push(this.orderItem);
      this.myform.controls['program'].setValue("");
      this.myform.controls['section'].setValue("");
      this.selectedSection = [];
      this.program_id = "";
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].sno === sno) {
        this.items.splice(i, 1);
      }
    }
  }
}
