<div class="wrapper">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Create User</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Create User</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary">
              <div class="card-body">
                <form [formGroup]="myform">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>First Name</label>
                        <input type="text" id="inputName" class="form-control" formControlName="firstname"
                          [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                        <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                          <div *ngIf="f.firstname.errors.required">First Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" id="inputName" class="form-control" formControlName="lastname"
                          [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                        <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                          <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>User Name</label>
                        <input type="text" id="inputName" class="form-control" formControlName="username"
                          [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                          <div *ngIf="f.username.errors.required">User Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Role Type</label>
                        <select class="form-control custom-select" formControlName="role_type"
                          [ngClass]="{ 'is-invalid': submitted && f.role_type.errors }" (change)="select_roletype()">
                          <option selected disabled>Select one</option>
                          <option value="Admin">Admin</option>
                          <option value="Backend Only">Backend Only</option>
                          <option value="Staff">Staff</option>
                          <option value="Branch Admin">Branch Admin</option>
                          <option value="Institution Admin">Institution Admin</option>
                        </select>
                        <div *ngIf="submitted && f.role_type.errors" class="invalid-feedback">
                          <div *ngIf="f.role_type.errors.required">Role Type is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Password</label>
                        <input type="password" id="inputName"
                          [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control"
                          formControlName="password">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                        <div *ngIf="f.password.touched && f.password.invalid" class="invalid-feedback">
                          <div *ngIf="f.password.errors.pattern">Minimum eight characters, at least one letter, one
                            number and one special character.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Confirm Password</label>
                        <input type="password" id="inputName"
                          [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" class="form-control"
                          formControlName="confirm_password">
                          <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                            <div *ngIf="f.confirm_password.errors.required">Confirm Password is required</div>
                          </div>
                          <div *ngIf="f.confirm_password.touched && f.confirm_password.invalid" class="invalid-feedback">
                            <div *ngIf="f.confirm_password.errors.pattern">Minimum eight characters, at least one letter, one
                              number and one special character.</div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Phone No</label>
                        <input type="text" id="inputName" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                          class="form-control" formControlName="phone">
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                          <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                        </div>
                        <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger">
                          <div *ngIf="f.phone.errors.required">Phone Number is required.</div>
                          <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Phone Number.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Email ID</label>
                        <input type="text" id="inputName" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                          class="form-control" formControlName="email">
                        <div *ngIf="Email.errors?.email" class="alert alert-danger">
                          Email not valid.
                        </div>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" *ngIf="role_section">
                    <div class="card card-primary">
                      <div class="card-header">
                        <h3 class="card-title">Add Role</h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-minus"></i></button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group">
                              <label for="">Role</label>
                              <select class="form-control custom-select" [(ngModel)]="roleSelect"
                                (change)="selectrole()" formControlName="role">
                                <option *ngFor='let option of rolelist' [ngValue]="option">
                                  {{option.RoleName}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Permission</label>
                              <ng-select [items]="permissionlist" bindLabel="PermissionName"
                                placeholder="Select Permission" appendTo="body" multiple="true"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPermission">
                              </ng-select>
                            </div>
                          </div>
                          <div class="col-md-3 offset-md-1 mt-4">
                            <input type="submit" value="Add" class="btn btn-info" (click)="addItems()">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th style="display: none;">#</th>
                                  <th>Role</th>
                                  <th style="display: none;">Role Id</th>
                                  <th>Permission</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of items;let i= index">
                                  <td style="display: none;">{{item.sno}}</td>
                                  <td>{{item.role}}</td>
                                  <td style="display: none;">{{item.roleid}}</td>
                                  <td>
                                    <ng-select [items]="permissionlist" bindLabel="PermissionName"
                                      placeholder="Select Permission" appendTo="body" multiple="true"
                                      [ngModelOptions]="{standalone: true}" [(ngModel)]="item.permission">
                                    </ng-select>
                                  </td>
                                  <td class="project-actions text-right">
                                    <a class="btn btn-danger btn-sm" (click)="remove(item.sno)" style="color: white;">
                                      <i class="fas fa-trash">
                                      </i>
                                      Remove
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" *ngIf="section_institute">
                    <div class="card card-primary">
                      <div class="card-header">
                        <h3 class="card-title">Add Section</h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-minus"></i></button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Institution</label>
                              <select class="form-control custom-select" (change)="searchbranch()"
                                [(ngModel)]="selectedinstitute" formControlName="institute">
                                <option *ngFor='let option of institutelist' [ngValue]="option">
                                  {{option.InstituteName}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-1 mt-4">
                            <input type="submit" value="Add" class="btn btn-info" (click)="addItems1()">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th style="display: none;">#</th>
                                  <th>Institution</th>
                                  <th style="display: none;">Institution Id</th>
                                  <th style="display: none;">Branch Id</th>
                                  <th style="display: none;">Programme Id</th>
                                  <th style="display: none;">Section</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of items1;let i= index">
                                  <td style="display: none;">{{item.sno}}</td>
                                  <td>{{item.institute}}</td>
                                  <td style="display: none;">{{item.instituteid}}</td>
                                  <td style="display: none;">{{item.branchid}}</td>
                                  <td style="display: none;">{{item.programid}}</td>
                                  <td style="display: none;">{{item.sectionid}}</td>
                                  <td class="project-actions text-right">
                                    <a class="btn btn-danger btn-sm" (click)="remove1(item.sno)" style="color: white;">
                                      <i class="fas fa-trash">
                                      </i>
                                      Remove
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" *ngIf="section_branch">
                    <div class="card card-primary">
                      <div class="card-header">
                        <h3 class="card-title">Add Section</h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-minus"></i></button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Institution</label>
                              <select class="form-control custom-select" (change)="searchbranch()"
                                [(ngModel)]="selectedinstitute" formControlName="institute">
                                <option *ngFor='let option of institutelist' [ngValue]="option">
                                  {{option.InstituteName}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Branch</label>
                              <select class="form-control custom-select" (change)="searchprogram()"
                                [(ngModel)]="selectedbranch" formControlName="branch">
                                <option *ngFor='let option of branchlist' [ngValue]="option">
                                  {{option.branch}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-1 mt-4">
                            <input type="submit" value="Add" class="btn btn-info" (click)="addItems1()">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th style="display: none;">#</th>
                                  <th>Institution</th>
                                  <th style="display: none;">Institution Id</th>
                                  <th>Branch</th>
                                  <th style="display: none;">Branch Id</th>
                                  <th style="display: none;">Programme Id</th>
                                  <th style="display: none;">Section</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of items1;let i= index">
                                  <td style="display: none;">{{item.sno}}</td>
                                  <td>{{item.institute}}</td>
                                  <td style="display: none;">{{item.instituteid}}</td>
                                  <td>{{item.branch}}</td>
                                  <td style="display: none;">{{item.branchid}}</td>
                                  <td style="display: none;">{{item.programid}}</td>
                                  <td style="display: none;">{{item.sectionid}}</td>
                                  <td class="project-actions text-right">
                                    <a class="btn btn-danger btn-sm" (click)="remove1(item.sno)" style="color: white;">
                                      <i class="fas fa-trash">
                                      </i>
                                      Remove
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="" *ngIf="section_program">
                    <div class="card card-primary">
                      <div class="card-header">
                        <h3 class="card-title">Add Section</h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-minus"></i></button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Institution</label>
                              <select class="form-control custom-select" (change)="searchbranch()"
                                [(ngModel)]="selectedinstitute" formControlName="institute">
                                <option *ngFor='let option of institutelist' [ngValue]="option">
                                  {{option.InstituteName}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Branch</label>
                              <select class="form-control custom-select" (change)="searchprogram()"
                                [(ngModel)]="selectedbranch" formControlName="branch">
                                <option *ngFor='let option of branchlist' [ngValue]="option">
                                  {{option.branch}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Programme</label>
                              <select class="form-control custom-select" (change)="searchsection()"
                                [(ngModel)]="selectedprogram" formControlName="program">
                                <option *ngFor='let option of programlist' [ngValue]="option">
                                  {{option.program_name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-1 mt-4">
                            <input type="submit" value="Add" class="btn btn-info" (click)="addItems1()">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th style="display: none;">#</th>
                                  <th>Institution</th>
                                  <th style="display: none;">Institution Id</th>
                                  <th>Branch</th>
                                  <th style="display: none;">Branch Id</th>
                                  <th>Programme</th>
                                  <th style="display: none;">Programme Id</th>
                                  <th style="display: none;">Section</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of items1;let i= index">
                                  <td style="display: none;">{{item.sno}}</td>
                                  <td>{{item.institute}}</td>
                                  <td style="display: none;">{{item.instituteid}}</td>
                                  <td>{{item.branch}}</td>
                                  <td style="display: none;">{{item.branchid}}</td>
                                  <td>{{item.program}}</td>
                                  <td style="display: none;">{{item.programid}}</td>
                                  <td style="display: none;">{{item.sectionid}}</td>
                                  <td class="project-actions text-right">
                                    <a class="btn btn-danger btn-sm" (click)="remove1(item.sno)" style="color: white;">
                                      <i class="fas fa-trash">
                                      </i>
                                      Remove
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="" *ngIf="section_all">
                    <div class="card card-primary">
                      <div class="card-header">
                        <h3 class="card-title">Add Section</h3>
                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-minus"></i></button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Institution</label>
                              <select class="form-control custom-select" (change)="searchbranch()"
                                [(ngModel)]="selectedinstitute" formControlName="institute">
                                <option *ngFor='let option of institutelist' [ngValue]="option">
                                  {{option.InstituteName}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Branch</label>
                              <select class="form-control custom-select" (change)="searchprogram()"
                                [(ngModel)]="selectedbranch" formControlName="branch">
                                <option *ngFor='let option of branchlist' [ngValue]="option">
                                  {{option.branch}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Programme</label>
                              <select class="form-control custom-select" (change)="searchsection()"
                                [(ngModel)]="selectedprogram" formControlName="program">
                                <option *ngFor='let option of programlist' [ngValue]="option">
                                  {{option.program_name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Section</label>
                              <ng-select [items]="sectionlist" bindLabel="ClassCode" placeholder="Select Section"
                                appendTo="body" multiple="true" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="selectedSection">
                              </ng-select>
                            </div>
                          </div>
                          <div class="col-md-1 mt-4">
                            <input type="submit" value="Add" class="btn btn-info" (click)="addItems1()">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th style="display: none;">#</th>
                                  <th>Institution</th>
                                  <th style="display: none;">Institution Id</th>
                                  <th>Branch</th>
                                  <th style="display: none;">Branch Id</th>
                                  <th>Programme</th>
                                  <th style="display: none;">Programme Id</th>
                                  <th>Section</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of items1;let i= index">
                                  <td style="display: none;">{{item.sno}}</td>
                                  <td>{{item.institute}}</td>
                                  <td style="display: none;">{{item.instituteid}}</td>
                                  <td>{{item.branch}}</td>
                                  <td style="display: none;">{{item.branchid}}</td>
                                  <td>{{item.program}}</td>
                                  <td style="display: none;">{{item.programid}}</td>
                                  <td>
                                    <ng-select [items]="sectionlist" bindLabel="ClassCode" placeholder="Select Section"
                                      appendTo="body" multiple="true" [ngModelOptions]="{standalone: true}"
                                      [(ngModel)]="item.section">
                                    </ng-select>
                                  </td>
                                  <td class="project-actions text-right">
                                    <a class="btn btn-danger btn-sm" (click)="remove1(item.sno)" style="color: white;">
                                      <i class="fas fa-trash">
                                      </i>
                                      Remove
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input type="submit" value="Add User" class="btn btn-success" (click)="submit()">
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
</div>