import { Component, OnInit, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User } from '../Model/user';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const SELECT_COUNTRY_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditcurriculumComponent),
  multi: true,
};

export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = e => {
            resolve({ default: myReader.result });
          };
          myReader.readAsDataURL(file);
        })
    );
  }
}

@Component({
  selector: 'app-editcurriculum',
  templateUrl: './editcurriculum.component.html',
  styleUrls: ['./editcurriculum.component.css'],
  providers: [SELECT_COUNTRY_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditcurriculumComponent implements ControlValueAccessor,OnInit {

  public config = {
    link: { addTargetToExternalLinks: true },
    mediaEmbed: {
      previewsInData: true
    }
  };
  capricsAdminUser: User;
  userid: any;
  myform: FormGroup;
  public Editor = ClassicEditor;
    // .create(document.querySelector('#editor'), {
    //   removePlugins: ['Heading', 'Link'],
    //   toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote'],
    //   mediaEmbed: {
    //     previewsInData: true
    //   }
    // })
    // .catch(error => {
    //   console.log(error);
    // });;
  Learningdata = '';
  coursedata = '';
  notesdata = '';
  worksheetdata = '';
  materialdata = '';
  referencedata = '';
  submitted = false;
  themelist = [];
  activitylist = [];
  subjectlist = [];
  intelligencelist = [];
  skill_list = [];
  mySelect: any;
  activity_Select: any;
  weekSelect: any;
  daySelect: any;
  intelSelect: any;
  skillselect: any;
  subjectSelect: any;
  theme_id: any;
  subjectid: any;
  intelligence_id: any;
  skill_id: any;
  imageUrl: any;
  imageUrl1: any;
  selectedIntelligent = [];
  selectedSkill = [];
  weeks = [];
  days = [];
  selected_week: any;
  selected_day: any;
  activity_id: any;
  program_id: any;
  academic_id: any;
  hide_subject: boolean;
  curriculum_id: any;

  hide_theme: boolean;
  hide_activity: boolean;
  hide_week: boolean;
  hide_day: boolean;
  private onChangeCallback: Function;
  private onTouchedCallback: Function;

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      activity: [''],
      theme: ['', Validators.required],
      academic: [''],
      program: [''],
      subject: ['', Validators.required],
      topic: ['', Validators.required],
      day_order: ['', Validators.required],
      duration: [''],
      week: [''],
      day: [''],
      intelligence: [''],
      skill: [''],
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  writeValue(obj: any): void {
    throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (
      loader
    ) {
      console.log('loader : ', loader);
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.activity_id == "" || this.activity_id == undefined) {
      this.activity_id = 0;
    }
    debugger;
    let mytype = "EditCurriculum";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "CurriculumId": this.curriculum_id,
      "ThemeId": this.theme_id,
      "AcademicId": this.academic_id,
      "ProgramId": this.program_id,
      "ActivityId": this.activity_id,
      "SubjectId": this.subjectid,
      "Topic": this.myform.value.topic,
      "DayOrder": this.myform.value.day_order,
      "Duration": this.myform.value.duration,
      "Week": this.selected_week,
      "Day": this.selected_day,
      "Learning": this.Learningdata,
      "Notes": this.notesdata,
      "Course": this.coursedata,
      "Worksheet": this.worksheetdata,
      "OutcomeResource": this.worksheetdata,
      "Materials": this.materialdata,
      "Reference": this.referencedata,
      "user_id": this.userid,
    }
    mydata["intelligent_data"] = [];
    this.selectedIntelligent.forEach(item => {
      var child = {};
      child["IntelligentId"] = item.id;
      mydata["intelligent_data"].push(child);
    });
    mydata["skill_data"] = [];
    this.selectedSkill.forEach(item => {
      var child = {};
      child["SkillId"] = item.id;
      mydata["skill_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditCurriculum", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.ngOnInit();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.curriculum_id = localStorage.getItem('curriculum_id') ? JSON.parse(localStorage.getItem("curriculum_id")) : undefined;
    this.hide_theme = true;
    this.hide_activity = true;
    this.hide_subject = true;
    this.hide_week = true;
    this.hide_day = true;
    this.activity_id = 0;
    this.imageUrl = '';
    this.imageUrl1 = '';
    this.Learningdata = '';
    this.coursedata = '';
    this.notesdata = '';
    this.worksheetdata = '';
    this.materialdata = '';
    this.referencedata = '';
    this.themelist = [];
    this.activitylist = [];
    this.subjectlist = [];
    this.intelligencelist = [];
    this.skill_list = [];
    this.selectedIntelligent = [];
    this.selectedSkill = [];
    let mytype1 = "GetCurriculum_child";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "CurriculumId": this.curriculum_id,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetCurriculum_child", data);
        if (data.result == 1) {
          this.Learningdata = data.data.parent[0].Learning;
          this.coursedata = data.data.parent[0].Course;
          this.notesdata = data.data.parent[0].Notes;
          this.worksheetdata = data.data.parent[0].OutcomeResource;
          this.materialdata = data.data.parent[0].Materials;
          this.referencedata = data.data.parent[0].Reference;
          this.selectedIntelligent = data.data.intelligent_data;
          this.selectedSkill = data.data.skill_data;
          this.myform.controls['theme'].setValue(data.data.parent[0].ThemeName);
          this.theme_id = data.data.parent[0].ThemeId;
          this.myform.controls['academic'].setValue(data.data.parent[0].AcademicCode);
          this.academic_id = data.data.parent[0].AcademicId;
          this.myform.controls['program'].setValue(data.data.parent[0].ProgramName);
          this.program_id = data.data.parent[0].ProgramId;
          this.myform.controls['subject'].setValue(data.data.parent[0].SubjectCode);
          this.subjectid = data.data.parent[0].SubjectId;
          this.myform.controls['topic'].setValue(data.data.parent[0].Topic);
          this.myform.controls['day_order'].setValue(data.data.parent[0].DayOrder);
          this.myform.controls['duration'].setValue(data.data.parent[0].Duration);
          this.myform.controls['week'].setValue(data.data.parent[0].Week);
          this.selected_week = data.data.parent[0].Week;
          this.myform.controls['day'].setValue(data.data.parent[0].Day);
          this.activity_id = data.data.parent[0].ActivityId;
          this.myform.controls['activity'].setValue(data.data.parent[0].ActivityName);
          this.selected_day = data.data.parent[0].Day;
          this.subjectlist = [];
          this.weeks = [];
          this.days = [];
          let mytype4 = "List_ActiveActivity";
          let mydata4 = {
            "secret_key": "2020140715947422055f0dd5bd3ba8f",
          }
          this.apiservice.apipost(mydata4, mytype4)
            .subscribe((data: any) => {
              console.log("List_ActiveActivity", data);
              if (data.result == 1) {
                this.activitylist = data.data;
                if (this.activity_id != 0) {
                  for (var index = 0; data.data.length; index++) {
                    if (data.data[index].id == this.activity_id) {
                      debugger;
                      this.activity_Select = index + ": Object";
                      this.valueChange();
                      break;
                    }
                  };
                }
              } else {
                this.swalservice.warningalert(data.msg);
              }
            });
          let mytype3 = "GetSubjectByTheme";
          let mydata3 = {
            "secret_key": "2020140715947422055f0dd5bd3ba8f",
            "ThemeId": this.theme_id,
          }
          this.apiservice.apipost(mydata3, mytype3)
            .subscribe((data: any) => {
              console.log("GetSubjectByTheme", data);
              if (data.result == 1) {
                this.subjectlist = data.data;
                this.weeks = data.weeks_data;
                this.days = data.days_data;
              } else {
                this.swalservice.warningalert(data.msg);
              }
            });
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype = "List_ActiveTheme";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveTheme", data);
        if (data.result == 1) {
          this.themelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype2 = "List_ActiveIntelligence";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("List_ActiveIntelligence", data);
        if (data.result == 1) {
          this.intelligencelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "List_ActiveSkill";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("List_ActiveSkill", data);
        if (data.result == 1) {
          this.skill_list = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  private valueChange() {
   // this.onTouchedCallback();
    this.onChangeCallback(this.activity_Select);
  }
  registerOnChange(onChangeCallback: Function) {
    this.onChangeCallback = onChangeCallback;
  }
  registerOnTouched(onTouchedCallback: any) {
    this.onTouchedCallback = onTouchedCallback;
  }
  selecttheme() {
    this.theme_id = this.mySelect.ThemeId;
    this.academic_id = this.mySelect.AcademicId;
    this.program_id = this.mySelect.ProgramId;
    this.myform.controls['academic'].setValue(this.mySelect.AcademicCode);
    this.myform.controls['program'].setValue(this.mySelect.ProgramName);
    this.subjectlist = [];
    this.weeks = [];
    this.days = [];
    let mytype3 = "GetSubjectByTheme";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeId": this.theme_id,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetSubjectByTheme", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
          this.weeks = data.weeks_data;
          this.days = data.days_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectactivity() {
    this.activity_id = this.activity_Select.id;
    let mytype3 = "ListActivityByid ";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ActivityId": this.activity_id,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("ListActivityByid ", data);
        if (data.result == 1) {
          this.hide_subject = true;
          this.selectedIntelligent = [];
          this.selectedSkill = [];
          this.myform.controls['day_order'].setValue(data.data[0].DayOrder);
          this.myform.controls['duration'].setValue(data.data[0].Duration);
          this.myform.controls['subject'].setValue(data.data[0].SubjectCode);
          this.subjectid = data.data[0].SubjectId;
          this.Learningdata = data.data[0].Learning;
          this.coursedata = data.data[0].Course;
          this.notesdata = data.data[0].Notes;
          this.worksheetdata = data.data[0].OutcomeResource;
          this.materialdata = data.data[0].Materials;
          this.referencedata = data.data[0].Reference;
          this.selectedIntelligent = data.data[0].intelligent_data;
          this.selectedSkill = data.data[0].skill_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectweek() {
    this.selected_week = this.weekSelect.week;
  }
  selectday() {
    this.selected_day = this.daySelect.day;
  }
  selectintel() {
    this.intelligence_id = this.intelSelect.id;
  }
  selectsubject() {
    this.subjectid = this.subjectSelect.SubjectId;
    this.myform.controls['duration'].setValue(this.subjectSelect.Duration);
  }
  theme_change() {
    this.hide_theme = false;
  }
  subject_change() {
    this.hide_subject = false;
  }
  week_change() {
    this.hide_week = false;
  }
  day_change() {
    this.hide_day = false;
  }
  activity_change() {
    this.hide_activity = false;
  }
}
