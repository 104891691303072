import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { User } from '../Model/user';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';

@Component({
  selector: 'app-addtheme',
  templateUrl: './addtheme.component.html',
  styleUrls: ['./addtheme.component.css']
})
export class AddthemeComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  academiclist = [];
  programlist = [];
  //myform: FormGroup;
  submitted = false;
  themeid: any;
  color_code: any;
  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any;
  removeUpload: boolean = false;
  editFile: boolean = true;

  public toggle: boolean = false;
  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
  public arrayColors: any = {
    color1: '#2883e9',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  public selectedColor: string = 'color1';
  public color1: string;
  public cmykValue: string = '';
  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);

  constructor(private formBuilder: FormBuilder, private apiservice: api,private cd: ChangeDetectorRef,
    public vcRef: ViewContainerRef, private cpService: ColorPickerService, private router: Router, private swalservice: swal) {
       this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  myform = this.formBuilder.group({
    theme_name: ['', Validators.required],
    academic: ['', Validators.required],
    program: ['', Validators.required],
    weeks: ['', Validators.required],
    days: ['', Validators.required],
    color_code: [],
    file: [null]
  });
  public onEventLog(event: string, data: any): void {
    console.log(event, data);
  }
  public onChangeColor(color: string): void {
    console.log('Color changed:', color);
  }
  public onChangeColorCmyk(color: string): Cmyk {
    this.color_code = color;
    const hsva = this.cpService.stringToHsva(color);
    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);
      return this.cpService.rgbaToCmyk(rgba);
    }
    return new Cmyk(0, 0, 0, 0);
  }
  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);
    if (hsva) {
      return this.cpService.outputFormat(hsva, 'rgba', null);
    }
    return '';
  }

  load() {
    this.myform.controls['color_code'].setValue('#19c05c');
    this.color_code = "#19c05c";
    this.color1 = "#19c05c";
    this.academiclist = [];
    this.programlist = [];
    let mytype = "List_ActiveAcademic";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveAcademic", data);
        if (data.result == 1) {
          this.academiclist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "List_ActiveProgram";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("List_ActiveProgram", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "AddTheme";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeName": this.myform.value.theme_name,
      "AcademicId": this.myform.value.academic,
      "ProgramId": this.myform.value.program,
      "Week": this.myform.value.weeks,
      "Day": this.myform.value.days,
      "ColorCode": this.color_code,
      "user_id": this.userid,
      "ThemeIcon": this.myform.value.file,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddTheme", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.imageUrl = '';
          this.load();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  get f() {
    return this.myform.controls;
  }
  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.myform.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      this.cd.markForCheck();
    }
  }
  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl = '';
    this.editFile = true;
    this.removeUpload = false;
    this.myform.patchValue({
      file: [null]
    });
  }
}
