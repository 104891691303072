<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-3">
                        <a (click)="add()" [class.disabled]="create" class="btn btn-sm btn-primary" style="color: white;">Add New Permission</a>
                    </div>
                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Permission List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Permission List</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-minus"></i></button>
                    </div>
                </div>
                <div class="card-body p-0 mt-2">
                    <table class="table table-striped projects" *ngIf="loadTable" datatable>
                        <thead>
                            <tr>
                                <th>
                                    SNo
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Permission Name
                                </th>
                                <!-- <th style="width: 15%">
                                    Status
                                </th> -->
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of permissionlist;let i = index;">
                                <td>{{ i + 1 }}</td>
                                <td>{{ data.date_time | date: "dd-MM-yyyy" }}</td>
                                <td><a>{{ data.PermissionName }}</a></td>
                                <!-- <td>
                                    <a>{{ data.Status }}</a>
                                </td> -->
                                <td class="project-actions text-right">
                                    <a *ngIf="data.Status == 'Active'" [class.disabled]="edit" class="btn btn-info btn-sm" data-toggle="modal"
                                        data-target="#exampleModalCenter" style="color: white;"
                                        (click)="open(data.PermissionId,data.PermissionName)">
                                        <i class="fas fa-pencil-alt">
                                        </i>
                                        Edit
                                    </a>&nbsp;
                                    <a *ngIf="data.Status == 'Active'" [class.disabled]="delete" class="btn btn-danger btn-sm"
                                        style="color: white;"
                                        (click)="delete(data.PermissionId,'Inactive','You will not be able to recover this Permission Details!','Yes, delete it!')">
                                        <i class="fas fa-trash">
                                        </i>
                                        Delete
                                    </a>
                                    <a *ngIf="data.Status == 'Inactive'" [class.disabled]="del" class="btn btn-success btn-sm"
                                        style="color: white;"
                                        (click)="delete(data.PermissionId,'Active','You will Activate this Permission Details!','Yes, Activate it!')">
                                        <i class="far fa-check-circle"></i>
                                        Activate
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">Update Permission</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="myform">
                    <div class="form-group">
                        <label for="inputName">Permission Name</label>
                        <input type="text" id="inputName" class="form-control" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Permission Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="col-md-2">
                                <label for="inputName">Create</label>
                            </div>
                            <div class="col-md-2">
                                <label for="inputName">Read</label>
                            </div>&nbsp;
                            <div class="col-md-2">
                                <label for="inputName">Edit</label>
                            </div>
                            <div class="col-md-2">
                                <label for="inputName">Delete</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row" *ngFor="let item of menulist">
                            <div class="col-md-4">
                                <label for="inputName">{{ item.menu_name }}</label>
                            </div>
                            <div class="col-md-2">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox4"
                                    [(ngModel)]="item.isSelected3" [ngModelOptions]="{standalone: true}"
                                    (change)="getProoduct(item.isSelected3, item,'create')">
                            </div>
                            <div class="col-md-2">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                    [(ngModel)]="item.isSelected" [ngModelOptions]="{standalone: true}"
                                    (change)="getProoduct(item.isSelected, item,'read')">
                            </div>
                            <div class="col-md-2">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
                                    [(ngModel)]="item.isSelected1" [ngModelOptions]="{standalone: true}"
                                    (change)="getProoduct(item.isSelected1, item,'write')">
                            </div>
                            <div class="col-md-2">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3"
                                    [(ngModel)]="item.isSelected2" [ngModelOptions]="{standalone: true}"
                                    (change)="getProoduct(item.isSelected2, item,'delete')">
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Update" class="btn btn-success" data-dismiss="modal"
                            (click)="submit()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>