<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-3">
                        <a (click)="add()" [class.disabled]="create" class="btn btn-sm btn-primary"
                            style="color: white;">Add New Section</a>
                    </div>
                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Section List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Section List</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                            title="Collapse">
                            <i class="fas fa-minus"></i></button>
                    </div>
                </div>
                <div class="card-body p-0 mt-2">
                    <table class="table table-striped projects" *ngIf="loadTable" datatable>
                        <thead>
                            <tr>
                                <th style="width: 10%">
                                    Sno
                                </th>
                                <th style="width: 10%">
                                    Date
                                </th>
                                <th style="width: 30%">
                                    Section Code
                                </th>
                                <th style="width: 30%">
                                    Definition
                                </th>
                                <th style="width: 20%">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of classlist;let i = index;">
                                <td>{{ i + 1 }}</td>
                                <td>{{ data.date | date: "dd-MM-yyyy" }}</td>
                                <td><a>{{ data.ClassCode }}</a></td>
                                <td><a><a>{{ data.Definition }}</a></a></td>
                                <td class="project-actions text-right">
                                    <a *ngIf="data.Status == 'Active'" [class.disabled]="edit"
                                        class="btn btn-info btn-sm" data-toggle="modal"
                                        data-target="#exampleModalCenter" style="color: white;"
                                        (click)="open(data.id,data.ClassCode,data.Definition,data.Status)">
                                        <i class="fas fa-pencil-alt">
                                        </i>
                                        Edit
                                    </a>&nbsp;
                                    <a *ngIf="data.Status == 'Active'" [class.disabled]="del"
                                        class="btn btn-danger btn-sm" style="color: white;"
                                        (click)="delete(data.id,'Inactive','You will not be able to recover this Section Details!','Yes, delete it!')">
                                        <i class="fas fa-trash">
                                        </i>
                                        Delete
                                    </a>
                                    <a *ngIf="data.Status == 'Inactive'" [class.disabled]="del"
                                        class="btn btn-success btn-sm" style="color: white;"
                                        (click)="delete(data.id,'Active','You will Activate this Section Details!','Yes, Activate it!')">
                                        <i class="far fa-check-circle"></i>
                                        Activate
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">Update Section</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="myform">
                    <div class="form-group">
                        <label for="inputName">Section Code</label>
                        <input type="text" id="inputName" class="form-control" formControlName="code"
                            [ngClass]="{ 'is-invalid': submitted && f.code.errors }">
                        <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                            <div *ngIf="f.code.errors.required">Section Code is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputDescription">Definition</label>
                        <textarea id="inputDescription" class="form-control" rows="4" formControlName="definition"
                            [ngClass]="{ 'is-invalid': submitted && f.definition.errors }"></textarea>
                        <div *ngIf="submitted && f.definition.errors" class="invalid-feedback">
                            <div *ngIf="f.definition.errors.required">Definition is required</div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Update" class="btn btn-success" data-dismiss="modal"
                            (click)="submit()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>