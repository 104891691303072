import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { User } from '../Model/user';

interface item {
  sno: any;
  role: any;
  roleid: any;
  permission: any;
  permissionid: any;
}

interface item1 {
  sno: any;
  institute: any;
  instituteid: any;
  branch: any;
  branchid: any;
  program: any;
  programid: any;
  section: any;
  sectionid: any;
}

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  myform: FormGroup;
  submitted = false;
  rolelist = [];
  permissionlist = [];
  branchlist = [];
  programlist = [];
  institutelist = [];
  sectionlist = [];
  selectedSection = [];
  institute_id: any;
  institute_name: any;
  selectedinstitute: any;
  selectedbranch: any;
  selectedprogram: any;
  orderItem: item;
  orderItem1: item1;
  items: Array<item>;
  items1: Array<item1>;
  role_id: any;
  role_name: any;
  roleSelect: any;
  selectedPermission = [];
  branch_id: any;
  branch_name: any;
  program_id: any;
  program_name: any;
  selected_roletype: any;
  section_all: boolean;
  section_institute: boolean;
  section_branch: boolean;
  // section_program: boolean;
  role_section: boolean;


  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal) {
    this.items = [];
    this.items1 = [];
    this.myform = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$")]],
      confirm_password: ['', [Validators.required, Validators.pattern("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$")]],
      role: [''],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.email]],
      institute: [''],
      branch: [''],
      program: [''],
      role_type: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  get f() {
    return this.myform.controls;
  }
  get Email() {
    return this.myform.get('email');
  }

  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.myform.value.password != this.myform.value.confirm_password) {
      this.swalservice.warningalert("confirm password not match");
      return;
    }
    if (this.selected_roletype != "Institution Admin" && this.selected_roletype != "Branch Admin" && this.selected_roletype != "Staff") {
      if (this.items.length == 0) {
        this.swalservice.warningalert("add permissions");
        return;
      }
    }
    if (this.items1.length == 0 && this.selected_roletype != "Backend Only") {
      this.swalservice.warningalert("add section");
      return;
    }
    var per = false;
    var sec = false;
    let mytype = "AddUser";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "Firstname": this.myform.value.firstname,
      "Lastname": this.myform.value.lastname,
      "Username": this.myform.value.username,
      "Password": this.myform.value.password,
      "Phone": this.myform.value.phone,
      "Email": this.myform.value.email,
      "RoleType": this.myform.value.role_type,
      "user_id": this.userid,
    }
    if (this.selected_roletype != "Institution Admin" && this.selected_roletype != "Branch Admin" && this.selected_roletype != "Staff") {
      mydata["role_data"] = [];
      this.items.forEach(item => {
        var child = {};
        child["RoleId"] = item.roleid;
        let permissiondata = {};
        if (item.permission.length == 0) {
          this.swalservice.warningalert("add permission for given role");
          per = true;
          return;
        }
        item.permission.forEach(item => {
          if (permissiondata["permission_id"]) {
            permissiondata["permission_id"] = permissiondata["permission_id"] + "," + item.PermissionId;
          } else {
            permissiondata["permission_id"] = item.PermissionId;
          }
        });
        child["PermissionId"] = permissiondata["permission_id"];
        mydata["role_data"].push(child);
      });
    }
    if (per == false && this.selected_roletype != "Backend Only") {
      mydata["section_data"] = [];
      this.items1.forEach(item1 => {
        var child = {};
        child["InstituteId"] = item1.instituteid;
        child["BranchId"] = item1.branchid;
        child["ProgramId"] = item1.programid;
        let seciondata = {};
        if (this.selected_roletype != "Institution Admin" && this.selected_roletype != "Branch Admin") {
          // if (item1.section.length == 0) {
          //   this.swalservice.warningalert("add section for given institute");
          //   sec = true;
          //   return;
          // }
          item1.section.forEach(item => {
            if (seciondata["section_id"]) {
              seciondata["section_id"] = seciondata["section_id"] + "," + item.section_id;
            } else {
              seciondata["section_id"] = item.section_id;
            }
          });
        }
        if (seciondata["section_id"] == 0 || seciondata["section_id"] == undefined) {
          seciondata["section_id"] = "0";
        }
        child["SectionId"] = seciondata["section_id"];
        mydata["section_data"].push(child);
      });
    }
    if (per == false && sec == false) {
      this.apiservice.apipost(mydata, mytype)
        .subscribe((data: any) => {
          console.log("AddUser", data);
          if (data.result == 1) {
            this.swalservice.successalert(data.msg);
            this.submitted = false;
            this.myform.reset();
            this.ngOnInit();
          } else {
            this.swalservice.warningalert(data.msg);
          }
        });
    }
  }
  ngOnInit(): void {
    this.role_section = true;
    this.section_all = true;
    this.section_branch = false;
    // this.section_program = false;
    this.section_institute = false;
    this.items = [];
    this.items1 = [];
    this.branchlist = [];
    this.rolelist = [];
    this.institutelist = [];
    let mytype = "List_ActiveRole";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveRole", data);
        if (data.result == 1) {
          this.rolelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "ListInstitute";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("ListInstitute", data);
        if (data.result == 1) {
          this.institutelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchbranch() {
    this.institute_id = this.selectedinstitute.InstituteId;
    this.institute_name = this.selectedinstitute.InstituteName;
    this.branchlist = [];
    let mytype1 = "GetBranchByInstituteid";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "InstituteId": this.selectedinstitute.InstituteId,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetBranchByInstituteid", data);
        if (data.result == 1) {
          this.branchlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchprogram() {
    this.branch_id = this.selectedbranch.branch_id;
    this.branch_name = this.selectedbranch.branch;
    this.programlist = [];
    this.sectionlist = [];
    let mytype1 = "GetProgram_Branchid";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "BranchId": this.selectedbranch.branch_id,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetProgram_Branchid", data);
        if (data.result == 1) {
          this.programlist = data.data;
          this.sectionlist = data.section;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchsection() {
    this.program_id = this.selectedprogram.program_id;
    this.program_name = this.selectedprogram.program_name;
    // this.sectionlist = [];
    // let mytype1 = "GetSection_Programid";
    // let mydata1 = {
    //   "secret_key": "2020140715947422055f0dd5bd3ba8f",
    //   "ProgramId": this.selectedprogram.program_id,
    // }
    // this.apiservice.apipost(mydata1, mytype1)
    //   .subscribe((data: any) => {
    //     console.log("GetSection_Programid", data);
    //     if (data.result == 1) {
    //       this.sectionlist = data.data;
    //     } else {
    //       this.swalservice.warningalert(data.msg);
    //     }
    //   });
  }
  selectrole() {
    this.role_id = this.roleSelect.id;
    this.role_name = this.roleSelect.RoleName;
    let mytype1 = "GetRole_Child";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "RoleId": this.role_id,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetRole_Child", data);
        if (data.result == 1) {
          this.permissionlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  select_roletype() {
    this.items1 = [];
    this.selected_roletype = this.myform.value.role_type;
    if (this.selected_roletype == "Institution Admin") {
      this.section_all = false;
      this.section_branch = false;
      this.section_institute = true;
      //  this.section_program = false;
      this.role_section = false;
    } else if (this.selected_roletype == "Branch Admin") {
      this.section_all = false;
      this.section_branch = true;
      this.section_institute = false;
      // this.section_program = false;
      this.role_section = false;
    } else if (this.selected_roletype == "Staff") {
      this.section_all = true;
      this.section_branch = false;
      this.section_institute = false;
      // this.section_program = true;
      this.role_section = false;
    } else if (this.selected_roletype == "Backend Only") {
      this.section_all = false;
      this.section_branch = false;
      this.section_institute = false;
      // this.section_program = false;
      this.role_section = true;
    } else {
      this.section_all = true;
      this.section_branch = false;
      this.section_institute = false;
      // this.section_program = false;
      this.role_section = true;
    }
  }
  addItems() {
    //let roledata = {};
    // this.selectedPermission.forEach(item => {
    //   if (roledata["permission_name"]) {
    //     roledata["permission_name"] = roledata["permission_name"] + "," + item.PermissionName;
    //     roledata["permission_id"] = roledata["permission_id"] + "," + item.PermissionId;
    //   } else {
    //     roledata["permission_name"] = item.PermissionName;
    //     roledata["permission_id"] = item.PermissionId;
    //   }
    // });
    if (this.role_id != undefined && this.selectedPermission.length != 0) {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        role: this.role_name,
        roleid: this.role_id,
        permission: this.selectedPermission,
        sno: index,
      };
      this.items.push(this.orderItem);
      this.myform.controls['role'].setValue("");
      this.selectedPermission = [];
      this.role_id = "";
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].sno === sno) {
        this.items.splice(i, 1);
      }
    }
  }
  addItems1() {
    // let sectiondata = {};
    // this.selectedSection.forEach(item1 => {
    //   if (sectiondata["section_name"]) {
    //     sectiondata["section_name"] = sectiondata["section_name"] + "," + item1.ClassCode;
    //     sectiondata["section_id"] = sectiondata["section_id"] + "," + item1.id;
    //   } else {
    //     sectiondata["section_name"] = item1.ClassCode;
    //     sectiondata["section_id"] = item1.id;
    //   }
    // });
    if (this.selected_roletype == "Institution Admin") {
      if (this.items1.length == 0) {
        if (this.institute_id != undefined) {
          let index = 1;
          this.items1.forEach(element => {
            index = index + 1;
          });
          this.orderItem1 = <item1>{
            institute: this.institute_name,
            instituteid: this.institute_id,
            branchid: '0',
            programid: '0',
            sectionid: '0',
            sno: index,
          };
          this.items1.push(this.orderItem1);
          this.myform.controls['institute'].setValue("");
          this.institute_id = "";
        }
        else {
          this.swalservice.warningalert("Fill Details");
        }
      } else {
        this.swalservice.warningalert("added only one institution/user");
      }
    } else if (this.selected_roletype == "Branch Admin") {

      if (this.institute_id != undefined && this.branch_id != undefined) {
        let index = 1;
        this.items1.forEach(element => {
          index = index + 1;
        });
        this.orderItem1 = <item1>{
          institute: this.institute_name,
          instituteid: this.institute_id,
          branch: this.branch_name,
          branchid: this.branch_id,
          program: '0',
          programid: '0',
          sectionid: '0',
          sno: index,
        };
        this.items1.push(this.orderItem1);
        this.myform.controls['institute'].setValue("");
        this.myform.controls['branch'].setValue("");
        this.institute_id = "";
        this.branch_id = "";
      }
      else {
        this.swalservice.warningalert("Fill Details");
      }
      // } else if (this.selected_roletype == "Staff") {
      //   if (this.institute_id != undefined && this.branch_id != undefined && this.program_id != undefined) {
      //     let index = 1;
      //     this.items1.forEach(element => {
      //       index = index + 1;
      //     });
      //     this.orderItem1 = <item1>{
      //       institute: this.institute_name,
      //       instituteid: this.institute_id,
      //       branch: this.branch_name,
      //       branchid: this.branch_id,
      //       program: this.program_name,
      //       programid: this.program_id,
      //       section: this.selectedSection,
      //       sno: index,
      //     };
      //     this.items1.push(this.orderItem1);
      //     this.myform.controls['institute'].setValue("");
      //     this.myform.controls['branch'].setValue("");
      //     this.myform.controls['program'].setValue("");
      //     this.institute_id = "";
      //     this.branch_id = "";
      //     this.program_id = "";
      //     this.selectedSection = [];
      //   }
      //   else {
      //     this.swalservice.warningalert("Fill Details");
      //   }
    }
    else {
      if (this.institute_id != undefined && this.branch_id != undefined && this.program_id != undefined) {
        let index = 1;
        this.items1.forEach(element => {
          index = index + 1;
        });
        this.orderItem1 = <item1>{
          institute: this.institute_name,
          instituteid: this.institute_id,
          branch: this.branch_name,
          branchid: this.branch_id,
          program: this.program_name,
          programid: this.program_id,
          section: this.selectedSection,
          sno: index,
        };
        this.items1.push(this.orderItem1);
        this.myform.controls['institute'].setValue("");
        this.myform.controls['branch'].setValue("");
        this.myform.controls['program'].setValue("");
        this.selectedSection = [];
        this.institute_id = "";
        this.branch_id = "";
        this.program_id = "";
      }
      else {
        this.swalservice.warningalert("Fill Details");
      }
    }
  }
  remove1(sno) {
    for (let i = 0; i < this.items1.length; ++i) {
      if (this.items1[i].sno === sno) {
        this.items1.splice(i, 1);
      }
    }
  }
}
