<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-3">
                        <a (click)="add()" [class.disabled]="create" class="btn btn-sm btn-primary" style="color: white;">Add New
                     Theme</a>
                    </div>
                    <div class="col-sm-3">
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Theme List</li>
                        </ol>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-sm">Academic Code</span>
                            </div>
                            <select class="form-control custom-select" [(ngModel)]="acSelect" (change)="searchacademic()" [ngModelOptions]="{standalone: true}">
                <option *ngFor='let option of academiclist' [ngValue]="option">
                  {{ option.AcademicCode }}</option>
              </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-sm">Program</span>
                            </div>
                            <select class="form-control custom-select" [(ngModel)]="pgSelect" (change)="searchprogram()" [ngModelOptions]="{standalone: true}">
                <option *ngFor='let option of programlist' [ngValue]="option">
                  {{ option.ProgramName }}</option>
              </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <input type="submit" value="Search" class="btn btn-success" (click)="search()">
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Theme List </h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                    <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <table class="table table-striped projects" *ngIf="loadTable" datatable>
                                    <thead>
                                        <tr>
                                            <th>
                                                Sno
                                            </th>
                                            <th>
                                                Date
                                            </th>
                                            <th>
                                                ThemeName
                                            </th>
                                            <th style="display: none;">
                                                AcademicId
                                            </th>
                                            <th>
                                                Academic
                                            </th>
                                            <th style="display: none;">
                                                ProgramId
                                            </th>
                                            <th>
                                                Program
                                            </th>
                                            <th>
                                                Week
                                            </th>
                                            <th>
                                                Day
                                            </th>
                                            <th>
                                                Color
                                            </th>
                                            <th>
                                                Live
                                            </th>
                                            <th>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of themelist;let i = index;">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ data.date | date: "dd-MM-yyyy" }}</td>
                                            <td>
                                                <a>
                          {{ data.ThemeName }}
                        </a>
                                            </td>
                                            <td style="display: none;">
                                                <a>
                          {{ data.AcademicId }}
                        </a>
                                            </td>
                                            <td>
                                                <a>
                          {{ data.AcademicCode }}
                        </a>
                                            </td>
                                            <td style="display: none;">
                                                <a>
                          {{ data.ProgramId }}
                        </a>
                                            </td>
                                            <td>
                                                <a>
                          {{ data.ProgramName }}
                        </a>
                                            </td>
                                            <td>
                                                <a>
                          {{ data.Week }}
                        </a>
                                            </td>
                                            <td>
                                                <a>
                          {{ data.Day }}
                        </a>
                                            </td>
                                            <td [ngStyle]="{'background-color':data.ColorCode }">
                                                {{ data.ColorCode }}
                                            </td>
                                            <td>
                                                <a *ngIf="data.publish == '0'" class="btn btn-info btn-sm" style="color: white;" (click)="live(data.id,'1','You want to publish this Theme Details!','Yes, Publish it!')">
                          Publish
                        </a>&nbsp;
                                                <a *ngIf="data.publish == '1'" class="btn btn-danger btn-sm" style="color: white;" (click)="live(data.id,'0','You want to unpublish this Theme Details!','Yes, Unpublish it!')">
                          Unpublish
                        </a>
                                            </td>
                                            <td class="project-actions text-right">
                                                <a *ngIf="data.Status == 'Active'" [class.disabled]="edit" class="btn btn-info btn-sm" style="color: white;" data-toggle="modal" data-target="#exampleModalCenter" (click)="open(data.id,data.ThemeName,data.ThemeIcon,data.AcademicId,data.AcademicCode,data.ProgramId,data.ProgramName,data.Week,data.Day,data.ColorCode)">
                                                    <i class="fas fa-pencil-alt">
                          </i> Edit
                                                </a>&nbsp;
                                                <a *ngIf="data.Status == 'Active'" [class.disabled]="del" class="btn btn-danger btn-sm" style="color: white;" (click)="delete(data.id,'Inactive','You will not be able to recover this Theme Details!','Yes, delete it!')">
                                                    <i class="fas fa-trash">
                          </i> Delete
                                                </a>
                                                <a *ngIf="data.Status == 'Inactive'" [class.disabled]="del" class="btn btn-success btn-sm" style="color: white;" (click)="delete(data.id,'Active','You will Activate this Theme Details!','Yes, Activate it!')">
                                                    <i class="far fa-check-circle"></i> Activate
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">Update Theme</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="myform">
                    <div class="form-group">
                        <label for="">Academic Year </label>&nbsp;<button class="badge btn-danger" (click)="ac_change()">
              Change</button>
                        <input type="text" id="inputName" class="form-control" readonly formControlName="academic" *ngIf="hide_academic">
                        <select class="form-control" formControlName="academic" [(ngModel)]="mySelect" (change)="selectacademic()" *ngIf="change_academic">
              <option *ngFor='let option of academiclist' [ngValue]="option">
                {{ option.AcademicCode }}</option>
            </select>
                    </div>
                    <div class="form-group">
                        <label for="">Program</label>&nbsp;<button class="badge btn-danger" (click)="pg_change()">
              Change</button>
                        <input type="text" id="inputName" class="form-control" readonly formControlName="program" *ngIf="hide_program">
                        <select class="form-control" formControlName="program" [(ngModel)]="mySelect1" (change)="selectprogram()" *ngIf="change_program">
              <option *ngFor='let option of programlist' [ngValue]="option">
                {{ option.ProgramName }}</option>
            </select>
                    </div>
                    <div class="form-group">
                        <label for="inputName">Theme Name</label>
                        <input type="text" id="inputName" class="form-control" formControlName="theme_name" [ngClass]="{ 'is-invalid': submitted && f.theme_name.errors }">
                        <div *ngIf="submitted && f.theme_name.errors" class="invalid-feedback">
                            <div *ngIf="f.theme_name.errors.required">Theme Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputName">Week</label>
                        <input type="number" id="inputName" class="form-control" formControlName="weeks" [ngClass]="{ 'is-invalid': submitted && f.weeks.errors }" max="4">
                        <div *ngIf="submitted && f.weeks.errors" class="invalid-feedback">
                            <div *ngIf="f.weeks.errors.required">Weeks is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputName">Day</label>
                        <input type="number" id="inputName" class="form-control" formControlName="days" [ngClass]="{ 'is-invalid': submitted && f.days.errors }" max="5">
                        <div *ngIf="submitted && f.days.errors" class="invalid-feedback">
                            <div *ngIf="f.days.errors.required">Days is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputName">Color Picker</label>
                        <input [value]="color1" class="form-control" formControlName="color_code" [style.background]="color1" [(colorPicker)]="color1" (colorPickerChange)="cmykColor=onChangeColorCmyk($event);" />
                    </div>
                    <div class="form-group">
                        <label for="inputName">Theme Icon (20px * 20px)</label>
                        <div class="group-gap">
                            <div class="avatar-upload">
                                <div class="avatar-edit">
                                    <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFile($event)" />
                                </div>
                                <div class="avatar-preview">
                                    <div class="preview" *ngIf="imageUrl && imageUrl !== null">
                                        <img [src]="imageUrl" width="20px" height="20px">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Update" class="btn btn-success" data-dismiss="modal" (click)="submit()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>