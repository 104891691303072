import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { User } from '../Model/user';

@Component({
  selector: 'app-listskill',
  templateUrl: './listskill.component.html',
  styleUrls: ['./listskill.component.css']
})
export class ListskillComponent implements OnInit {

  capricsAdminUser: User;
  userid:any;
  @ViewChild('fileInput') el: ElementRef;
  skilllist = [];
  submitted = false;
  loadTable: boolean;
  skillid: any;
  imageUrl: any;
  editFile: boolean = true;
  removeUpload: boolean = false;
  create: any;
  edit: any;
  del: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api,
     private swalservice: swal,private cd: ChangeDetectorRef) {
      this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
      if(this.capricsAdminUser != undefined){
        this.userid = this.capricsAdminUser.Userid;
      }
  }
  myform = this.formBuilder.group({
    skill_name: ['', Validators.required],
    file: [null]
  })
  load() {
    this.loadTable = false;
    let mytype = "ListSkill";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListSkill", data);
        if (data.result == 1) {
          this.skilllist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
      let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 2,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddSkill");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "EditSkill";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "Skillid": this.skillid,
      "SkillName": this.myform.value.skill_name,     
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditSkill", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteSkill";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "Skillid": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteSkill", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(skill_id, skill_name) {
    this.skillid = skill_id;
    this.myform.controls['skill_name'].setValue(skill_name);   
  } 
}
