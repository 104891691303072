import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { User } from '../Model/user';

@Component({
  selector: 'app-addskill',
  templateUrl: './addskill.component.html',
  styleUrls: ['./addskill.component.css']
})
export class AddskillComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  @ViewChild('fileInput') el: ElementRef;
  submitted = false;
  imageUrl: any;
  editFile: boolean = true;
  removeUpload: boolean = false;

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal,
    private cd: ChangeDetectorRef) {

  }
  myform = this.formBuilder.group({
    skill_name: ['', Validators.required],
    file: [null]
  })
  get f() {
    return this.myform.controls;
  }

  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "AddSkill";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "SkillName": this.myform.value.skill_name,
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddSkill", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.imageUrl = '';
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageUrl = reader.result;
        this.myform.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      this.cd.markForCheck();
    }
  }
  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl = '';
    this.editFile = true;
    this.removeUpload = false;
    this.myform.patchValue({
      file: [null]
    });
  }
}
