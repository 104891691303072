<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>Create Section</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Create Section</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">Section</h3>
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                      title="Collapse">
                      <i class="fas fa-minus"></i></button>
                  </div>
                </div>
                <div class="card-body">
                  <form [formGroup]="myform">
                    <div class="form-group">
                      <label for="inputName">Section Code</label>
                      <input type="text" id="inputName" class="form-control" formControlName="code"
                        [ngClass]="{ 'is-invalid': submitted && f.code.errors }">
                      <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                        <div *ngIf="f.code.errors.required">Section Code is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputDescription">Definition</label>
                      <textarea id="inputDescription" class="form-control" rows="4" formControlName="definition"
                        [ngClass]="{ 'is-invalid': submitted && f.definition.errors }"></textarea>
                      <div *ngIf="submitted && f.definition.errors" class="invalid-feedback">
                        <div *ngIf="f.definition.errors.required">Definition is required</div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <input type="submit" value="Create new Section" class="btn btn-success" (click)="submit()">
            </div>
          </div>
        </div>
      </section>
    </div>
    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
  </div>