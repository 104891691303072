<div class="wrapper">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-3">
            <a (click)="add()" [class.disabled]="create" class="btn btn-sm btn-primary" style="color: white;">Add New
              User</a>
          </div>
          <div class="col-sm-3">

          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">User List</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">User List</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
              title="Collapse">
              <i class="fas fa-minus"></i></button>
          </div>
        </div>
        <div class="card-body p-0 mt-2">
          <table class="table table-striped projects" *ngIf="loadTable" datatable>
            <thead>
              <tr>
                <th>
                  Sno
                </th>
                <th>
                  Date
                </th>
                <th>
                  First Name
                </th>
                <th>
                  Last Name
                </th>
                <th>
                  User Name
                </th>
                <th>
                  Contact
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of userlist; let i = index;">
                <td>{{ i + 1 }}</td>
                <td>{{ data.date_time | date: "dd-MM-yyyy" }}</td>
                <td>{{ data.first_name }}</td>
                <td>{{ data.last_name }}</td>
                <td>{{ data.username }}</td>
                <td>{{ data.phone_number }}</td>
                <td class="project-actions text-right">
                  <a *ngIf="data.status == '1'" [class.disabled]="edit" class="btn btn-info btn-sm" data-toggle="modal"
                    data-target="#exampleModalCenter" style="color: white;" (click)="open(data)">
                    <i class="fas fa-pencil-alt">
                    </i>
                    Edit
                  </a>&nbsp;
                  <a *ngIf="data.status == '1'" [class.disabled]="del" class="btn btn-danger btn-sm"
                    style="color: white;"
                    (click)="delete(data.user_id,'0','You will not be able to recover this User Details!','Yes, delete it!')">
                    <i class="fas fa-trash">
                    </i>
                    Delete
                  </a>
                  <a *ngIf="data.status == '0'" [class.disabled]="del" class="btn btn-success btn-sm"
                    style="color: white;"
                    (click)="delete(data.user_id,'1','You will Activate this User Details!','Yes, Activate it!')">
                    <i class="far fa-check-circle"></i>
                    Activate
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLongTitle">Update User</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="myform">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>First Name</label>
                <input type="text" id="inputName" class="form-control" formControlName="firstname"
                  [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                  <div *ngIf="f.firstname.errors.required">First Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Last Name</label>
                <input type="text" id="inputName" class="form-control" formControlName="lastname"
                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>User Name</label>
                <input type="text" id="inputName" class="form-control" formControlName="username"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">User Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Role Type</label>
                <select class="form-control custom-select" formControlName="role_type"
                  [ngClass]="{ 'is-invalid': submitted && f.role_type.errors }" (change)="select_roletype()">
                  <option selected disabled>Select one</option>
                  <option value="Admin">Admin</option>
                  <option value="Backend Only">Backend Only</option>
                  <option value="Staff">Staff</option>
                  <option value="Branch Admin">Branch Admin</option>
                  <option value="Institution Admin">Institution Admin</option>
                </select>
                <div *ngIf="submitted && f.role_type.errors" class="invalid-feedback">
                  <div *ngIf="f.role_type.errors.required">Role Type is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Password</label>
                <input type="password" id="inputName" class="form-control" formControlName="password">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Confirm Password</label>
                <input type="password" id="inputName" class="form-control" formControlName="confirm_password">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone No</label>
                <input type="text" id="inputName" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                  class="form-control" formControlName="phone">
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                </div>
                <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger">
                  <div *ngIf="f.phone.errors.required">Phone Number is required.</div>
                  <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Phone Number.</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Email ID</label>
                <input type="text" id="inputName" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  class="form-control" formControlName="email">
                <div *ngIf="Email.errors?.email" class="alert alert-danger">
                  Email not valid.
                </div>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="" *ngIf="role_section">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Add Role</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Collapse">
                    <i class="fas fa-minus"></i></button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="">Role</label>
                      <select class="form-control custom-select" [(ngModel)]="roleSelect" (change)="selectrole()"
                        formControlName="role">
                        <option *ngFor='let option of rolelist' [ngValue]="option">
                          {{option.RoleName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Permission</label>
                      <ng-select [items]="permissionlist" bindLabel="PermissionName" placeholder="Select Permission"
                        appendTo="body" multiple="true" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedPermission">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-3 offset-md-1 mt-4">
                    <input type="submit" value="Add" class="btn btn-info" (click)="addItems()">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Role</th>
                          <th style="display: none;">Role Id</th>
                          <th>Permission</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of items;let i= index">
                          <td>{{item.sno}}</td>
                          <td>{{item.RoleName}}</td>
                          <td style="display: none;">{{item.RoleId}}</td>
                          <td>
                            <ng-select [items]="permissionlist" bindLabel="PermissionName"
                              placeholder="Select Permission" appendTo="body" multiple="true"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="item.child">
                            </ng-select>
                          </td>
                          <td class="project-actions text-right">
                            <a class="btn btn-danger btn-sm" (click)="remove(item.sno)" style="color: white;">
                              <i class="fas fa-trash">
                              </i>
                              Remove
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" *ngIf="section_institute">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Add Section</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Collapse">
                    <i class="fas fa-minus"></i></button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Institution</label>
                      <select class="form-control custom-select" (change)="searchbranch()"
                        [(ngModel)]="selectedinstitute" formControlName="institute">
                        <option *ngFor='let option of institutelist' [ngValue]="option">
                          {{option.InstituteName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 mt-4">
                    <input type="submit" value="Add" class="btn btn-info" (click)="addItems1()">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Institution</th>
                          <th style="display: none;">Institution Id</th>
                          <th style="display: none;">Branch Id</th>
                          <th style="display: none;">Programme Id</th>
                          <th style="display: none;">Section</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of items1;let i= index;">
                          <td>{{item.Sno}}</td>
                          <td>{{item.InstituteName}}</td>
                          <td style="display: none;">{{item.InstituteId}}</td>
                          <td style="display: none;">{{item.BranchId}}</td>
                          <td style="display: none;">{{item.ProgramId}}</td>
                          <td style="display: none;">{{item.child}}</td>
                          <td class="project-actions text-right">
                            <a class="btn btn-danger btn-sm" (click)="remove1(item.Sno)" style="color: white;">
                              <i class="fas fa-trash">
                              </i>
                              Remove
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" *ngIf="section_branch">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Add Section</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Collapse">
                    <i class="fas fa-minus"></i></button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Institution</label>
                      <select class="form-control custom-select" (change)="searchbranch()"
                        [(ngModel)]="selectedinstitute" formControlName="institute">
                        <option *ngFor='let option of institutelist' [ngValue]="option">
                          {{option.InstituteName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Branch</label>
                      <select class="form-control custom-select" (change)="searchprogram()" [(ngModel)]="selectedbranch"
                        formControlName="branch">
                        <option *ngFor='let option of branchlist' [ngValue]="option">
                          {{option.branch}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 mt-4">
                    <input type="submit" value="Add" class="btn btn-info" (click)="addItems1()">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Institution</th>
                          <th style="display: none;">Institution Id</th>
                          <th>Branch</th>
                          <th style="display: none;">Branch Id</th>
                          <th style="display: none;">Programme Id</th>
                          <th style="display: none;">Section</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of items1;let i= index">
                          <td>{{item.Sno}}</td>
                          <td>{{item.InstituteName}}</td>
                          <td style="display: none;">{{item.InstituteId}}</td>
                          <td>{{item.Branchcode}}</td>
                          <td style="display: none;">{{item.BranchId}}</td>
                          <td style="display: none;">{{item.ProgramId}}</td>
                          <td style="display: none;">{{item.child}}</td>
                          <td class="project-actions text-right">
                            <a class="btn btn-danger btn-sm" (click)="remove1(item.Sno)" style="color: white;">
                              <i class="fas fa-trash">
                              </i>
                              Remove
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" *ngIf="section_all">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Add Section</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Collapse">
                    <i class="fas fa-minus"></i></button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Institution</label>
                      <select class="form-control custom-select" (change)="searchbranch()"
                        [(ngModel)]="selectedinstitute" formControlName="institute">
                        <option *ngFor='let option of institutelist' [ngValue]="option">
                          {{option.InstituteName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Branch</label>
                      <select class="form-control custom-select" (change)="searchprogram()" [(ngModel)]="selectedbranch"
                        formControlName="branch">
                        <option *ngFor='let option of branchlist' [ngValue]="option">
                          {{option.branch}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Programme</label>
                      <select class="form-control custom-select" (change)="searchsection()"
                        [(ngModel)]="selectedprogram" formControlName="program">
                        <option *ngFor='let option of programlist' [ngValue]="option">
                          {{option.program_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Section</label>
                      <ng-select [items]="sectionlist" bindLabel="ClassCode" placeholder="Select Section"
                        appendTo="body" multiple="true" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedSection">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-1 mt-4">
                    <input type="submit" value="Add" class="btn btn-info" (click)="addItems1()">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Institution</th>
                          <th style="display: none;">Institution Id</th>
                          <th>Branch</th>
                          <th style="display: none;">Branch Id</th>
                          <th>Programme</th>
                          <th style="display: none;">Programme Id</th>
                          <th>Section</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of items1;let i= index">
                          <td>{{item.Sno}}</td>
                          <td>{{item.InstituteName}}</td>
                          <td style="display: none;">{{item.InstituteId}}</td>
                          <td>{{item.Branchcode}}</td>
                          <td style="display: none;">{{item.BranchId}}</td>
                          <td>{{item.ProgramName}}</td>
                          <td style="display: none;">{{item.ProgramId}}</td>
                          <td>
                            <ng-select [items]="sectionlist" bindLabel="ClassCode" placeholder="Select Section"
                              appendTo="body" multiple="true" [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="item.child">
                            </ng-select>
                          </td>
                          <td class="project-actions text-right">
                            <a class="btn btn-danger btn-sm" (click)="remove1(item.Sno)" style="color: white;">
                              <i class="fas fa-trash">
                              </i>
                              Remove
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-12">
            <input type="submit" value="Update" class="btn btn-success" data-dismiss="modal" (click)="submit()">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>