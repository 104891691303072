<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Create Theme</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Create Theme</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Theme</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                    <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="myform">
                                    <div class="form-group">
                                        <label for="">Academic Year</label>
                                        <select class="form-control custom-select" formControlName="academic">
                      <option *ngFor='let option of academiclist' [value]="option.AcademicId">
                        {{ option.AcademicCode }}</option>
                    </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Program</label>
                                        <select class="form-control custom-select" formControlName="program">
                      <option *ngFor='let option of programlist' [value]="option.id">
                        {{ option.ProgramName }}</option>
                    </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Theme Name</label>
                                        <input type="text" id="" class="form-control" formControlName="theme_name" [ngClass]="{ 'is-invalid': submitted && f.theme_name.errors }">
                                        <div *ngIf="submitted && f.theme_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.theme_name.errors.required">Theme Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputName">Week</label>
                                        <select class="form-control custom-select" formControlName="weeks" [ngClass]="{ 'is-invalid': submitted && f.weeks.errors }">
                      <option selected disabled>Select one</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                                        <div *ngIf="submitted && f.weeks.errors" class="invalid-feedback">
                                            <div *ngIf="f.weeks.errors.required">Week is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputName">Day</label>
                                        <select class="form-control custom-select" formControlName="days" [ngClass]="{ 'is-invalid': submitted && f.days.errors }">
                      <option selected disabled>Select one</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                                        <div *ngIf="submitted && f.days.errors" class="invalid-feedback">
                                            <div *ngIf="f.days.errors.required">Day is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputName">Theme Icon (20px * 20px)</label>
                                        <div class="group-gap">
                                            <div class="avatar-upload">
                                                <div class="avatar-edit">
                                                    <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFile($event)" />
                                                </div>
                                                <div class="avatar-preview">
                                                    <div class="preview" *ngIf="imageUrl && imageUrl !== null">
                                                        <img [src]="imageUrl" width="20" height="20">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputName">Color Picker</label>
                                        <input [value]="color1" class="form-control" formControlName="color_code" [style.background]="color1" [(colorPicker)]="color1" (colorPickerChange)="cmykColor=onChangeColorCmyk($event);" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <input type="submit" value="Create new Theme" class="btn btn-success" (click)="submit()">
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
</div>