<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Edit Activity</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Edit Activity</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Activity</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                        data-toggle="tooltip" title="Collapse">
                                        <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="myform">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Activity Name</label>
                                                <input type="text" id="" class="form-control"
                                                    formControlName="activity_name"
                                                    [ngClass]="{ 'is-invalid': submitted && f.activity_name.errors }">
                                                <div *ngIf="submitted && f.activity_name.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.activity_name.errors.required">Activity Name is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Subject</label>&nbsp;<button class="badge btn-danger"
                                                    (click)="subject_change()">
                                                    Change</button>
                                                <input type="text" id="inputName" class="form-control" readonly
                                                    formControlName="subject" *ngIf="hide_subject">
                                                <select *ngIf="!hide_subject" class="form-control custom-select"
                                                    [(ngModel)]="mySelect" (change)="selectsubject()"
                                                    formControlName="subject">
                                                    <option *ngFor='let option of subjectlist' [ngValue]="option">
                                                        {{option.SubjectCode}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                                    <div *ngIf="f.subject.errors.required">Subject is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Day Order</label>
                                                <select class="form-control custom-select" formControlName="day_order"
                                                    [ngClass]="{ 'is-invalid': submitted && f.day_order.errors }">
                                                    <option selected disabled>Select one</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                                <div *ngIf="submitted && f.day_order.errors" class="invalid-feedback">
                                                    <div *ngIf="f.day_order.errors.required">Day Order is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label>Type</label>
                                                <select class="form-control custom-select" formControlName="type"
                                                    [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                                    <option selected disabled>Select one</option>
                                                    <option value="Home Activity">Home Activity</option>
                                                    <option value="Take">Take</option>
                                                </select>
                                                <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                                    <div *ngIf="f.type.errors.required">Type is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="">Duration</label>
                                                <div class="ngx-timepicker-field-example">
                                                    <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'"
                                                        formControlName="duration">
                                                    </ngx-timepicker-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Intelligent</label>
                                                <ng-select [items]="intelligencelist" bindLabel="IntelligentName"
                                                    placeholder="Select Intelligent" appendTo="body" multiple="true"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="selectedIntelligent">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Skill</label>
                                                <ng-select [items]="skill_list" bindLabel="SkillName"
                                                    placeholder="Select Skill" appendTo="body" multiple="true"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSkill">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Learning Outcome</label>
                                                <!-- <quill-editor [(ngModel)]="Learningdata"
                                  [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="Learningdata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Teacher Notes</label>
                                                <!-- <quill-editor [(ngModel)]="notesdata"
                                  [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="notesdata" [ngModelOptions]="{standalone: true}"
                                                    [editor]="Editor" (ready)="onReady($event)" [config]="config">
                                                </ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Course of Action</label>
                                                <!-- <quill-editor [(ngModel)]="coursedata"
                                  [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="coursedata" [ngModelOptions]="{standalone: true}"
                                                    [editor]="Editor" (ready)="onReady($event)" [config]="config">
                                                </ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Resources</label>
                                                <!-- <quill-editor [(ngModel)]="worksheetdata"
                              [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="worksheetdata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)" [config]="config"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Materials Required</label>
                                                <!-- <quill-editor [(ngModel)]="materialdata"
                              [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="materialdata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)" [config]="config"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Reference</label>
                                                <!-- <quill-editor [(ngModel)]="referencedata"
                                  [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="referencedata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)" [config]="config"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <input type="submit" value="Edit Activity" class="btn btn-success" (click)="submit()">
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
</div>