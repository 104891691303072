import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { DatePipe } from "@angular/common";
import { User } from '../Model/user';

@Component({
  selector: 'app-addinstitute',
  templateUrl: './addinstitute.component.html',
  styleUrls: ['./addinstitute.component.css'],
  providers: [DatePipe]
})
export class AddinstituteComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any;
  submitted = false;
  anybranch: any;
  noOfbranch: boolean;
  programlist = [];
  selectedProgram = [];
  any_branch: boolean;

  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe,
    private cd: ChangeDetectorRef, private apiservice: api, public datepipe: DatePipe, private router: Router, private swalservice: swal) {

  }
  myform = this.formBuilder.group({
    institution_name: ['', Validators.required],
    institution_code: ['', Validators.required],
    start_date: ['', Validators.required],
    founder_name: [''],
    email: ['', [Validators.required, Validators.email]],
    alter_email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    office_phone: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    noOf_branch: [''],
    institute_type: ['', Validators.required],
    board: [''],
    pan: [''],
    gst: [''],
    address: [''],
    establish_year: [''],
    file: [null]
  });
  get f() {
    return this.myform.controls;
  }
  get Email() {
    return this.myform.get('email');
  }
  get AlterEmail() {
    return this.myform.get('alter_email');
  }
  submit() {
    this.myform.value;
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.myform.value.noOf_branch == "" || this.myform.value.noOf_branch == undefined) {
      this.myform.controls['noOf_branch'].setValue(0);
    }
    let mytype = "AddInstitute";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "InstituteName": this.myform.value.institution_name,
      "InstituteCode": this.myform.value.institution_code,
      "StartDate": this.myform.value.start_date,
      "FounderName": this.myform.value.founder_name,
      "Email": this.myform.value.email,
      "AlternateEmail": this.myform.value.alter_email,
      "Phone": this.myform.value.phone,
      "OfficeNo": this.myform.value.office_phone,
      "AnyBranch": this.anybranch,
      "No_of_Branch": this.myform.value.noOf_branch,
      "InstituteType": this.myform.value.institute_type,
      "Board": this.myform.value.board,
      "PanNo": this.myform.value.pan,
      "GstNo": this.myform.value.gst,
      "Address": this.myform.value.address,
      "Logo": this.myform.value.file,
      "EstablishmentYear": this.myform.value.establish_year,
      "user_id": this.userid,
    }
    mydata["program_data"] = [];
    this.selectedProgram.forEach(item => {
      var child = {};
      child["program_id"] = item.ProgramId;
      mydata["program_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddInstitute", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.imageUrl = '';
          this.ngOnInit();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.myform.patchValue({
          file: reader.result
        });
      }
      this.cd.markForCheck();
    }
  }
  ngOnInit(): void {
    this.any_branch = false;
    this.anybranch = "No";
    this.noOfbranch = true;
    this.selectedProgram = [];
    //this.myform.controls['start_date'].setValue(this.datepipe.transform(new Date(), 'dd-MM-yyyy'));
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
    this.programlist = [];
    let mytype1 = "List_ActiveProgram";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("List_ActiveProgram", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  branch() {
    if (this.anybranch == "No") {
      this.anybranch = "Yes";
      this.noOfbranch = false;
    } else {
      this.anybranch = "No";
      this.noOfbranch = true;
      this.myform.controls['noOf_branch'].setValue('');
    }
  }
}
