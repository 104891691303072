import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { User } from '../Model/user';

interface item {
  Sno: any;
  Title: any;
  Description: any;
  OrderBy: any;
}

@Component({
  selector: 'app-listmonth',
  templateUrl: './listmonth.component.html',
  styleUrls: ['./listmonth.component.css']
})
export class ListmonthComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  monthlist = [];
  details = [];
  loadTable: boolean;
  month_id: any;
  myform: FormGroup;
  submitted = false;
  orderItem: item;
  items: Array<item>;
  mySelect: any;
  academiclist = [];
  programlist = [];
  academic_id: any;
  program_id: any;
  title: any;
  create: any;
  edit: any;
  del: any;

  hide_theme: boolean;
  hide_academic: boolean;
  hide_program: boolean;
  search_academicid: any;
  search_programid: any;
  pgSelect: any;
  acSelect: any;
  theme_id: any;
  themelist = []; 
  academic_code: any;
  program_name: any;
  activeprogramlist = [];

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.items = [];
    this.myform = this.formBuilder.group({
      theme: [''],
      orderby: ['', Validators.required],
      academic: [''],
      program: [''],
      title: [''],
      description: [''],
      order_by: ['']
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  ngOnInit(): void {
    this.load();
  }
  academic_change() {
    this.hide_academic = false;
    this.academic_id = "";
  }
  program_change() {
    this.hide_program = false;
    this.program_id = "";
  }
  theme_change() {
    this.hide_theme = false;
    this.theme_id = "";
  }
  load() {
    this.hide_academic = true;
    this.hide_program = true;
    this.hide_theme = true;
    this.loadTable = false;
    let mytype = "ListMonth";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListMonth", data);
        if (data.result == 1) {
          this.monthlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "Getpermission_user";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 13,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });

    this.items = [];
    this.academiclist = [];
    let mytype1 = "List_ActiveAcademic";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("List_ActiveAcademic", data);
        if (data.result == 1) {
          this.academiclist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
      this.activeprogramlist = [];
      let mytype2 = "List_ActiveProgram";
      let mydata2 = {
        "secret_key": "2020140715947422055f0dd5bd3ba8f",
      }
      this.apiservice.apipost(mydata2, mytype2)
        .subscribe((data: any) => {
          console.log("List_ActiveProgram", data);
          if (data.result == 1) {
            this.activeprogramlist = data.data;
          } else {
            this.swalservice.warningalert(data.msg);
          }
        });
  }
  add() {
    this.router.navigateByUrl("AddMonth");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.items.length == 0) {
      this.swalservice.warningalert("Add Details");
      return;
    }
    if (this.theme_id == "" || this.theme_id == undefined) {
      this.swalservice.warningalert("select theme");
      return;
    }
    let mytype = "EditMonth";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "MonthId": this.month_id,
      "ThemeId": this.theme_id,
      "AcademicId": this.academic_id,
      "ProgramId": this.program_id,
      "OrderBy": this.myform.value.orderby,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];
    this.items.forEach(item => {
      var child = {};
      child["Sno"] = item.Sno;
      child["Title"] = item.Title;
      child["Description"] = item.Description;
      child["OrderBy"] = item.OrderBy;
      mydata["child_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditMonth", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
          this.load();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  open(data) {
    this.items = [];
    this.month_id = data.MonthId;
    this.myform.controls['theme'].setValue(data.theme_name);
    this.theme_id = data.ThemeId;
    this.myform.controls['orderby'].setValue(data.OrderBy);
    this.myform.controls['academic'].setValue(data.AcademicCode);
    this.academic_id = data.AcademicId;
    this.myform.controls['program'].setValue(data.ProgramName);
    this.program_id = data.ProgramId;

    let mytype = "ListMonthByid";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "MonthId": data.MonthId,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListMonthByid", data);
        if (data.result == 1) {
          data.data.forEach(element => {
            this.items.push(element);
          });
          if (this.items && this.items.length > 0) {
            this.items.forEach(element => {
              element.Sno = element.Sno;
              element.Title = element.Title;
              element.Description = element.Description;
              element.OrderBy = element.OrderBy;
            });
          }
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteMonth";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "MonthId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteMonth", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  selectacademic() {
    this.academic_id = this.acSelect.AcademicId;
    this.academic_code = this.acSelect.AcademicCode;
    this.programlist = [];
    let mytype3 = "GetProgramByAcademic";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "AcademicCode": this.academic_code,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetProgramByAcademic", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectprogram() {
    this.program_id = this.pgSelect.ProgramId;
    this.program_name = this.pgSelect.ProgramName;
    this.themelist = [];
    let mytype3 = "GetThemeByProgram";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ProgramName": this.program_name,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetThemeByProgram", data);
        if (data.result == 1) {
          this.themelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selecttheme() {
    this.theme_id = this.mySelect.ThemeId;
  }
  addItems() {
    if (this.myform.value.title != "" && this.myform.value.description != "" && this.myform.value.order_by != "") {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        Title: this.myform.value.title,
        Description: this.myform.value.description,
        OrderBy: this.myform.value.order_by,
        Sno: index,
      };
      this.items.push(this.orderItem);
      this.myform.controls['title'].setValue("");
      this.myform.controls['description'].setValue("");
      this.myform.controls['order_by'].setValue("");
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].Sno === sno) {
        this.items.splice(i, 1);
      }
    }
  }
  month_view(monthid) {
    let mytype4 = "Add_Monthview";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "MonthId": monthid,
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("Add_Monthview", data);
        if (data.result == 1) {
          window.open("http://uatpreview-nurturelab.caprics.in/month", "_blank");
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  search() {
    this.loadTable = false;
    this.monthlist = [];
    if (this.search_academicid == undefined) {
      this.search_academicid = "";
    }
    if (this.search_programid == undefined) {
      this.search_programid = "";
    }
    let mytype4 = "Month_Search";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "AcademicId": this.search_academicid,
      "ProgramId": this.search_programid,
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("Month_Search", data);
        if (data.result == 1) {
          this.monthlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchacademic() {
    this.search_academicid = this.acSelect.AcademicId;
  }
  searchprogram() {
    this.search_programid = this.pgSelect.ProgramId;
  }
}

