<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Curriculum</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Curriculum</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Edit Curriculum</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                        data-toggle="tooltip" title="Collapse">
                                        <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="myform">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Theme </label>&nbsp;<button class="badge btn-danger"
                                                    (click)="theme_change()" *ngIf="hide_theme">
                                                    Change</button>
                                                <input type="text" id="inputName" class="form-control" readonly
                                                    formControlName="theme" *ngIf="hide_theme">
                                                <select *ngIf="!hide_theme" class="form-control custom-select"
                                                    [(ngModel)]="mySelect" (change)="selecttheme()"
                                                    formControlName="theme"
                                                    [ngClass]="{ 'is-invalid': submitted && f.theme.errors }">
                                                    <option *ngFor='let option of themelist' [ngValue]="option">
                                                        {{option.ThemeName}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.theme.errors" class="invalid-feedback">
                                                    <div *ngIf="f.theme.errors.required">Theme is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Academic</label>
                                                <input type="text" readonly id="inputName" class="form-control"
                                                    formControlName="academic">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <!-- <label for="">Activity </label>&nbsp;<button class="badge btn-danger"
                                                    (click)="activity_change()" *ngIf="hide_activity">
                                                    Change</button>
                                                <input type="text" id="inputName" *ngIf="hide_activity"
                                                    class="form-control" readonly formControlName="activity"> -->
                                                <label for="">Activity</label>
                                                <select class="form-control custom-select" [(ngModel)]="activity_Select"
                                                    (change)="selectactivity()" formControlName="activity">
                                                    <option *ngFor='let option of activitylist' [ngValue]="option">
                                                        {{option.ActivityName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Program</label>
                                                <input readonly type="text" id="inputName" class="form-control"
                                                    formControlName="program">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Subject </label>&nbsp;<button class="badge btn-danger"
                                                    (click)="subject_change()" *ngIf="hide_subject">
                                                    Change</button>
                                                <input type="text" id="inputName" class="form-control" readonly
                                                    formControlName="subject" *ngIf="hide_subject">
                                                <select *ngIf="!hide_subject" class="form-control custom-select"
                                                    formControlName="subject" [(ngModel)]="subjectSelect"
                                                    [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"
                                                    (change)="selectsubject()">
                                                    <option *ngFor='let option of subjectlist' [ngValue]="option">
                                                        {{option.SubjectCode}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                                    <div *ngIf="f.subject.errors.required">Subject is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Topic</label>
                                                <input type="text" id="inputName" class="form-control"
                                                    formControlName="topic"
                                                    [ngClass]="{ 'is-invalid': submitted && f.topic.errors }">
                                                <div *ngIf="submitted && f.topic.errors" class="invalid-feedback">
                                                    <div *ngIf="f.topic.errors.required">Topic is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Day Order</label>
                                                <select class="form-control custom-select" formControlName="day_order"
                                                    [ngClass]="{ 'is-invalid': submitted && f.day_order.errors }">
                                                    <option selected disabled>Select one</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                                <div *ngIf="submitted && f.day_order.errors" class="invalid-feedback">
                                                    <div *ngIf="f.day_order.errors.required">Day Order is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Duration</label>
                                                <input type="text" id="inputName" class="form-control"
                                                    placeholder="hours/mins" formControlName="duration"
                                                    [ngClass]="{ 'is-invalid': submitted && f.duration.errors }">
                                                <div *ngIf="submitted && f.duration.errors" class="invalid-feedback">
                                                    <div *ngIf="f.duration.errors.required">Duration is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Week</label>&nbsp;<button class="badge btn-danger"
                                                    (click)="week_change()" *ngIf="hide_week">
                                                    Change</button>
                                                <input type="text" id="inputName" class="form-control" readonly
                                                    formControlName="week" *ngIf="hide_week">
                                                <select *ngIf="!hide_week" class="form-control custom-select"
                                                    [(ngModel)]="weekSelect" (change)="selectweek()"
                                                    formControlName="week"
                                                    [ngClass]="{ 'is-invalid': submitted && f.week.errors }">
                                                    <option *ngFor='let option of weeks' [ngValue]="option">
                                                        {{option.week}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.week.errors" class="invalid-feedback">
                                                    <div *ngIf="f.week.errors.required">Week is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Day</label>&nbsp;<button class="badge btn-danger"
                                                    *ngIf="hide_day" (click)="day_change()">
                                                    Change</button>
                                                <input type="text" id="inputName" class="form-control" readonly
                                                    formControlName="day" *ngIf="hide_day">
                                                <select *ngIf="!hide_day" class="form-control custom-select"
                                                    formControlName="day" [(ngModel)]="daySelect" (change)="selectday()"
                                                    [ngClass]="{ 'is-invalid': submitted && f.week.errors }">
                                                    <option *ngFor='let option of days' [ngValue]="option">
                                                        {{option.day}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.day.errors" class="invalid-feedback">
                                                    <div *ngIf="f.day.errors.required">Day is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Intelligent</label>
                                                <ng-select [items]="intelligencelist" bindLabel="IntelligentName"
                                                    placeholder="Select Intelligent" appendTo="body" multiple="true"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="selectedIntelligent">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Skill</label>
                                                <ng-select [items]="skill_list" bindLabel="SkillName"
                                                    placeholder="Select Skill" appendTo="body" multiple="true"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSkill">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Learning Outcome</label>
                                                <!-- <quill-editor [(ngModel)]="Learningdata"
                                                    [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="Learningdata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)" [config]="config"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Teacher Notes</label>
                                                <!-- <quill-editor [(ngModel)]="notesdata"
                                                    [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="notesdata" [ngModelOptions]="{standalone: true}"
                                                    [editor]="Editor" (ready)="onReady($event)" [config]="config">
                                                </ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Course of Action</label>
                                                <!-- <quill-editor [(ngModel)]="coursedata"
                                                    [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="coursedata" [ngModelOptions]="{standalone: true}"
                                                    [editor]="Editor" (ready)="onReady($event)" [config]="config">
                                                </ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Resources</label>
                                                <!-- <quill-editor [(ngModel)]="worksheetdata"
                                                    [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="worksheetdata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)" [config]="config"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Materials Required</label>
                                                <!-- <quill-editor [(ngModel)]="materialdata"
                                                    [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="materialdata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)" [config]="config"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="inputDescription">Reference</label>
                                                <!-- <quill-editor [(ngModel)]="referencedata"
                                                    [ngModelOptions]="{standalone: true}"></quill-editor> -->
                                                <ckeditor [(ngModel)]="referencedata"
                                                    [ngModelOptions]="{standalone: true}" [editor]="Editor"
                                                    (ready)="onReady($event)" [config]="config"></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Edit Curriculum" class="btn btn-success" (click)="submit()">
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>