import { Component, OnInit,ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User } from '../Model/user';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

// interface Quill {
//   getModule(moduleName: string);
// }

// interface BetterTableModule {
//   insertTable(rows: number, columns: number): void;
// }

export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = e => {
            resolve({ default: myReader.result });
          };

          myReader.readAsDataURL(file);
        })
    );
  }
}

@Component({
  selector: 'app-addcurriculam',
  templateUrl: './addcurriculam.component.html',
  styleUrls: ['./addcurriculam.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddcurriculamComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  myform: FormGroup;
  public Editor = ClassicEditor;
  Learningdata = '';
  coursedata = '';
  notesdata = '';
  worksheetdata = '';
  materialdata = '';
  referencedata = '';
  submitted = false;
  themelist = [];
  activitylist = [];
  subjectlist = [];
  intelligencelist = [];
  skill_list = [];
  mySelect: any;
  activity_Select: any;
  weekSelect: any;
  daySelect: any;
  intelSelect: any;
  skillselect: any;
  subjectSelect: any;
  theme_id: any;
  subjectid: any;
  intelligence_id: any;
  skill_id: any;
  imageUrl: any;
  imageUrl1: any;
  selectedIntelligent = [];
  selectedSkill = [];
  weeks = [];
  days = [];
  selected_week: any;
  selected_day: any;
  activity_id: any;
  program_id: any;
  academic_id: any;
  hide_subject: boolean;

  //public quill: Quill;
  public config = { link: { addTargetToExternalLinks: true } };

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      activity: [''],
      theme: ['', Validators.required],
      academic: [''],
      program: [''],
      subject: ['', Validators.required],
      topic: ['', Validators.required],
      day_order: ['', Validators.required],
      duration: [''],
      week: [''],
      day: [''],
      intelligence: [''],
      skill: [''],
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function(
      loader
    ) {
      console.log('loader : ', loader);
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }
  // private get tableModule(): BetterTableModule {
  //   return this.quill.getModule("better-table");
  // }

  // public editorCreated(event: Quill): void {
  //   this.quill = event;
  //   this.addNewtable();
  // }

  // private addNewtable(): void {
  //   this.tableModule.insertTable(3, 3);
  // }
  // public onReady(editor) {
  //   editor.ui.getEditableElement().parentElement.insertBefore(
  //     editor.ui.view.toolbar.element,
  //     editor.ui.getEditableElement()
  //   );
  // }
  get f() {
    return this.myform.controls;
  }
  // learnChange({ editor }: ChangeEvent) {
  //   this.Learningdata = editor.getData();
  // }
  // notesChange({ editor }: ChangeEvent) {
  //   this.notesdata = editor.getData();
  // }
  // courseChange({ editor }: ChangeEvent) {
  //   this.coursedata = editor.getData();
  // }
  // workChange({ editor }: ChangeEvent) {
  //   this.worksheetdata = editor.getData();
  // }
  // materialChange({ editor }: ChangeEvent) {
  //   this.materialdata = editor.getData();
  // }
  // referenceChange({ editor }: ChangeEvent) {
  //   this.referencedata = editor.getData();
  // }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.activity_id == "" || this.activity_id == undefined) {
      this.activity_id = 0;
    }
    let mytype = "AddCurriculum";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeId": this.theme_id,
      "AcademicId": this.academic_id,
      "ProgramId": this.program_id,
      "ActivityId": this.activity_id,
      "SubjectId": this.subjectid,
      "Topic": this.myform.value.topic,
      "DayOrder": this.myform.value.day_order,
      "Duration": this.myform.value.duration,
      "Week": this.selected_week,
      "Day": this.selected_day,
      "Learning": this.Learningdata,
      "Notes": this.notesdata,
      "Course": this.coursedata,
      "Worksheet": this.worksheetdata,
      "OutcomeResource": this.worksheetdata,
      "Materials": this.materialdata,
      "Reference": this.referencedata,
      "user_id": this.userid,
    }
    mydata["intelligent_data"] = [];
    this.selectedIntelligent.forEach(item => {
      var child = {};
      child["IntelligentId"] = item.id;
      mydata["intelligent_data"].push(child);
    });
    mydata["skill_data"] = [];
    this.selectedSkill.forEach(item => {
      var child = {};
      child["SkillId"] = item.id;
      mydata["skill_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddCurriculum", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.load();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }

  ngOnInit(): void {
    this.load();
  }
  load() {
    this.activity_id = 0;
    this.hide_subject = false;
    this.selectedIntelligent = [];
    this.selectedSkill = [];
    this.imageUrl = '';
    this.imageUrl1 = '';
    this.Learningdata = '';
    this.coursedata = '';
    this.notesdata = '';
    this.worksheetdata = '';
    this.materialdata = '';
    this.referencedata = '';
    this.themelist = [];
    this.activitylist = [];
    this.subjectlist = [];
    this.intelligencelist = [];
    this.skill_list = [];
    let mytype = "List_ActiveTheme";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveTheme", data);
        if (data.result == 1) {
          this.themelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype2 = "List_ActiveIntelligence";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("List_ActiveIntelligence", data);
        if (data.result == 1) {
          this.intelligencelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "List_ActiveSkill";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("List_ActiveSkill", data);
        if (data.result == 1) {
          this.skill_list = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype4 = "List_ActiveActivity";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("List_ActiveActivity", data);
        if (data.result == 1) {
          this.activitylist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selecttheme() {
    this.theme_id = this.mySelect.ThemeId;
    this.academic_id = this.mySelect.AcademicId;
    this.program_id = this.mySelect.ProgramId;
    this.myform.controls['academic'].setValue(this.mySelect.AcademicCode);
    this.myform.controls['program'].setValue(this.mySelect.ProgramName);
    this.subjectlist = [];
    this.weeks = [];
    this.days = [];
    let mytype3 = "GetSubjectByTheme";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeId": this.theme_id,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetSubjectByTheme", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
          this.weeks = data.weeks_data;
          this.days = data.days_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectactivity() {
    this.activity_id = this.activity_Select.id;
    let mytype3 = "ListActivityByid ";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ActivityId": this.activity_id,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("ListActivityByid ", data);
        if (data.result == 1) {
          this.hide_subject = true;
          this.selectedIntelligent = [];
          this.selectedSkill = [];
          this.myform.controls['day_order'].setValue(data.data[0].DayOrder);
          this.myform.controls['duration'].setValue(data.data[0].Duration);
          this.myform.controls['subject'].setValue(data.data[0].SubjectCode);
          this.subjectid = data.data[0].SubjectId;
          this.Learningdata = data.data[0].Learning;
          this.coursedata = data.data[0].Course;
          this.notesdata = data.data[0].Notes;
          this.worksheetdata = data.data[0].WorkSheet;
          this.materialdata = data.data[0].Materials;
          this.referencedata = data.data[0].Reference;
          this.selectedIntelligent = data.data[0].intelligent_data;
          this.selectedSkill = data.data[0].skill_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectweek() {
    this.selected_week = this.weekSelect.week;
  }
  selectday() {
    this.selected_day = this.daySelect.day;
  }
  selectintel() {
    this.intelligence_id = this.intelSelect.id;
  }
  // selectskill() {
  //   this.skill_id = this.skillselect.Skillid;
  //   this.imageUrl1 = "http://capricsadmin.angelenterprises.in/uploads/skills/" + this.skillselect.SkillIcon;
  // }
  selectsubject() {
    this.subjectid = this.subjectSelect.SubjectId;
    this.myform.controls['duration'].setValue(this.subjectSelect.Duration);
  }
}
