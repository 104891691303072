import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../Model/user';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;

  constructor(private router: Router,private apiservice: api, private swalservice: swal) {
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
   }

  ngOnInit(): void {
    let mytype2 = "getmenu_user";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("getmenu_user", data);
        if (data.result == 1) {
          
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  logout() {
    localStorage.removeItem('CapricsAdminUser');
    this.router.navigateByUrl('Login');
  }
}
