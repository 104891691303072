import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ScheduleModule, ScheduleAllModule, AgendaService, DayService, WeekService, WorkWeekService, MonthService } from '@syncfusion/ej2-angular-schedule';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdduserComponent } from './adduser/adduser.component';
import { AddbranchComponent } from './addbranch/addbranch.component';
import { AddcurriculamComponent } from './addcurriculam/addcurriculam.component';
import { api } from './Api/api.service';
import { swal } from './Common-Service/swal.service';
import { ListprojectComponent } from './listproject/listproject.component';
import { AddprojectComponent } from './addproject/addproject.component';
import { AddsubjectComponent } from './addsubject/addsubject.component';
import { AddacademicComponent } from './addacademic/addacademic.component';
import { ListsubjectComponent } from './listsubject/listsubject.component';
import { ListacademicComponent } from './listacademic/listacademic.component';
import { AddthemeComponent } from './addtheme/addtheme.component';
import { ListthemeComponent } from './listtheme/listtheme.component';
import { DataTablesModule } from 'angular-datatables';
import { AddintelligentComponent } from './addintelligent/addintelligent.component';
import { AddskillComponent } from './addskill/addskill.component';
import { AddrollComponent } from './addroll/addroll.component';
import { AddpermissionComponent } from './addpermission/addpermission.component';
import { ListroleComponent } from './listrole/listrole.component';
import { ListskillComponent } from './listskill/listskill.component';
import { ListintelligentComponent } from './listintelligent/listintelligent.component';
import { ListcurriculumComponent } from './listcurriculum/listcurriculum.component';
import { ListpermissionComponent } from './listpermission/listpermission.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListinstituteComponent } from './listinstitute/listinstitute.component';
import { AddinstituteComponent } from './addinstitute/addinstitute.component';
import { DatePipe } from '@angular/common';
import { AddactivityComponent } from './addactivity/addactivity.component';
import { AddmonthComponent } from './addmonth/addmonth.component';
import { ListactivityComponent } from './listactivity/listactivity.component';
import { ListmonthComponent } from './listmonth/listmonth.component';
import { LoginComponent } from './login/login.component';
import { QuillModule } from 'ngx-quill';
import { QuillConfig } from "ngx-quill";
import * as Quill from "quill";
import QuillBetterTable from "quill-better-table";
import { AddclassComponent } from './addclass/addclass.component';
import { ListclassComponent } from './listclass/listclass.component';
import { ListbranchComponent } from './listbranch/listbranch.component';
import { ListuserComponent } from './listuser/listuser.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { EditactivityComponent } from './editactivity/editactivity.component';
import { EditcurriculumComponent } from './editcurriculum/editcurriculum.component';

let url = "http://demo-nurturelab.caprics.in/admin/api/";
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "red",
  "bgsOpacity": 0.5,
  "bgsPosition": "center-center",
  "bgsSize": 70,
  "bgsType": "square-jelly-box",
  "blur": 15,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#f9f5f5",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "white",
  "pbDirection": "ltr",
  "pbThickness": 4,
  "hasProgressBar": true,
  "text": "Loading...",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 2000
};

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'AddBranch', component: AddbranchComponent },
  { path: 'ListBranch', component: ListbranchComponent },
  { path: 'AddUser', component: AdduserComponent },
  { path: 'ListUser', component: ListuserComponent },
  { path: 'AddCurriculam', component: AddcurriculamComponent },
  { path: 'ListCurriculam', component: ListcurriculumComponent },
  { path: 'AddProject', component: AddprojectComponent },
  { path: 'ProjectList', component: ListprojectComponent },
  { path: 'AddSubject', component: AddsubjectComponent },
  { path: 'SubjectList', component: ListsubjectComponent },
  { path: 'AddAcademic', component: AddacademicComponent },
  { path: 'AcademicList', component: ListacademicComponent },
  { path: 'AddTheme', component: AddthemeComponent },
  { path: 'ThemeList', component: ListthemeComponent },
  { path: 'AddIntelligent', component: AddintelligentComponent },
  { path: 'ListIntelligent', component: ListintelligentComponent },
  { path: 'AddSkill', component: AddskillComponent },
  { path: 'ListSkill', component: ListskillComponent },
  { path: 'AddRole', component: AddrollComponent },
  { path: 'ListRole', component: ListroleComponent },
  { path: 'AddPermission', component: AddpermissionComponent },
  { path: 'ListPermission', component: ListpermissionComponent },
  { path: 'AddInstitute', component: AddinstituteComponent },
  { path: 'ListInstitute', component: ListinstituteComponent },
  { path: 'AddActivity', component: AddactivityComponent },
  { path: 'ListActivity', component: ListactivityComponent },
  { path: 'AddMonth', component: AddmonthComponent },
  { path: 'ListMonth', component: ListmonthComponent },
  { path: 'Login', component: LoginComponent },
  { path: 'AddSection', component: AddclassComponent },
  { path: 'ListSection', component: ListclassComponent },
  { path: 'ckeditor', component: CkeditorComponent },
  { path: 'EditActivity', component: EditactivityComponent },
  { path: 'EditCurriculum', component: EditcurriculumComponent }
];

Quill.register(
  {
    "modules/better-table": QuillBetterTable
  },
  true
);

const quillConfig: QuillConfig = {
  modules: {
    table: false, // disable table module
    "better-table": {
      operationMenu: {
        items: {
          unmergeCells: {
            text: "Another unmerge cells name"
          }
        },
        color: {
          colors: ["#fff", "red", "rgb(0, 0, 0)"], // colors in operationMenu
          text: "Background Colors" // subtitle
        }
      }
    },
    keyboard: {
      bindings: QuillBetterTable.keyboardBindings
    }
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    CalendarComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    AdduserComponent,
    AddbranchComponent,
    AddcurriculamComponent,
    ListprojectComponent,
    AddprojectComponent,
    AddsubjectComponent,
    AddacademicComponent,
    ListsubjectComponent,
    ListacademicComponent,
    AddthemeComponent,
    ListthemeComponent,
    AddintelligentComponent,
    AddskillComponent,
    AddrollComponent,
    AddpermissionComponent,
    ListroleComponent,
    ListskillComponent,
    ListintelligentComponent,
    ListcurriculumComponent,
    ListpermissionComponent,
    ListinstituteComponent,
    AddinstituteComponent,
    AddactivityComponent,
    AddmonthComponent,
    ListactivityComponent,
    ListmonthComponent,
    LoginComponent,
    AddclassComponent,
    ListclassComponent,
    ListbranchComponent,
    ListuserComponent,
    CkeditorComponent,
    EditactivityComponent,
    EditcurriculumComponent
  ],
  imports: [
    BrowserModule, ScheduleModule, ScheduleAllModule, QuillModule.forRoot(), RouterModule.forRoot(routes, { useHash: false }), FormsModule, ReactiveFormsModule, HttpClientModule,
    AppRoutingModule, DataTablesModule, NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), NgSelectModule, CKEditorModule, NgxMaterialTimepickerModule, BrowserAnimationsModule,
    NgxUiLoaderRouterModule, ColorPickerModule, QuillModule.forRoot(quillConfig), NgxUiLoaderHttpModule.forRoot({
      showForeground: true, exclude: [url + 'GetSubjectByTheme', url + 'List_ActiveIntelligence', url + 'GetRole_Child', url + 'GetProgram_Branchid',
      url + 'GetProgramByAcademic', url + 'GetThemeByProgram', url + 'GetDaysByWeek', url + 'GetBranchByInstituteid', url + 'GetSection_Programid',
      url + 'GetInstituteByid', url + 'ListActivityByid']
    })
  ],
  exports: [RouterModule],
  providers: [AgendaService, DayService, WeekService, WorkWeekService, MonthService, api, swal, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
