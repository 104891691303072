import { Component } from '@angular/core';
import { api } from './Api/api.service';
import { Router } from '@angular/router';
import { User } from './Model/user';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  capricsAdminUser: User;

  constructor(public apiservice: api, private router: Router) {
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    console.log("capricsAdminUser", this.capricsAdminUser);
    if(this.capricsAdminUser == undefined){
      this.router.navigateByUrl('Login');
     }
  }
  //title = 'Caprics Admin';
}
