import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { User } from '../Model/user';

@Component({
  selector: 'app-listrole',
  templateUrl: './listrole.component.html',
  styleUrls: ['./listrole.component.css']
})
export class ListroleComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  rolelist = [];
  permissionlist = [];
  selectedPermission = [];
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  roleid: any;
  permission_id: any;
  mySelect: any;
  hide_permission: boolean;
  change_permission: any;
  create: any;
  edit: any;
  del: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      name: ['', Validators.required],
      definition: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  load() {
    this.hide_permission = true;
    this.change_permission = false;
    this.loadTable = false;
    let mytype = "ListRole";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListRole", data);
        if (data.result == 1) {
          this.rolelist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "List_ActivePermission";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("List_ActivePermission", data);
        if (data.result == 1) {
          this.permissionlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype2 = "Getpermission_user";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 9,
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddRole");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "EditRole";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "RoleId": this.roleid,
      "RoleName": this.myform.value.name,
      "Definition": this.myform.value.definition,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];
    this.selectedPermission.forEach(item => {
      var child = {};
      child["PermissionId"] = item.PermissionId;
      mydata["child_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditRole", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteRole";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "RoleId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteRole", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(id, name, definition) {
    this.roleid = id;
    this.myform.controls['name'].setValue(name);
    this.myform.controls['definition'].setValue(definition);
    this.selectedPermission = [];
    let mytype1 = "GetRole_Child";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "RoleId": id,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetRole_Child", data);
        if (data.result == 1) {
          this.selectedPermission = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectpermission() {
    this.permission_id = this.mySelect.PermissionId;
  }
  per_change() {
    this.hide_permission = false;
    this.change_permission = true;
  }
}
