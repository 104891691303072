import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { User } from '../Model/user';

@Component({
  selector: 'app-listpermission',
  templateUrl: './listpermission.component.html',
  styleUrls: ['./listpermission.component.css']
})
export class ListpermissionComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  permissionlist = [];
  menulist = [];
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  permissionid: any;
  create: any;
  edit: any;
  del: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  load() {
    this.loadTable = false;
    let mytype = "ListPermission";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListPermission", data);
        if (data.result == 1) {
          this.permissionlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
      let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 10,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddPermission");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    this.menulist.forEach(item => {
      if (!item.isCreate) {
        item.isCreate = false;
      }
      if (!item.isRead) {
        item.isRead = false;
      }
      if (!item.isWrite) {
        item.isWrite = false;
      }
      if (!item.isDelete) {
        item.isDelete = false;
      }
    });
    let mytype = "EditPermission";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "PermissionId": this.permissionid,
      "PermissionName": this.myform.value.name,
      "user_id": this.userid,
    }
    mydata["Permissions"] = [];
    this.menulist.forEach(item => {
      var child = {};
      child["MenuId"] = item.MenuId;
      child["Create"] = item.isCreate;
      child["Read"] = item.isRead;
      child["Write"] = item.isWrite;
      child["Delete"] = item.isDelete;
      mydata["Permissions"].push(child);
    });
    console.log("mydata", mydata);
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditPermission", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeletePermission";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "PermissionId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeletePermission", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(id, name) {
    this.permissionid = id;
    this.myform.controls['name'].setValue(name);
    let mytype = "GetPermissionById";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "PermissionId": id,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("GetPermissionById", data);
        if (data.result == 1) {
          this.menulist = data.data;
          this.menulist.forEach(element => {
            element.isSelected3 = (element.Create == 1) ? true : false;
            element.isSelected = (element.Read == 1) ? true : false;
            element.isSelected1 = (element.Write == 1) ? true : false;
            element.isSelected2 = (element.Delete == 1) ? true : false;
            element.isCreate = element.isSelected3;
            element.isRead = element.isSelected;
            element.isWrite = element.isSelected1;
            element.isDelete = element.isSelected2;
          });
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  getProoduct(isSelected, product, action) {
    if (action == "create") {
      product.isCreate = isSelected;
    } else if (action == "read") {
      product.isRead = isSelected;
    } else if (action == "write") {
      product.isWrite = isSelected;
    } else {
      product.isDelete = isSelected;
    }
  }
}
