import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { User } from '../Model/user';

interface item {
  sno: any;
  subject: any;
  subjectid: any;
  duration: any;
}

@Component({
  selector: 'app-addproject',
  templateUrl: './addproject.component.html',
  styleUrls: ['./addproject.component.css']
})
export class AddprojectComponent implements OnInit {

  capricsAdminUser: User;
  userid:any;
  myform: FormGroup;
  submitted = false;
  subjectlist = [];
  orderItem: item;
  items: Array<item>;
  subject_id: any;
  subject_name: any;
  myduration: any;
  mySelect: any;
  programid: any;

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal) {
    this.items = [];
    this.myform = this.formBuilder.group({
      program_name: ['', Validators.required],
      total_duration: ['', Validators.required],
      subject: [''],
      duration: ['']
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if(this.capricsAdminUser != undefined){
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  load() {
    this.myduration = 0;
    this.subjectlist = [];
    let mytype = "List_ActiveSubject";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveSubject", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.items = [];
  }
  get f() {
    return this.myform.controls;
  }
  submit() {    
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.items.length == 0) {
      this.swalservice.warningalert("Add Subject");
      return;
    }
    let mytype = "AddProgram";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ProgramName": this.myform.value.program_name,
      "TotalDuration": this.myform.value.total_duration,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];

    this.items.forEach(item => {
      var child={};
      child["Sno"]=item.sno;
      child["SubjectId"]=item.subjectid;
      child["Duration"]= item.duration;
      mydata["child_data"].push(child); 
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddProgram", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.load();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }

  ngOnInit(): void {
    this.load();
  }

  selectsubject() {
    this.subject_id = this.mySelect.id;
    this.subject_name = this.mySelect.SubjectCode;
  }
  addItems() {
    if (this.subject_name != "" && this.myform.value.duration != "") {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        subject: this.subject_name,
        subjectid: this.subject_id,
        duration: this.myform.value.duration,
        sno: index,
      };
      this.items.push(this.orderItem);      
      this.myform.controls['subject'].setValue("");
      this.myform.controls['duration'].setValue("");
      this.subject_id = "";
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].sno === sno) {
        this.items.splice(i, 1);
      }
    }
  }
}
