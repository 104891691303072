import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators,FormsModule  } from '@angular/forms';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User } from '../Model/user';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = e => {
            resolve({ default: myReader.result });
          };

          myReader.readAsDataURL(file);
        })
    );
  }
}

@Component({
  selector: 'app-listactivity',
  templateUrl: './listactivity.component.html',
  styleUrls: ['./listactivity.component.css']
})
export class ListactivityComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  hide_subject: boolean;
  activitylist = [];
  selectedSkill = [];
  selectedIntelligent = [];
  skill_list = [];
  intelligencelist = [];
  subjectlist = [];
  public Editor = ClassicEditor;
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  activity_id: any;
  Learningdata = '';
  coursedata = '';
  notesdata = '';
  worksheetdata = '';
  materialdata = '';
  referencedata = '';
  create: any;
  edit: any;
  del: any;
  mySelect: any;
  subject_id: any;
  seditor: any;
  editor: any;
  public config = { link: { addTargetToExternalLinks: true } };

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      activity_name: ['', Validators.required],
      type: ['', Validators.required],
      subject: [''],
      day_order: ['', Validators.required],
      duration: ['', Validators.required],
      intelligence: [''],
      skill: [''],
      Learningdata:[''],
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function(
      loader
    ) {
      console.log('loader : ', loader);
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }
  // public onReady(editor) {
  //   editor.ui.getEditableElement().parentElement.insertBefore(
  //     editor.ui.view.toolbar.element,
  //     editor.ui.getEditableElement()
  //   );
  // }
  ngOnInit(): void {
    this.load();    
  }
  
  load() {
    this.hide_subject = true;
    this.selectedIntelligent = [];
    this.selectedSkill = [];
    this.intelligencelist = [];
    this.skill_list = [];
    this.Learningdata = '';
    this.coursedata = '';
    this.notesdata = '';
    this.worksheetdata = '';
    this.materialdata = '';
    this.referencedata = '';
    this.loadTable = false;
    let mytype = "ListActivity";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListActivity", data);
        if (data.result == 1) {
          this.activitylist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 12,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.intelligencelist = [];
    let mytype2 = "List_ActiveIntelligence";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("List_ActiveIntelligence", data);
        if (data.result == 1) {
          this.intelligencelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.skill_list = [];
    let mytype3 = "List_ActiveSkill";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("List_ActiveSkill", data);
        if (data.result == 1) {
          this.skill_list = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  add() {
    this.router.navigateByUrl("AddActivity");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.subject_id == 0) {
      this.swalservice.warningalert("select Subject");
      return;
    }
    let mytype = "EditActivity";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ActivityId": this.activity_id,
      "ActivityName": this.myform.value.activity_name,
      "Activity_type": this.myform.value.type,
      "SubjectId": this.subject_id,
      "DayOrder": this.myform.value.day_order,
      "Duration": this.myform.value.duration,
      "Learning": this.Learningdata,
      "Notes": this.notesdata,
      "Course": this.coursedata,
      "WorkSheet": this.worksheetdata,
      "Materials": this.materialdata,
      "Reference": this.referencedata,
      "user_id": this.userid,
    }
    mydata["intelligent_data"] = [];
    this.selectedIntelligent.forEach(item => {
      var child = {};
      child["IntelligentId"] = item.id;
      mydata["intelligent_data"].push(child);
    });
    mydata["skill_data"] = [];
    this.selectedSkill.forEach(item => {
      var child = {};
      child["SkillId"] = item.id;
      mydata["skill_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditActivity", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  editorContentCreated(quill){
    this.editor = quill;
    this.editor.setContents(this.editor.clipboard.convert(`<p>Hello world</p>`));

}
open1(data){
  localStorage.setItem('activity_id', JSON.stringify(data.id));
  this.router.navigate([]).then(result => {  window.open('EditActivity', '_blank'); });
}
  open(data) {
    this.activity_id = data.id;
    this.myform.controls['activity_name'].setValue(data.ActivityName);
    this.myform.controls['type'].setValue(data.Activity_type);
    this.myform.controls['subject'].setValue(data.SubjectCode);
    this.subject_id = data.SubjectId;
    this.myform.controls['day_order'].setValue(data.DayOrder);
    this.myform.controls['duration'].setValue(data.Duration);
    this.myform.controls['intelligence'].setValue(data.IntelligentName);
    this.myform.controls['skill'].setValue(data.SkillName);
   
    // const contents = this.quillEditor.clipboard.convert(this.content);
    // this.quillEditor.setContents(contents);
    this.Learningdata = data.Learning;
    //this.myform.controls['Learningdata'].setValue('karthick');
    this.coursedata = data.Course;
    this.notesdata = data.Notes;
    this.worksheetdata = data.WorkSheet;
    this.materialdata = data.Materials;
    this.referencedata = data.Reference;
    this.selectedSkill = [];
    this.selectedIntelligent = [];
    let mytype = "ListActivityByid";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ActivityId": this.activity_id,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListActivityByid", data);
        if (data.result == 1) {
          this.selectedSkill = data.data[0].skill_data;
          this.selectedIntelligent = data.data[0].intelligent_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.subjectlist = [];
    let mytype1 = "List_ActiveSubject";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("List_ActiveSubject", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }

  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteActivity";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "ActivityId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteActivity", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  selectsubject() {
    this.subject_id = this.mySelect.SubjectId;
  }
  subject_change() {
    this.subject_id = 0;
    this.hide_subject = false;
  }
}

