import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { User } from '../Model/user';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';

@Component({
  selector: 'app-listtheme',
  templateUrl: './listtheme.component.html',
  styleUrls: ['./listtheme.component.css']
})
export class ListthemeComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  themelist = [];
  submitted = false;
  loadTable: boolean;
  theme_id: any;
  academic_id: any;
  program_id: any;
  academiclist = [];
  programlist = [];
  mySelect: any;
  mySelect1: any;
  hide_academic: boolean;
  change_academic: boolean;
  hide_program: boolean;
  change_program: boolean;
  create: any;
  edit: any;
  del: any;
  search_academicid: any;
  search_programid: any;
  pgSelect: any;
  acSelect: any;
  color_code: any;
  imageUrl: any;
  editFile: boolean = true;
  removeUpload: boolean = false;
  @ViewChild('fileInput') el: ElementRef;

  public toggle: boolean = false;
  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
  public arrayColors: any = {
    color1: '#2883e9',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  public selectedColor: string = 'color1';
  public color1: any;
  public cmykValue: string = '';
  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);

  constructor(private formBuilder: FormBuilder, public vcRef: ViewContainerRef,
    private cpService: ColorPickerService, private router: Router, private apiservice: api,
    private swalservice: swal, private cd: ChangeDetectorRef) {
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  myform = this.formBuilder.group({
    theme_name: ['', Validators.required],
    academic: ['', Validators.required],
    program: ['', Validators.required],
    weeks: ['', Validators.required],
    days: ['', Validators.required],
    color_code: [],
    file: [null]
  })
  public onEventLog(event: string, data: any): void {
    console.log(event, data);
  }
  public onChangeColor(color: string): void {
    console.log('Color changed:', color);
  }
  public onChangeColorCmyk(color: string): Cmyk {
    this.color_code = color;
    const hsva = this.cpService.stringToHsva(color);
    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);
      return this.cpService.rgbaToCmyk(rgba);
    }
    return new Cmyk(0, 0, 0, 0);
  }
  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);
    if (hsva) {
      return this.cpService.outputFormat(hsva, 'rgba', null);
    }
    return '';
  }
  ngOnInit(): void {
    this.load();
  }
  load() {
    this.hide_academic = true;
    this.change_academic = false;
    this.hide_program = true;
    this.change_program = false;
    this.loadTable = false;
    let mytype = "ListTheme";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListTheme", data);
        if (data.result == 1) {
          this.themelist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });

    let mytype1 = "ListAcademic";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("ListAcademic", data);
        if (data.result == 1) {
          this.academiclist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype2 = "ListProgram";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("ListProgram", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "Getpermission_user";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 7,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  add() {
    this.router.navigateByUrl("AddTheme");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "EditTheme";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeId": this.theme_id,
      "ThemeName": this.myform.value.theme_name,
      "AcademicId": this.academic_id,
      "ProgramId": this.program_id,
      "Week": this.myform.value.weeks,
      "Day": this.myform.value.days,
      "ColorCode": this.color_code,
      "ThemeIcon": this.myform.value.file,
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditTheme", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  open(themeid, themename, icon, academicid, academic_code, programid, programname, week, day, color) {
    this.theme_id = themeid;
    this.academic_id = academicid;
    this.program_id = programid;
    this.myform.controls['theme_name'].setValue(themename);
    this.myform.controls['academic'].setValue(academic_code);
    this.myform.controls['program'].setValue(programname);
    this.myform.controls['weeks'].setValue(week);
    this.myform.controls['days'].setValue(day);
    this.myform.controls['color_code'].setValue(color);
    this.color_code = color;
    this.color1 = color;
    this.imageUrl = icon;
  }
  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageUrl = reader.result;
        this.myform.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      this.cd.markForCheck();
    }
  }
  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl = '';
    this.editFile = true;
    this.removeUpload = false;
    this.myform.patchValue({
      file: [null]
    });
  }
  ac_change() {
    this.hide_academic = false;
    this.change_academic = true;
  }
  pg_change() {
    this.hide_program = false;
    this.change_program = true;
  }
  selectacademic() {
    this.academic_id = this.mySelect.AcademicId;
  }
  selectprogram() {
    this.program_id = this.mySelect1.ProgramId;
  }
  live(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "update_publish";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "ThemeId": id,
          "publish": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("update_publish", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteTheme";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "ThemeId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteTheme", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  search() {
    this.loadTable = false;
    this.themelist = [];
    if (this.search_programid == undefined) {
      this.search_programid = "";
    }
    if (this.search_programid == undefined) {
      this.search_programid = "";
    }

    let mytype4 = "Theme_Search";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "AcademicId": this.search_academicid,
      "ProgramId": this.search_programid,
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("Theme_Search", data);
        if (data.result == 1) {
          this.themelist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchprogram() {
    this.search_programid = this.pgSelect.ProgramId;
  }
  searchacademic() {
    this.search_academicid = this.acSelect.AcademicId;
  }
}

