<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-2">
                        <a (click)="add()" [class.disabled]="create" class="btn btn-sm btn-primary" style="color: white;">Add New Program</a>
                    </div>
                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Program List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Program List</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                            <i class="fas fa-minus"></i></button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <table class="table table-striped projects" *ngIf="loadTable" datatable>
                        <thead>
                            <tr>
                                <th>
                                    SNo
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Program Name
                                </th>
                                <th>
                                    Total Duration
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of projectlist;let i = index;">
                                <td>{{ i + 1 }}</td>
                                <td>{{ data.date | date: "dd-MM-yyyy" }}</td>
                                <td>
                                    <a>
                                        {{ data.ProgramName }}
                                    </a>
                                </td>
                                <td>
                                    <a>
                                        <a>
                                            {{ data.TotalDuration }}
                                        </a>
                                    </a>
                                </td>
                                <td class="project-actions text-right">
                                    <a *ngIf="data.Status == 'Active'" [class.disabled]="edit" class="btn btn-info btn-sm" data-toggle="modal" data-target="#exampleModalCenter" style="color: white;" (click)="open(data.id,data.ProgramName,data.TotalDuration)">
                                        <i class="fas fa-pencil-alt">
                                        </i> Edit
                                    </a>&nbsp;
                                    <a *ngIf="data.Status == 'Active'" [class.disabled]="del" class="btn btn-danger btn-sm" (click)="delete(data.id,'Inactive','You will not be able to recover this Program Details!','Yes, delete it!')" style="color: white;">
                                        <i class="fas fa-trash">
                                        </i> Delete
                                    </a>
                                    <a *ngIf="data.Status == 'Inactive'" [class.disabled]="del" class="btn btn-success btn-sm" style="color: white;" (click)="delete(data.id,'Active','You will Activate this Program Details!','Yes, Activate it!')">
                                        <i class="far fa-check-circle"></i> Activate
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
<div class="modal fade bd-example-modal-xl" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">Update Program</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="myform">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputName">Program Name</label>
                                <input type="text" id="inputName" class="form-control" formControlName="program_name" [ngClass]="{ 'is-invalid': submitted && f.program_name.errors }">
                                <div *ngIf="submitted && f.program_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.program_name.errors.required">Program Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputName">Total Duration</label>
                                <div class="ngx-timepicker-field-example">
                                    <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'" formControlName="total_duration" [ngClass]="{ 'is-invalid': submitted && f.total_duration.errors }">
                                    </ngx-timepicker-field>
                                </div>
                                <div *ngIf="submitted && f.total_duration.errors" class="invalid-feedback">
                                    <div *ngIf="f.total_duration.errors.required">Total Duration is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Subject </h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                                            <i class="fas fa-minus"></i></button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Subject</label>
                                                <select class="form-control custom-select" [(ngModel)]="mySelect" (change)="selectsubject()" formControlName="subject">
                                                    <option *ngFor='let option of subjectlist' [ngValue]="option">
                                                        {{option.SubjectCode}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Duration</label>
                                                <div class="ngx-timepicker-field-example">
                                                    <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'" formControlName="duration">
                                                    </ngx-timepicker-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 offset-md-1 mt-4">
                                            <input type="button" value="Add" class="btn btn-info" (click)="addItems()">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <!-- <th>#</th> -->
                                                        <th>Subject</th>
                                                        <th>Duration</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of items;let i= index">
                                                        <!-- <td>{{item.order_by}}</td> -->
                                                        <td>{{item.SubjectCode}}</td>
                                                        <td>
                                                            <div class="ngx-timepicker-field-example">
                                                                <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.Duration">
                                                                </ngx-timepicker-field>
                                                            </div>
                                                        </td>
                                                        <td class="project-actions text-right">
                                                            <a class="btn btn-danger btn-sm" (click)="remove(item.order_by)" style="color: white;">
                                                                <i class="fas fa-trash">
                                                                </i> Remove
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Update" class="btn btn-success" data-dismiss="modal" (click)="submit()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>