import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User } from '../Model/user';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = e => {
            resolve({ default: myReader.result });
          };
          myReader.readAsDataURL(file);
        })
    );
  }
}

@Component({
  selector: 'app-addactivity',
  templateUrl: './addactivity.component.html',
  styleUrls: ['./addactivity.component.css']
})
export class AddactivityComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  myform: FormGroup;
  public Editor = ClassicEditor;
  Learningdata = '';
  coursedata = '';
  notesdata = '';
  worksheetdata = '';
  materialdata = '';
  referencedata = '';
  submitted = false;
  selectedIntelligent = [];
  selectedSkill = [];
  intelligencelist = [];
  skill_list = [];
  subjectlist = [];
  mySelect: any;
  subject_id: any;
  myduration: any;

  public config = { link: { addTargetToExternalLinks: true } };

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      activity_name: ['', Validators.required],
      type: ['', Validators.required],
      day_order: ['', Validators.required],
      duration: ['', Validators.required],
      intelligence: [''],
      skill: [''],
      subject: [''],
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (
      loader
    ) {
      console.log('loader : ', loader);
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }
  // public onReady(editor) {
  //   editor.ui.getEditableElement().parentElement.insertBefore(
  //     editor.ui.view.toolbar.element,
  //     editor.ui.getEditableElement()
  //   );
  // }
  ngOnInit(): void {
    this.myduration = 0;
    this.subject_id = "";
    this.Learningdata = '';
    this.coursedata = '';
    this.notesdata = '';
    this.worksheetdata = '';
    this.materialdata = '';
    this.referencedata = '';
    this.selectedIntelligent = [];
    this.selectedSkill = [];
    this.intelligencelist = [];
    this.skill_list = [];
    let mytype2 = "List_ActiveIntelligence";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("List_ActiveIntelligence", data);
        if (data.result == 1) {
          this.intelligencelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "List_ActiveSkill";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("List_ActiveSkill", data);
        if (data.result == 1) {
          this.skill_list = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.subjectlist = [];
    let mytype = "List_ActiveSubject";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveSubject", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectsubject() {
    this.subject_id = this.mySelect.id;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      this.swalservice.warningalert("Fill Complete details");
      return;
    }
    if (this.subject_id == "") {
      this.swalservice.warningalert("select Subject");
      return;
    }
    if (this.myform.value.duration == "" || this.myform.value.duration == undefined) {
      this.swalservice.warningalert("select Duration");
      return;
    }
    let mytype = "AddActivity";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ActivityName": this.myform.value.activity_name,
      "Activity_type": this.myform.value.type,
      "SubjectId": this.subject_id,
      "DayOrder": this.myform.value.day_order,
      "Duration": this.myform.value.duration,
      "Learning": this.Learningdata,
      "Notes": this.notesdata,
      "Course": this.coursedata,
      "WorkSheet": this.worksheetdata,
      "OutcomeResource": this.worksheetdata,
      "Materials": this.materialdata,
      "Reference": this.referencedata,
      "user_id": this.userid,
    }
    mydata["intelligent_data"] = [];
    this.selectedIntelligent.forEach(item => {
      var child = {};
      child["IntelligentId"] = item.id;
      mydata["intelligent_data"].push(child);
    });
    mydata["skill_data"] = [];
    this.selectedSkill.forEach(item => {
      var child = {};
      child["SkillId"] = item.id;
      mydata["skill_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddActivity", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.ngOnInit();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  get f() {
    return this.myform.controls;
  }
}
