import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import { User } from '../Model/user';

interface item {
  sno: any;
  ProgramName: any;
  program_id: any;
  child: any;
  sectionid: any;
}

@Component({
  selector: 'app-listbranch',
  templateUrl: './listbranch.component.html',
  styleUrls: ['./listbranch.component.css']
})
export class ListbranchComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  branchlist = [];
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  branch_id: any;
  create: any;
  edit: any;
  del: any;

  institutelist = [];
  section_list = [];
  sectionlist = [];
  instituteid: any;
  instituteSelect: any;
  programlist = [];
  orderItem: item;
  items: Array<item>;
  program_id: any;
  program_name: any;
  section_id: any;
  section_name: any;
  mySelect: any;
  mySelect1: any;
  selectedSection = [];
  hide_institute:boolean;
  old_institute: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, public datepipe: DatePipe, private swalservice: swal) {
    this.items = [];
    this.myform = this.formBuilder.group({
      branch: ['', Validators.required],
      branch_code: ['', Validators.required],
      institution_name: ['', Validators.required],
      start_date: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      alter_email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      address: [''],
      program: [''],
      section: ['']
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  load() {
    this.hide_institute = true;
    this.loadTable = false;
    let mytype = "List_Branch";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_Branch", data);
        if (data.result == 1) {
          this.branchlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 3,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.myform.controls['start_date'].setValue(this.datepipe.transform(new Date(), 'dd-MM-yyyy'));
    this.myform.controls['institution_name'].setValue("");
    this.items = [];
    let mytype2 = "List_ActiveInstitute";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("List_ActiveInstitute", data);
        if (data.result == 1) {
          this.institutelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "Get_ActiveClass";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("Get_ActiveClass", data);
        if (data.result == 1) {
          this.sectionlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddBranch");
  }
  get f() {
    return this.myform.controls;
  }
  get Email() {
    return this.myform.get('email');
  }
  get AlterEmail() {
    return this.myform.get('alter_email');
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "Edit_Branch";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "BranchId": this.branch_id,
      "Branch": this.myform.value.branch,
      "Branchcode": this.myform.value.branch_code,
      "InstitutionId": this.instituteid,
      "StartDate": this.myform.value.start_date,
      "Email": this.myform.value.email,
      "AlternateEmail": this.myform.value.alter_email,
      "Phone": this.myform.value.phone,
      "Address": this.myform.value.address,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];
    this.items.forEach(item => {
      var child = {};
      child["program_id"] = item.program_id;
      let sectiondata = {};
      item.child.forEach(item => {
        if (sectiondata["section_id"]) {
          sectiondata["section_id"] = sectiondata["section_id"] + "," + item.id;
        } else {
          sectiondata["section_id"] = item.id;
        }
      });
      if (item.child.length == 0) {
        sectiondata["section_id"] = "0";
      }
      child["section_id"] = sectiondata["section_id"];
      mydata["child_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("Edit_Branch", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm,instituteid) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteBranch";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "branch_id": id,
          "InstitutionId": instituteid,
          "status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteBranch", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(data) {
    this.branch_id = data.branch_id;
    this.myform.controls['branch'].setValue(data.branch);
    this.myform.controls['branch_code'].setValue(data.Branchcode);
    this.myform.controls['start_date'].setValue(data.StartDate);
    this.myform.controls['institution_name'].setValue(data.InstituteName);
    this.instituteid = data.InstitutionId;
    this.myform.controls['email'].setValue(data.email);
    this.myform.controls['alter_email'].setValue(data.AlternateEmail);
    this.myform.controls['phone'].setValue(data.phone_number);
    this.myform.controls['address'].setValue(data.Address);
    let mytype2 = "GetBranch_Child";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "BranchId": this.branch_id,
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("GetBranch_Child", data);
        if (data.result == 1) {
          this.items = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
      let mytype = "GetInstituteByid";
      let mydata = {
        "secret_key": "2020140715947422055f0dd5bd3ba8f",
        "InstituteId": this.instituteid,
      }
      this.apiservice.apipost(mydata, mytype)
        .subscribe((data: any) => {
          console.log("GetInstituteByid", data);
          if (data.result == 1) {
            this.programlist = data.data;
          } else {
            this.swalservice.warningalert(data.msg);
          }
        });
  }
  selectprogram() {
    this.program_id = this.mySelect.program_id;
    this.program_name = this.mySelect.ProgramName;
  }
  selectsection() {
    this.section_id = this.mySelect1.id;
    this.section_name = this.mySelect1.ClassCode;
  }
  selectInstitute() {
    if (this.items.length != 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'If you change the institution can removed the program details!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes remove it',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.items = [];
        } else {
          this.instituteSelect =this.old_institute +": Object";
        }
      });
    } else {
      this.old_institute = this.instituteSelect.InstituteId;
    }
    this.instituteid = this.instituteSelect.InstituteId;
    this.programlist = [];
    let mytype = "GetInstituteByid";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "InstituteId": this.instituteid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("GetInstituteByid", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  addItems() {
    let sectiondata = {};
    if (this.program_id != "") {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        ProgramName: this.program_name,
        program_id: this.program_id,
        child: this.selectedSection,
        sectionid: sectiondata,
        sno: index,
      };
      this.items.push(this.orderItem);
      this.myform.controls['program'].setValue("");
      this.myform.controls['section'].setValue("");
      this.selectedSection = [];
      this.program_id = "";
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].sno === sno) {
        this.items.splice(i, 1);
      }
    }
  }
  institute_change(){
    this.hide_institute = false;
    this.programlist = [];
  }
}
