<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Create Month</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Create Month</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <form [formGroup]="myform">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Academic</label>
                                                <select class="form-control custom-select" [(ngModel)]="acSelect"
                                                    (change)="selectacademic()" formControlName="academic">
                                                    <option *ngFor='let option of academiclist' [ngValue]="option">
                                                        {{option.AcademicCode}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Program</label>
                                                    <select class="form-control custom-select" [(ngModel)]="pgSelect"
                                                    (change)="selectprogram()" formControlName="program">
                                                    <option *ngFor='let option of programlist' [ngValue]="option">
                                                        {{option.ProgramName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Theme</label>
                                                <select class="form-control custom-select" [(ngModel)]="mySelect"
                                                    (change)="selecttheme()" formControlName="theme">
                                                    <option *ngFor='let option of themelist' [ngValue]="option">
                                                        {{option.ThemeName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="">Order By</label>
                                                <input type="number" id="" class="form-control" formControlName="orderby"
                                                    [ngClass]="{ 'is-invalid': submitted && f.orderby.errors }">
                                                <div *ngIf="submitted && f.orderby.errors" class="invalid-feedback">
                                                    <div *ngIf="f.orderby.errors.required">Order By is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Details </h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                            data-toggle="tooltip" title="Collapse">
                                            <i class="fas fa-minus"></i></button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="inputName">Title</label>
                                                <input type="text" id="" class="form-control" formControlName="title">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="inputName">Description</label>
                                                <textarea id="inputDescription" class="form-control" rows="4"
                                                    formControlName="description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="inputName">Order By</label>
                                                <input type="number" id="" class="form-control"
                                                    formControlName="order_by">
                                            </div>
                                        </div>
                                        <div class="col-md-1 offset-md-0 mt-4">
                                            <input type="submit" value="Add" class="btn btn-info" (click)="addItems()">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style="display: none;">#</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Order By</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of items;let i= index">
                                                        <td style="display: none;">{{item.sno}}</td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="item.title"
                                                                [ngModelOptions]="{standalone: true}">
                                                        </td>
                                                        <td>
                                                            <textarea id="inputDescription" class="form-control"
                                                                rows="4" [(ngModel)]="item.description"
                                                                [ngModelOptions]="{standalone: true}"></textarea>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="item.order_by"
                                                                [ngModelOptions]="{standalone: true}">
                                                        </td>
                                                        <td class="project-actions text-right">
                                                            <a class="btn btn-danger btn-sm" (click)="remove(item.sno)"
                                                                style="color: white;">
                                                                <i class="fas fa-trash">
                                                                </i>
                                                                Remove
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <input type="submit" value="Save" class="btn btn-success" (click)="submit()">
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
</div>