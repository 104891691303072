import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { User } from '../Model/user';

interface item {
  order_by: any;
  SubjectCode: any;
  SubjectId: any;
  Duration: any;
}

@Component({
  selector: 'app-listproject',
  templateUrl: './listproject.component.html',
  styleUrls: ['./listproject.component.css']
})
export class ListprojectComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  projectlist = [];
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  subjectlist = [];
  orderItem: item;
  items: Array<item>;
  myduration: any;
  mySelect: any;
  program_id: any;
  subject_id: any;
  subject_name: any;
  create: any;
  edit: any;
  del: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.items = [];
    this.myform = this.formBuilder.group({
      program_name: ['', Validators.required],
      total_duration: ['', Validators.required],
      subject: [''],
      duration: ['']
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  ngOnInit(): void {
    this.load();
  }
  load() {
    this.loadTable = false;
    let mytype = "ListProgram";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListProgram", data);
        if (data.result == 1) {
          this.projectlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });

    this.myduration = 0;
    let mytype1 = "List_ActiveSubject";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("ListSubject", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype2 = "Getpermission_user";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 4,
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.items = [];
  }
  add() {
    this.router.navigateByUrl("AddProject");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.items.length == 0) {
      this.swalservice.warningalert("Add Subject");
      return;
    }
    let mytype = "EditProgram";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ProgramId": this.program_id,
      "ProgramName": this.myform.value.program_name,
      "TotalDuration": this.myform.value.total_duration,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];

    this.items.forEach(item => {
      var child = {};
      child["Sno"] = item.order_by;
      child["SubjectId"] = item.SubjectId;
      child["Duration"] = item.Duration;
      mydata["child_data"].push(child);
    });

    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditProgram", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.load();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteProgram";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "ProgramId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteProgram", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  selectsubject() {
    this.subject_id = this.mySelect.id;
    this.subject_name = this.mySelect.SubjectCode;
  }
  open(programid, programname, totalduration) {
    this.items = [];
    this.program_id = programid;
    this.myform.controls['program_name'].setValue(programname);
    this.myform.controls['total_duration'].setValue(totalduration);

    let mytype = "ListProgramSubjectByProgramId";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ProgramId": this.program_id,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListProgramSubjectByProgramId", data);
        if (data.result == 1) {
          data.data.forEach(element => {
            this.items.push(element);
          });
          if (this.items && this.items.length > 0) {
            this.items.forEach(element => {
              element.SubjectCode = element.SubjectCode;
              element.SubjectId = element.SubjectId;
              element.Duration = element.Duration;
            });
          }
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  addItems() {
    if (this.subject_name != "" && this.myform.value.duration != "") {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        SubjectCode: this.subject_name,
        SubjectId: this.subject_id,
        Duration: this.myform.value.duration,
        order_by: index,
      };
      this.items.push(this.orderItem);
      this.myform.controls['subject'].setValue("");
      this.myform.controls['duration'].setValue("");
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(Sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].order_by === Sno) {
        this.items.splice(i, 1);
      }
    }
  }
}
