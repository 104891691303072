import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { User } from '../Model/user';

@Component({
  selector: 'app-addroll',
  templateUrl: './addroll.component.html',
  styleUrls: ['./addroll.component.css']
})
export class AddrollComponent implements OnInit {

  capricsAdminUser: User;
  userid:any;
  myform: FormGroup;
  submitted = false;
  permissionlist = [];
  selectedPermission = [];

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      name: ['', Validators.required],
      definition: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if(this.capricsAdminUser != undefined){
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  get f() {
    return this.myform.controls;
  }

  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }

    let mytype = "AddRole";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "RoleName": this.myform.value.name,
      "Definition": this.myform.value.definition,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];
    this.selectedPermission.forEach(item => {
      var child = {};
      child["PermissionId"] = item.PermissionId;
      mydata["child_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddRole", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.ngOnInit();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }

  ngOnInit(): void {
    this.selectedPermission = [];
    this.permissionlist = [];
    let mytype = "List_ActivePermission";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActivePermission", data);
        if (data.result == 1) {
          this.permissionlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }

}
