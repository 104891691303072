<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Create Institute</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Create Institute</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Add</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                        data-toggle="tooltip" title="Collapse">
                                        <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="myform">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Institution Name</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.institution_name.errors }"
                                                    class="form-control" formControlName="institution_name">
                                                <div *ngIf="submitted && f.institution_name.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.institution_name.errors.required">Institution is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Institution Code</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.institution_code.errors }"
                                                    class="form-control" formControlName="institution_code">
                                                <div *ngIf="submitted && f.institution_code.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.institution_code.errors.required">Institution Code is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Start Date</label>
                                                <div class="input-group date" id="reservationdate"
                                                    data-target-input="nearest">
                                                    <input type="date" formControlName="start_date"
                                                        [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                                        class="form-control datetimepicker-input" />
                                                    <!-- <div class="input-group-append" data-target="#reservationdate"
                                                        data-toggle="datetimepicker">
                                                        <div class="input-group-text"><i class="fa fa-calendar"></i>
                                                        </div>
                                                    </div> -->
                                                    <div *ngIf="submitted && f.start_date.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.start_date.errors.required">Start Date is
                                                            required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Founder Name</label>
                                                <input type="text" id="inputName" class="form-control"
                                                    formControlName="founder_name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email ID</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                    class="form-control" formControlName="email">
                                                <div *ngIf="Email.errors?.email" class="alert alert-danger">
                                                    Email not valid.
                                                </div>
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Alternate Email ID</label>
                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.alter_email.errors }"
                                                    formControlName="alter_email">
                                                <div *ngIf="AlterEmail.errors?.alter_email" class="alert alert-danger">
                                                    Alternate Email not valid.
                                                </div>
                                                <div *ngIf="submitted && f.alter_email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.alter_email.errors.required">Alternate Email is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Contact No</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                                    class="form-control" formControlName="phone">
                                                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                    <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                                </div>
                                                <div *ngIf="f.phone.touched && f.phone.invalid"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.phone.errors.required">Phone Number is required.</div>
                                                    <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Phone
                                                        Number.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Office No</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.office_phone.errors }"
                                                    class="form-control" formControlName="office_phone">
                                                <div *ngIf="f.office_phone.touched && f.office_phone.invalid"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.office_phone.errors.pattern">Please, Enter 10 digit
                                                        Phone
                                                        Number.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Any Branch</label>
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                        (change)="branch()" id="customSwitch1" [checked]="any_branch">
                                                    <label class="custom-control-label" for="customSwitch1">{{ anybranch
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>No of Branch</label>
                                                <input type="number" id="inputName" [readonly]="noOfbranch"
                                                    [ngClass]="{ 'is-invalid': submitted && f.noOf_branch.errors }"
                                                    class="form-control" formControlName="noOf_branch">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Institution Type</label>
                                                <select class="form-control custom-select"
                                                    formControlName="institute_type"
                                                    [ngClass]="{ 'is-invalid': submitted && f.institute_type.errors }">
                                                    <option selected disabled>Select one</option>
                                                    <option value="Pre-School">Pre-School</option>
                                                    <option value="Primary">Primary</option>
                                                    <option value="Secondary">Secondary</option>
                                                </select>
                                                <div *ngIf="submitted && f.institute_type.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.institute_type.errors.required">Institution Type is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Board/Affiliation</label>
                                                <select class="form-control custom-select" formControlName="board">
                                                    <option selected disabled>Select one</option>
                                                    <option value="IB">IB</option>
                                                    <option value="IGCSE">IGCSE</option>
                                                    <option value="ICSE">ICSE</option>
                                                    <option value="State Board">State Board</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>PAN No</label>
                                                <input type="text" id="inputName" class="form-control"
                                                    formControlName="pan">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>GST No</label>
                                                <input type="text" id="inputName" class="form-control"
                                                    formControlName="gst">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <textarea class="form-control" rows="3" formControlName="address"
                                                    [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                                    placeholder="Enter Address"></textarea>
                                                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                                    <div *ngIf="f.address.errors.required">Address is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Program</label>
                                                <ng-select [items]="programlist" bindLabel="ProgramName"
                                                    placeholder="Select Program" appendTo="body" multiple="true"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedProgram">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Establishment Year</label>
                                                <input type="text" id="inputName" class="form-control"
                                                    formControlName="establish_year">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Logo</label>
                                                <div class="group-gap">
                                                    <div class="avatar-upload">
                                                        <div class="avatar-edit">
                                                            <input type='file' id="imageUpload"
                                                                accept=".png, .jpg, .jpeg" #fileInput
                                                                (change)="uploadFile($event)" />
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div class="preview" *ngIf="imageUrl && imageUrl !== null">
                                                                <img [src]="imageUrl" width="100" height="100">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Add Institute" class="btn btn-success" (click)="submit()">
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>