import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

// ClassicEditor
// .create( document.querySelector( '#editor' ), {
//     plugins: [ SimpleUploadAdapter, ],
//     toolbar: [ ],
//     simpleUpload: {
//         // Feature configuration.
//     }
// } )
// .then( )
// .catch(  );
ClassicEditor
    .create( document.querySelector( '#editor' ), {
        removePlugins: [ 'Heading', 'Link' ],
        toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ],
        mediaEmbed: {
          previewsInData: true
      }
    } )
    .catch( error => {
        console.log( error );
    } );

export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = e => {
            resolve({ default: myReader.result });
          };

          myReader.readAsDataURL(file);
        })
    );
  }
}

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.css']
})
export class CkeditorComponent implements OnInit {

 

  public Editor = ClassicEditor;

  constructor() { }
  

  ngOnInit(): void {
  }
  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function(
      loader
    ) {
      console.log('loader : ', loader);
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }
}
