import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { User } from '../Model/user';

@Component({
  selector: 'app-listclass',
  templateUrl: './listclass.component.html',
  styleUrls: ['./listclass.component.css']
})
export class ListclassComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  classlist = [];
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  classid: any;
  create: any;
  edit: any;
  del: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      code: ['', Validators.required],
      definition: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  load() {
    this.loadTable = false;
    let mytype = "ListClass";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListClass", data);
        if (data.result == 1) {
          this.classlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 15,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddSection");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "EditClass";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ClassId": this.classid,
      "ClassCode": this.myform.value.code,
      "Definition": this.myform.value.definition,
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditClass", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteClass";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "ClassId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteClass", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(class_id, code, definition, status) {
    this.classid = class_id;
    this.myform.controls['code'].setValue(code);
    this.myform.controls['definition'].setValue(definition);
  }
}
