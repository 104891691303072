<div class="wrapper">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Create Intelligent</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Create Intelligent</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Intelligent</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Collapse">
                    <i class="fas fa-minus"></i></button>
                </div>
              </div>
              <div class="card-body">
                <form [formGroup]="myform">
                  <div class="form-group">
                    <label for="inputName">Intelligent Name</label>
                    <input type="text" id="inputName" class="form-control" formControlName="intelligent_name"
                      [ngClass]="{ 'is-invalid': submitted && f.intelligent_name.errors }">
                    <div *ngIf="submitted && f.intelligent_name.errors" class="invalid-feedback">
                      <div *ngIf="f.intelligent_name.errors.required">Intelligent Name is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="inputName">Intelligent Icon (20px * 20px)</label>
                    <div class="group-gap">
                      <div class="avatar-upload">
                        <div class="avatar-edit">
                          <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                            (change)="uploadFile($event)" />
                        </div>
                        <div class="avatar-preview">
                          <div class="preview" *ngIf="imageUrl && imageUrl !== null">
                            <img [src]="imageUrl" width="20" height="20">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <input type="submit" value="Create new Intelligent" class="btn btn-success" (click)="submit()">
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
  <aside class="control-sidebar control-sidebar-dark">
  </aside>
</div>