import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { User } from '../Model/user';

@Component({
  selector: 'app-addpermission',
  templateUrl: './addpermission.component.html',
  styleUrls: ['./addpermission.component.css']
})
export class AddpermissionComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  menulist = [];
  myform: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private apiservice: api, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  ngOnInit(): void {
    let mytype = "List_Menu";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_Menu", data);
        if (data.result == 1) {
          this.menulist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  getProoduct(isSelected, product, action) {

    if (action == "create") {
      product.isCreate = isSelected;
    } else if (action == "read") {
      product.isRead = isSelected;
    } else if (action == "write") {
      product.isWrite = isSelected;
    } else {
      product.isDelete = isSelected;
    }
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.menulist.forEach(item => {
      if (!item.isCreate) {
        item.isCreate = false;
      }
      if (!item.isRead) {
        item.isRead = false;
      }
      if (!item.isWrite) {
        item.isWrite = false;
      }
      if (!item.isDelete) {
        item.isDelete = false;
      }
    });
    let mytype = "AddPermission";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "PermissionName": this.myform.value.name,
      "user_id": this.userid,
    }
    mydata["Permissions"] = [];

    this.menulist.forEach(item => {
      var child = {};
      child["MenuId"] = item.id;
      child["Create"] = item.isCreate;
      child["Read"] = item.isRead;
      child["Write"] = item.isWrite;
      child["Delete"] = item.isDelete;
      mydata["Permissions"].push(child);
    });
    console.log("menu", mydata);
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddPermission", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.ngOnInit();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
}
