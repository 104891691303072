import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { User } from '../Model/user';

@Component({
  selector: 'app-listacademic',
  templateUrl: './listacademic.component.html',
  styleUrls: ['./listacademic.component.css']
})
export class ListacademicComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  academiclist = [];
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  academicid: any;
  create: any;
  edit: any;
  del: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      code: ['', Validators.required],
      from_year: ['', Validators.required],
      to_year: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  ngOnInit(): void {
    this.load();
  }
  load() {
    this.loadTable = false;
    let mytype = "ListAcademic";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListAcademic", data);
        if (data.result == 1) {
          this.academiclist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 6,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  add() {
    this.router.navigateByUrl("AddAcademic");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "EditAcademic";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "AcademicId": this.academicid,
      "AcademicCode": this.myform.value.code,
      "FromYear": this.myform.value.from_year,
      "ToYear": this.myform.value.to_year,
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditAcademic", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteAcademic";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "AcademicId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteAcademic", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(academic_id, code, fromyear, toyear) {
    this.academicid = academic_id;
    this.myform.controls['code'].setValue(code);
    this.myform.controls['from_year'].setValue(fromyear);
    this.myform.controls['to_year'].setValue(toyear);
  }
}

