import { Component, OnInit } from '@angular/core';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  intelligent:any;
  skill:any;
  subject:any;
  program:any;
  academic:any;
  theme:any;
  curriculum:any;
  month:any;
  role:any;
  permission:any;
  institute:any;
  branch:any;
  user:any;
  activity:any;

  constructor(private router: Router, private apiservice: api, private swalservice: swal) { }

  ngOnInit(): void {
    let mytype = "Dashboard";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("Dashboard", data);
        if (data.result == 1) {
          this.intelligent = data.Intelligent;
          this.skill = data.Skill;
          this.subject = data.Subject;
          this.program = data.Program;
          this.academic = data.Academic;
          this.theme = data.Theme;
          this.curriculum = data.Curriculum;
          this.month = data.Month;
          this.role = data.Role;
          this.permission = data.Permission;
          this.institute = data.Institution;
          this.branch = data.Branch;
          this.user = data.User;
          this.activity = data.Activity;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }

}
