<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-3">
                        <a (click)="add()" [class.disabled]="create" class="btn btn-sm btn-primary"
                            style="color: white;">Add New Month</a>
                    </div>
                    <div class="col-sm-3">
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Month List</li>
                        </ol>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-sm">Academic Year</span>
                            </div>
                            <select class="form-control custom-select" [(ngModel)]="acSelect"
                                (change)="searchacademic()" [ngModelOptions]="{standalone: true}">
                                <option *ngFor='let option of academiclist' [ngValue]="option">
                                    {{ option.AcademicCode }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-sm">Program</span>
                            </div>
                            <select class="form-control custom-select" [(ngModel)]="pgSelect" (change)="searchprogram()"
                                [ngModelOptions]="{standalone: true}">
                                <option *ngFor='let option of activeprogramlist' [ngValue]="option">
                                    {{ option.ProgramName }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <input type="submit" value="Search" class="btn btn-success" (click)="search()">
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Month List </h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                        data-toggle="tooltip" title="Collapse">
                                        <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <table class="table table-striped projects" *ngIf="loadTable" datatable>
                                    <thead>
                                        <tr>
                                            <th>
                                                Sno
                                            </th>
                                            <th>
                                                Date
                                            </th>
                                            <th>
                                                Theme
                                            </th>
                                            <th>
                                                Academic Code
                                            </th>
                                            <th>
                                                Program
                                            </th>
                                            <th>
                                                Order By
                                            </th>
                                            <th>
                                                Preview
                                            </th>
                                            <th>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of monthlist;let i = index;">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ data.date | date: "dd-MM-yyyy" }}</td>
                                            <td>
                                                {{ data.theme_name }}
                                            </td>
                                            <td>
                                                {{ data.AcademicCode }}
                                            </td>
                                            <td>
                                                {{ data.ProgramName }}
                                            </td>
                                            <td>
                                                {{ data.OrderBy }}
                                            </td>
                                            <td>
                                                <a *ngIf="data.Status == 'Active'" class="btn btn-secondary btn-sm"
                                                    style="color: white;" (click)="month_view(data.MonthId)">
                                                    <i class="far fa-eye"></i>
                                                    Month
                                                </a>
                                            </td>
                                            <td class="project-actions text-right">
                                                <a *ngIf="data.Status == 'Active'" [class.disabled]="edit"
                                                    class="btn btn-info btn-sm" style="color: white;"
                                                    data-toggle="modal" data-target="#exampleModalCenter"
                                                    (click)="open(data)">
                                                    <i class="fas fa-pencil-alt">
                                                    </i>
                                                    Edit
                                                </a>&nbsp;
                                                <a *ngIf="data.Status == 'Active'" [class.disabled]="del"
                                                    class="btn btn-danger btn-sm" style="color: white;"
                                                    (click)="delete(data.MonthId,'Inactive','You will not be able to recover this Month Details!','Yes, delete it!')">
                                                    <i class="fas fa-trash">
                                                    </i>
                                                    Delete
                                                </a>
                                                <a *ngIf="data.Status == 'Inactive'" [class.disabled]="del"
                                                    class="btn btn-success btn-sm" style="color: white;"
                                                    (click)="delete(data.MonthId,'Active','You will Activate this Month Details!','Yes, Activate it!')">
                                                    <i class="far fa-check-circle"></i>
                                                    Activate
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
    <aside class="control-sidebar control-sidebar-dark">
    </aside>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">Update Month</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="myform">
                    <div class="row">
                        <!-- <div class="col-md-3">
                            <div class="form-group">
                                <label for="inputName">Theme</label>&nbsp;<button *ngIf="hide_theme"
                                    class="badge btn-danger" (click)="theme_change()">
                                    Change</button>
                                <input type="text" id="" class="form-control" formControlName="theme"
                                    *ngIf="hide_theme">
                                <select *ngIf="!hide_theme" class="form-control custom-select"
                                    formControlName="theme" [(ngModel)]="mySelect" (change)="selecttheme()">
                                    <option *ngFor='let option of themelist' [ngValue]="option">
                                        {{ option.ThemeName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Academic Year</label>
                                <input type="text" id="inputName" class="form-control" readonly
                                    formControlName="academic">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Program</label>
                                <input type="text" id="inputName" class="form-control" readonly
                                    formControlName="program">
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="inputName">Academic</label>&nbsp;<button *ngIf="hide_academic"
                                class="badge btn-danger" (click)="academic_change()">
                                Change</button>
                            <input type="text" id="" class="form-control" formControlName="academic"
                                *ngIf="hide_academic">
                                <select *ngIf="!hide_academic" class="form-control custom-select" [(ngModel)]="acSelect"
                                    (change)="selectacademic()" formControlName="academic">
                                    <option *ngFor='let option of academiclist' [ngValue]="option">
                                        {{option.AcademicCode}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="inputName">Program</label>&nbsp;<button *ngIf="hide_program"
                                class="badge btn-danger" (click)="program_change()">
                                Change</button>
                            <input type="text" id="" class="form-control" formControlName="program"
                                *ngIf="hide_program">
                                    <select *ngIf="!hide_program" class="form-control custom-select" [(ngModel)]="pgSelect"
                                    (change)="selectprogram()" formControlName="program">
                                    <option *ngFor='let option of programlist' [ngValue]="option">
                                        {{option.ProgramName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="inputName">Theme</label>&nbsp;<button *ngIf="hide_theme"
                                    class="badge btn-danger" (click)="theme_change()">
                                    Change</button>
                                <input type="text" id="" class="form-control" formControlName="theme"
                                    *ngIf="hide_theme">
                                <select *ngIf="!hide_theme" class="form-control custom-select"
                                    formControlName="theme" [(ngModel)]="mySelect" (change)="selecttheme()">
                                    <option *ngFor='let option of themelist' [ngValue]="option">
                                        {{ option.ThemeName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Order By</label>
                                <input type="number" id="" class="form-control" formControlName="orderby"
                                    [ngClass]="{ 'is-invalid': submitted && f.orderby.errors }">
                                <div *ngIf="submitted && f.orderby.errors" class="invalid-feedback">
                                    <div *ngIf="f.orderby.errors.required">Order By is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">Add Details </h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                            data-toggle="tooltip" title="Collapse">
                                            <i class="fas fa-minus"></i></button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="inputName">Title</label>
                                                <input type="text" id="" class="form-control" formControlName="title">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="inputName">Description</label>
                                                <textarea id="inputDescription" class="form-control" rows="4"
                                                    formControlName="description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="inputName">Order By</label>
                                                <input type="number" id="" class="form-control"
                                                    formControlName="order_by">
                                            </div>
                                        </div>
                                        <div class="col-md-1 offset-md-0 mt-4">
                                            <input type="submit" value="Add" class="btn btn-info" (click)="addItems()">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style="display: none;">#</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Order By</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of items;let i= index">
                                                        <td style="display: none;">{{item.Sno}}</td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="item.Title"
                                                                [ngModelOptions]="{standalone: true}">
                                                        </td>
                                                        <td>
                                                            <textarea id="inputDescription" class="form-control"
                                                                rows="4" [(ngModel)]="item.Description"
                                                                [ngModelOptions]="{standalone: true}"></textarea>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="item.OrderBy"
                                                                [ngModelOptions]="{standalone: true}">
                                                        </td>
                                                        <td class="project-actions text-right">
                                                            <a class="btn btn-danger btn-sm" (click)="remove(item.Sno)"
                                                                style="color: white;">
                                                                <i class="fas fa-trash">
                                                                </i>
                                                                Remove
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Update" class="btn btn-success" data-dismiss="modal"
                            (click)="submit()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>