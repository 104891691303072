import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { User } from '../Model/user';

@Component({
  selector: 'app-listinstitute',
  templateUrl: './listinstitute.component.html',
  styleUrls: ['./listinstitute.component.css']
})
export class ListinstituteComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any;
  institutelist = [];
  submitted = false;
  loadTable: boolean;
  institute_id: any;
  anybranch: any;
  noOfbranch: boolean;
  branch_checked: boolean;
  create: any;
  edit: any;
  del: any;
  programlist = [];
  selectedProgram = [];

  constructor(private formBuilder: FormBuilder, private cd: ChangeDetectorRef, public datepipe: DatePipe, private router: Router, private apiservice: api, private swalservice: swal) {
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  myform = this.formBuilder.group({
    institution_name: ['', Validators.required],
    institution_code: ['', Validators.required],
    start_date: ['', Validators.required],
    founder_name: [''],
    email: ['', [Validators.required, Validators.email]],
    alter_email: ['', [Validators.email]],
    phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    office_phone: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    noOf_branch: [''],
    institute_type: ['', Validators.required],
    board: [''],
    pan: [''],
    gst: [''],
    address: [''],
    establish_year: [''],
    file: [null]
  });
  ngOnInit(): void {
    this.branch_checked = false;
    this.selectedProgram = [];
    this.loadTable = false;
    let mytype = "ListInstitute";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListInstitute", data);
        if (data.result == 1) {
          this.institutelist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "Getpermission_user";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 14,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "List_ActiveProgram";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("List_ActiveProgram", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  add() {
    this.router.navigateByUrl("AddInstitute");
  }
  get f() {
    return this.myform.controls;
  }
  get Email() {
    return this.myform.get('email');
  }
  get AlterEmail() {
    return this.myform.get('alter_email');
  }
  submit() {
    debugger;
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "EditInstitute";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "InstituteId": this.institute_id,
      "InstituteName": this.myform.value.institution_name,
      "InstituteCode": this.myform.value.institution_code,
      "StartDate": this.myform.value.start_date,
      "FounderName": this.myform.value.founder_name,
      "Email": this.myform.value.email,
      "AlternateEmail": this.myform.value.alter_email,
      "Phone": this.myform.value.phone,
      "OfficeNo": this.myform.value.office_phone,
      "AnyBranch": this.anybranch,
      "No_of_Branch": this.myform.value.noOf_branch,
      "InstituteType": this.myform.value.institute_type,
      "Board": this.myform.value.board,
      "PanNo": this.myform.value.pan,
      "GstNo": this.myform.value.gst,
      "Address": this.myform.value.address,
      "Logo": this.myform.value.file,
      "EstablishmentYear": this.myform.value.establish_year,
      "user_id": this.userid,
    }
    mydata["program_data"] = [];
    this.selectedProgram.forEach(item => {
      var child = {};
      child["program_id"] = item.ProgramId;
      if (item.ProgramId == undefined) {
        child["program_id"] = item.program_id;
      }
      mydata["program_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditInstitute", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.ngOnInit();
          this.submitted = false;
          this.myform.reset();
          this.ngOnInit();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.myform.patchValue({
          file: reader.result
        });
      }
      this.cd.markForCheck();
    }
  }
  branch() {
    if (this.anybranch == "No") {
      this.anybranch = "Yes";
      this.noOfbranch = false;
    } else {
      this.anybranch = "No";
      this.noOfbranch = true;
      this.myform.controls['noOf_branch'].setValue('0');
    }
  }
  open(id, name, code, startdate, founder, emailid, alter_emailid, phone, office_no, branch, no_ofbranch, type, board, pan, gst, address, year, logo) {
    this.institute_id = id;
    this.myform.controls['institution_name'].setValue(name);
    this.myform.controls['institution_code'].setValue(code);
    this.myform.controls['start_date'].setValue(startdate);
    this.myform.controls['founder_name'].setValue(founder);
    this.myform.controls['email'].setValue(emailid);
    this.myform.controls['alter_email'].setValue(alter_emailid);
    this.myform.controls['phone'].setValue(phone);
    this.myform.controls['office_phone'].setValue(office_no);
    this.anybranch = branch;
    if (branch == "No") {
      this.noOfbranch = true;
      this.branch_checked = false;
    } else {
      this.noOfbranch = false;
      this.branch_checked = true;
    }
    this.myform.controls['noOf_branch'].setValue(no_ofbranch);
    this.myform.controls['institute_type'].setValue(type);
    this.myform.controls['board'].setValue(board);
    this.myform.controls['pan'].setValue(pan);
    this.myform.controls['gst'].setValue(gst);
    this.myform.controls['address'].setValue(address);
    this.myform.controls['establish_year'].setValue(year);
    this.imageUrl = logo;
    this.selectedProgram = [];
    let mytype = "GetInstituteByid";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "InstituteId": this.institute_id,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("GetInstituteByid", data);
        if (data.result == 1) {
          this.selectedProgram = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteInstitute";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "InstituteId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteInstitute", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.ngOnInit();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
}

