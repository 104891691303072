import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import { User } from '../Model/user';
import { timeStamp } from 'console';

@Component({
  selector: 'app-listcurriculum',
  templateUrl: './listcurriculum.component.html',
  styleUrls: ['./listcurriculum.component.css']
})
export class ListcurriculumComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  hide_theme: boolean;
  hide_activity: boolean;
  hide_subject: boolean;
  hide_intelligent: boolean;
  hide_skill: boolean;
  hide_week: boolean;
  hide_day: boolean;
  curriculumlist = [];
  academiclist = [];
  programlist = [];
  myform: FormGroup;
  public Editor = ClassicEditor;
  Learningdata = '';
  coursedata = '';
  notesdata = '';
  worksheetdata = '';
  materialdata = '';
  referencedata = '';
  submitted = false;
  loadTable: boolean;
  curriculum_id: any;
  themelist = [];
  subjectlist = [];
  intelligencelist = [];
  skill_list = [];
  mySelect: any;
  intelSelect: any;
  skillselect: any;
  subjectSelect: any;
  theme_id: any;
  subjectid: any;
  intelligence_id: any;
  skill_id: any;
  imageUrl: any;
  imageUrl1: any;
  search_themename: any;
  search_academiccode: any;
  search_programname: any;
  search_subjectcode: any;
  search_day: any;
  subSelect: any;
  pgSelect: any;
  acSelect: any;
  thSelect: any;
  selectedIntelligent = [];
  selectedSkill = [];
  search_themeid: any;
  weeks = [];
  days = [];
  selected_week: any;
  selected_day: any;
  weekSelect: any;
  daySelect: any;
  create: any;
  edit: any;
  del: any;
  academic_id: any;
  program_id: any;
  activity_Select: any;
  activity_id: any;
  activitylist = [];
  old_week: any;
  old_day: any;
  public config = { link: { addTargetToExternalLinks: true } };

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.myform = this.formBuilder.group({
      activity: [''],
      theme: ['', Validators.required],
      academic: [''],
      program: [''],
      subject: ['', Validators.required],
      topic: ['', Validators.required],
      day_order: ['', Validators.required],
      duration: [''],
      week: [''],
      day: [''],
      intelligence: [''],
      skill: [''],
      days: [''],
      weeks: [''],
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  get f() {
    return this.myform.controls;
  }
  load() {
    this.loadTable = false;
    this.imageUrl = '';
    this.imageUrl1 = '';
    this.Learningdata = '';
    this.coursedata = '';
    this.notesdata = '';
    this.worksheetdata = '';
    this.materialdata = '';
    this.referencedata = '';
    this.intelligencelist = [];
    this.skill_list = [];
    this.curriculumlist = [];
    this.activitylist = [];
    let mytype4 = "ListCurriculum";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("ListCurriculum", data);
        if (data.result == 1) {
          this.curriculumlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype = "List_ActiveTheme";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveTheme", data);
        if (data.result == 1) {
          this.themelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });

    let mytype1 = "List_ActiveSubject";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("List_ActiveSubject", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype2 = "List_ActiveIntelligence";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("List_ActiveIntelligence", data);
        if (data.result == 1) {
          this.intelligencelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "List_ActiveSkill";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("List_ActiveSkill", data);
        if (data.result == 1) {
          this.skill_list = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });

    let mytype5 = "List_ActiveAcademic";
    let mydata5 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata5, mytype5)
      .subscribe((data: any) => {
        console.log("ListAcademic", data);
        if (data.result == 1) {
          this.academiclist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype6 = "List_ActiveProgram";
    let mydata6 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata6, mytype6)
      .subscribe((data: any) => {
        console.log("List_ActiveProgram", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype7 = "Getpermission_user";
    let mydata7 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 8,
    }
    this.apiservice.apipost(mydata7, mytype7)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype8 = "List_ActiveActivity";
    let mydata8 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata8, mytype8)
      .subscribe((data: any) => {
        console.log("List_ActiveActivity", data);
        if (data.result == 1) {
          this.activitylist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddCurriculam");
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.activity_id == "" || this.activity_id == undefined) {
      this.activity_id = 0;
    }
    this.old_week = this.myform.value.weeks;
    this.old_day = this.myform.value.days;
    let mytype = "EditCurriculum";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "CurriculumId": this.curriculum_id,
      "ThemeId": this.theme_id,
      "AcademicId": this.academic_id,
      "ProgramId": this.program_id,
      "ActivityId": this.activity_id,
      "SubjectId": this.subjectid,
      "Topic": this.myform.value.topic,
      "DayOrder": this.myform.value.day_order,
      "Duration": this.myform.value.duration,
      "Week": this.selected_week,
      "Day": this.selected_day,
      "Learning": this.Learningdata,
      "Notes": this.notesdata,
      "Course": this.coursedata,
      "Worksheet": this.worksheetdata,
      "Materials": this.materialdata,
      "Reference": this.referencedata,
      "user_id": this.userid,
    }
    mydata["intelligent_data"] = [];
    this.selectedIntelligent.forEach(item => {
      var child = {};
      child["IntelligentId"] = item.id;
      mydata["intelligent_data"].push(child);
    });
    mydata["skill_data"] = [];
    this.selectedSkill.forEach(item => {
      var child = {};
      child["SkillId"] = item.id;
      mydata["skill_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditCurriculum", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset({
            weeks: this.old_week,
            days: this.old_day
          });
          this.load();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteCurriculum";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "CurriculumId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteCurriculum", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open1(item){
    localStorage.setItem('curriculum_id', JSON.stringify(item.CurriculumId));
    this.router.navigate([]).then(result => {  window.open('EditCurriculum', '_blank'); });
  }
  open(item) {
    this.hide_theme = true;
    this.hide_activity = true;
    this.hide_subject = true;
    this.hide_intelligent = true;
    this.hide_skill = true;
    this.hide_week = true;
    this.hide_day = true;

    this.curriculum_id = item.CurriculumId;
    this.myform.controls['theme'].setValue(item.ThemeName);
    this.theme_id = item.ThemeId;
    this.myform.controls['academic'].setValue(item.AcademicCode);
    this.academic_id = item.AcademicId;
    this.myform.controls['program'].setValue(item.ProgramName);
    this.program_id = item.ProgramId;
    this.myform.controls['subject'].setValue(item.SubjectCode);
    this.subjectid = item.SubjectId;
    this.myform.controls['topic'].setValue(item.Topic);
    this.myform.controls['day_order'].setValue(item.DayOrder);
    this.myform.controls['duration'].setValue(item.Duration);
    this.myform.controls['week'].setValue(item.Week);
    this.selected_week = item.Week;
    this.myform.controls['day'].setValue(item.Day);
    this.myform.controls['activity'].setValue(item.ActivityName);
    this.activity_id = item.ActivityId;
    this.selected_day = item.Day;
    this.Learningdata = item.Learning;
    this.coursedata = item.Course;
    this.notesdata = item.Notes;
    this.worksheetdata = item.Worksheet;
    this.materialdata = item.Materials;
    this.referencedata = item.Reference;
    this.subjectlist = [];
    this.weeks = [];
    this.days = [];
    let mytype3 = "GetSubjectByTheme";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeId": this.theme_id,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetSubjectByTheme", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
          this.weeks = data.weeks_data;
          this.days = data.days_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.selectedIntelligent = [];
    this.selectedSkill = [];
    let mytype = "GetCurriculum_child";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "CurriculumId": this.curriculum_id,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("GetCurriculum_child", data);
        if (data.result == 1) {
          this.selectedIntelligent = data.data.intelligent_data;
          this.selectedSkill = data.data.skill_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selecttheme() {
    this.theme_id = this.mySelect.ThemeId;
    this.myform.controls['academic'].setValue(this.mySelect.AcademicCode);
    this.academic_id = this.mySelect.AcademicId;
    this.program_id = this.mySelect.ProgramId;
    this.myform.controls['program'].setValue(this.mySelect.ProgramName);
    this.subjectlist = [];
    this.weeks = [];
    this.days = [];
    let mytype3 = "GetSubjectByTheme";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeId": this.theme_id,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetSubjectByTheme", data);
        if (data.result == 1) {
          this.subjectlist = data.data;
          this.weeks = data.weeks_data;
          this.days = data.days_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectweek() {
    this.selected_week = this.weekSelect.week;
    // this.days = [];
    // let mytype3 = "GetDaysByWeek";
    // let mydata3 = {
    //   "secret_key": "2020140715947422055f0dd5bd3ba8f",
    //   "ThemeId": this.theme_id,
    //   "Week": this.selected_week,
    // }
    // this.apiservice.apipost(mydata3, mytype3)
    //   .subscribe((data: any) => {
    //     console.log("GetDaysByWeek", data);
    //     if (data.result == 1) {
    //       this.days = data.days_data;
    //     } else {
    //       this.swalservice.warningalert(data.msg);
    //     }
    //   });
  }
  selectday() {
    this.selected_day = this.daySelect.day;
  }
  selectintel() {
    this.intelligence_id = this.intelSelect.Id;
    this.imageUrl = "http://capricsadmin.angelenterprises.in/uploads/intelligents/" + this.intelSelect.IntelligentIcon;
    //this.selectedIntelligent = []; 
  }
  selectskill() {
    this.skill_id = this.skillselect.id;
    this.imageUrl1 = "http://capricsadmin.angelenterprises.in/uploads/skills/" + this.skillselect.SkillIcon;
  }
  selectsubject() {
    this.subjectid = this.subjectSelect.SubjectId;
    this.myform.controls['duration'].setValue(this.subjectSelect.Duration);
  }
  searchtheme() {
    this.search_themename = this.thSelect.ThemeName;
    this.search_themeid = this.thSelect.ThemeId;
    // this.subjectlist = [];
    // let mytype3 = "GetSubjectByTheme";
    // let mydata3 = {
    //   "secret_key": "2020140715947422055f0dd5bd3ba8f",
    //   "ThemeId": this.search_themeid,
    // }
    // this.apiservice.apipost(mydata3, mytype3)
    //   .subscribe((data: any) => {
    //     console.log("GetSubjectByTheme", data);
    //     if (data.result == 1) {
    //       this.subjectlist = data.data;
    //     } else {
    //       this.swalservice.warningalert(data.msg);
    //     }
    //   });
  }
  searchacademic() {
    this.search_academiccode = this.acSelect.AcademicCode;
    // this.subjectlist = [];
    // this.programlist = [];
    // let mytype3 = "GetProgramByAcademic";
    // let mydata3 = {
    //   "secret_key": "2020140715947422055f0dd5bd3ba8f",
    //   "AcademicCode": this.search_academiccode,
    // }
    // this.apiservice.apipost(mydata3, mytype3)
    //   .subscribe((data: any) => {
    //     console.log("GetProgramByAcademic", data);
    //     if (data.result == 1) {
    //       this.programlist = data.data;
    //     } else {
    //       this.swalservice.warningalert(data.msg);
    //     }
    //   });
  }
  searchprogram() {
    this.search_programname = this.pgSelect.ProgramName;
    this.themelist = [];
    let mytype3 = "GetThemeByProgram";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ProgramName": this.search_programname,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetThemeByProgram", data);
        if (data.result == 1) {
          this.themelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchsubject() {
    this.search_subjectcode = this.subSelect.SubjectCode;
  }
  searchday() {
    this.search_day = this.mySelect.ThemeName;
  }
  search() {
    this.loadTable = false;
    this.curriculumlist = [];
    if (this.search_academiccode == undefined) {
      this.search_academiccode = "";
    }
    if (this.search_programname == undefined) {
      this.search_programname = "";
    }
    if (this.search_themename == undefined) {
      this.search_themename = "";
    }
    if (this.search_subjectcode == undefined) {
      this.search_subjectcode = "";
    }
    if (this.myform.value.days == undefined) {
      this.myform.value.days = "";
    }
    if (this.myform.value.weeks == undefined) {
      this.myform.value.weeks = "";
    }
    let mytype4 = "Curriculum_Search";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "AcademicCode": this.search_academiccode,
      "ProgramName": this.search_programname,
      "ThemeName": this.search_themename,
      "Week": this.myform.value.weeks,
      "Day": this.myform.value.days,
      "SubjectCode": this.search_subjectcode,
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("Curriculum_Search", data);
        if (data.result == 1) {
          this.curriculumlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  theme_change() {
    this.hide_theme = false;
  }
  activity_change() {
    this.hide_activity = false;
  }
  subject_change() {
    this.hide_subject = false;
  }
  intelligent_change() {
    this.hide_intelligent = false;
  }
  skill_change() {
    this.hide_skill = false;
  }
  week_change() {
    this.hide_week = false;
  }
  day_change() {
    this.hide_day = false;
  }
  day_view(day, week, themeid) {
    let mytype4 = "Add_dayview";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "days": day,
      "weeks": week,
      "theme_id": themeid,
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("Add_dayview", data);
        if (data.result == 1) {
          // const url = this.router.serializeUrl(
          //   this.router.createUrlTree(["http://uatpreview-nurturelab.caprics.in/"])
          // );
          // window.open(url, '_blank');
          window.open("http://uatpreview-nurturelab.caprics.in/", "_blank");
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  week_view(week, themeid) {
    let mytype4 = "Add_weekview";
    let mydata4 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "weeks": week,
      "theme_id": themeid,
    }
    this.apiservice.apipost(mydata4, mytype4)
      .subscribe((data: any) => {
        console.log("Add_weekview", data);
        if (data.result == 1) {
          window.open("http://uatpreview-nurturelab.caprics.in/week", "_blank");
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectactivity() {
    this.activity_id = this.activity_Select.id;
    let mytype3 = "ListActivityByid ";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ActivityId": this.activity_id,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("ListActivityByid ", data);
        if (data.result == 1) {
          this.hide_subject = true;
          this.selectedIntelligent = [];
          this.selectedSkill = [];
          this.myform.controls['day_order'].setValue(data.data[0].DayOrder);
          this.myform.controls['duration'].setValue(data.data[0].Duration);
          this.myform.controls['subject'].setValue(data.data[0].SubjectCode);
          this.subjectid = data.data[0].SubjectId;
          this.Learningdata = data.data[0].Learning;
          this.coursedata = data.data[0].Course;
          this.notesdata = data.data[0].Notes;
          this.worksheetdata = data.data[0].WorkSheet;
          this.materialdata = data.data[0].Materials;
          this.referencedata = data.data[0].Reference;
          this.selectedIntelligent = data.data[0].intelligent_data;
          this.selectedSkill = data.data[0].skill_data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
}
