import Swal from 'sweetalert2';

export class swal {

    private apilink: any = "http://localhost/BaseDB";
  
    constructor(){ }
  
    successalert(data:any){
        Swal.fire({
            icon: 'success',
            title: data
          });
    }
    warningalert(data:any){
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data
          });
    }
  }