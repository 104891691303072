import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { User } from '../Model/user';

@Component({
  selector: 'app-listintelligent',
  templateUrl: './listintelligent.component.html',
  styleUrls: ['./listintelligent.component.css']
})
export class ListintelligentComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  @ViewChild('fileInput') el: ElementRef;
  intelligent_list = [];
  submitted = false;
  loadTable: boolean;
  intelligent_id: any;
  imageUrl: any;
  editFile: boolean = true;
  removeUpload: boolean = false;
  create: any;
  edit: any;
  del: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api,
    private cd: ChangeDetectorRef, private swalservice: swal) {
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  myform = this.formBuilder.group({
    intelligent_name: ['', Validators.required],
    file: [null]
  })
  load() {
    this.loadTable = false;
    let mytype = "ListIntelligent";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("ListIntelligent", data);
        if (data.result == 1) {
          this.intelligent_list = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 1,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddIntelligent");
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    let mytype = "EditIntelligent";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "IntelligentId": this.intelligent_id,
      "IntelligentName": this.myform.value.intelligent_name,
      "IntelligentIcon": this.myform.value.file,
      "user_id": this.userid,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("EditIntelligent", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.load();
          this.submitted = false;
          this.myform.reset();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteIntelligent";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "IntelligentId": id,
          "Status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteIntelligent", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(id, name, icon) {
    this.intelligent_id = id;
    this.myform.controls['intelligent_name'].setValue(name);
    this.imageUrl = icon;
  }
  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageUrl = reader.result;
        this.myform.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      this.cd.markForCheck();
    }
  }
  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl = '';
    this.editFile = true;
    this.removeUpload = false;
    this.myform.patchValue({
      file: [null]
    });
  }
}
