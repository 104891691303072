<form [formGroup]="myform">
    <div class="wrapper">
        <app-header></app-header>
        <app-sidebar></app-sidebar>
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-3">
                            <a (click)="add()" [class.disabled]="create" class="btn btn-sm btn-primary" style="color: white;">Add New
                                Curriculum</a>
                        </div>
                        <div class="col-sm-3">

                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Curriculum List</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Academic Year</span>
                                </div>
                                <select class="form-control custom-select" [(ngModel)]="acSelect" (change)="searchacademic()" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor='let option of academiclist' [ngValue]="option">
                                        {{ option.AcademicCode }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Program</span>
                                </div>
                                <select class="form-control custom-select" [(ngModel)]="pgSelect" (change)="searchprogram()" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor='let option of programlist' [ngValue]="option">
                                        {{ option.ProgramName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Theme</span>
                                </div>
                                <select class="form-control custom-select" [(ngModel)]="thSelect" [ngModelOptions]="{standalone: true}" (change)="searchtheme()">
                                    <option *ngFor='let option of themelist' [ngValue]="option">
                                        {{option.ThemeName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Week</span>
                                </div>
                                <select class="form-control custom-select" formControlName="weeks">
                                    <option selected disabled>Select one</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>                                  
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Day</span>
                                </div>
                                <select class="form-control custom-select" formControlName="days">
                                    <option selected disabled>Select one</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Subject</span>
                                </div>
                                <select class="form-control custom-select" [(ngModel)]="subSelect" [ngModelOptions]="{standalone: true}" (change)="searchsubject()">
                                    <option *ngFor='let option of subjectlist' [ngValue]="option">
                                        {{option.SubjectCode}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <input type="submit" value="Search" class="btn btn-success" (click)="search()">
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Curriculum List</h3>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                                <i class="fas fa-minus"></i></button>
                        </div>
                    </div>
                    <div class="card-body p-0 mt-2">
                        <table class="table table-striped projects" *ngIf="loadTable" datatable>
                            <thead>
                                <tr>
                                    <th>
                                        Id
                                    </th>
                                    <!-- <th>
                                        Date
                                    </th> -->
                                    <th>
                                        Theme
                                    </th>
                                    <th>
                                        Program
                                    </th>
                                    <th>
                                        Subject
                                    </th>
                                    <th>
                                        Day
                                    </th>
                                    <th>
                                        Week
                                    </th>
                                    <th>
                                        Order
                                    </th>
                                    <th>
                                        Preview
                                    </th>
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of curriculumlist">
                                    <td>{{ item.CurriculumId }}</td>
                                    <!--  <td>{{ item.date | date: "dd-MM-yyyy" }}</td> -->
                                    <td>{{ item.ThemeName }}</td>
                                    <td>{{ item.ProgramName }}</td>
                                    <td>{{ item.SubjectCode }}</td>
                                    <td>{{ item.Day }}</td>
                                    <td>{{ item.Week }}</td>
                                    <td>{{ item.DayOrder }}</td>
                                    <td>
                                        <a *ngIf="item.Status == 'Active'" class="btn btn-secondary btn-sm" style="color: white;" (click)="day_view(item.Day,item.Week,item.ThemeId)">
                                            <i class="far fa-eye"></i> Day
                                        </a>&nbsp;
                                        <a *ngIf="item.Status == 'Active'" class="btn btn-secondary btn-sm" style="color: white;" (click)="week_view(item.Week,item.ThemeId)">
                                            <i class="far fa-eye"></i> Week
                                        </a>&nbsp;
                                    </td>
                                    <td class="project-actions text-right">
                                        <a *ngIf="item.Status == 'Active'" [class.disabled]="edit" class="btn btn-info btn-sm" style="color: white;" (click)="open1(item)">
                                            <i class="fas fa-pencil-alt">
                                            </i> Edit
                                        </a>&nbsp;
                                        <a *ngIf="item.Status == 'Active'" [class.disabled]="del" class="btn btn-danger btn-sm" style="color: white;" (click)="delete(item.CurriculumId,'Inactive','You will not be able to recover this Curriculum Details!','Yes, delete it!')">
                                            <i class="fas fa-trash">
                                            </i> Delete
                                        </a>
                                        <a *ngIf="item.Status == 'Inactive'" [class.disabled]="del" class="btn btn-success btn-sm" style="color: white;" (click)="delete(item.CurriculumId,'Active','You will Activate this Curriculum Details!','Yes, Activate it!')">
                                            <i class="far fa-check-circle"></i> Activate
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
        <app-footer></app-footer>
    </div>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title" id="exampleModalLongTitle">Update Curriculum</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="">Theme </label>&nbsp;<button class="badge btn-danger" (click)="theme_change()">
                                    Change</button>
                                <input type="text" id="inputName" class="form-control" readonly formControlName="theme" *ngIf="hide_theme">
                                <select *ngIf="!hide_theme" class="form-control custom-select" [(ngModel)]="mySelect" (change)="selecttheme()" formControlName="theme" [ngClass]="{ 'is-invalid': submitted && f.theme.errors }">
                                    <option *ngFor='let option of themelist' [ngValue]="option">
                                        {{option.ThemeName}}</option>
                                </select>
                                <div *ngIf="submitted && f.theme.errors" class="invalid-feedback">
                                    <div *ngIf="f.theme.errors.required">Theme is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Academic</label>
                                <input type="text" readonly id="inputName" class="form-control" formControlName="academic">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="">Activity </label>&nbsp;<button class="badge btn-danger" (click)="activity_change()" *ngIf="hide_activity">
                                    Change</button>
                                <input type="text" id="inputName" *ngIf="hide_activity" class="form-control" readonly formControlName="activity">
                                <select *ngIf="!hide_activity" class="form-control custom-select" [(ngModel)]="activity_Select" (change)="selectactivity()" formControlName="activity">
                                    <option *ngFor='let option of activitylist' [ngValue]="option">
                                        {{option.ActivityName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Program</label>
                                <input readonly type="text" id="inputName" class="form-control" formControlName="program">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="">Subject </label>&nbsp;<button class="badge btn-danger" (click)="subject_change()">
                                    Change</button>
                                <input type="text" id="inputName" class="form-control" readonly formControlName="subject" *ngIf="hide_subject">
                                <select *ngIf="!hide_subject" class="form-control custom-select" formControlName="subject" [(ngModel)]="subjectSelect" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" (change)="selectsubject()">
                                    <option *ngFor='let option of subjectlist' value="option.SubjectId">
                                        {{option.SubjectCode}}</option>
                                </select>
                                <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                    <div *ngIf="f.subject.errors.required">Subject is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Topic</label>
                                <input type="text" id="inputName" class="form-control" formControlName="topic" [ngClass]="{ 'is-invalid': submitted && f.topic.errors }">
                                <div *ngIf="submitted && f.topic.errors" class="invalid-feedback">
                                    <div *ngIf="f.topic.errors.required">Topic is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Day Order</label>
                                <select class="form-control custom-select" formControlName="day_order" [ngClass]="{ 'is-invalid': submitted && f.day_order.errors }">
                                    <option selected disabled>Select one</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                                <div *ngIf="submitted && f.day_order.errors" class="invalid-feedback">
                                    <div *ngIf="f.day_order.errors.required">Day Order is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Duration</label>
                                <input type="text" id="inputName" readonly class="form-control" placeholder="hours/mins" formControlName="duration" [ngClass]="{ 'is-invalid': submitted && f.duration.errors }">
                                <div *ngIf="submitted && f.duration.errors" class="invalid-feedback">
                                    <div *ngIf="f.duration.errors.required">Duration is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Week</label>&nbsp;<button class="badge btn-danger" (click)="week_change()">
                                    Change</button>
                                <input type="text" id="inputName" class="form-control" readonly formControlName="week" *ngIf="hide_week">
                                <select *ngIf="!hide_week" class="form-control custom-select" [(ngModel)]="weekSelect" (change)="selectweek()" formControlName="week" [ngClass]="{ 'is-invalid': submitted && f.week.errors }">
                                    <option *ngFor='let option of weeks' [ngValue]="option">
                                        {{option.week}}</option>
                                </select>
                                <div *ngIf="submitted && f.week.errors" class="invalid-feedback">
                                    <div *ngIf="f.week.errors.required">Week is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Day</label>&nbsp;<button class="badge btn-danger" (click)="day_change()">
                                    Change</button>
                                <input type="text" id="inputName" class="form-control" readonly formControlName="day" *ngIf="hide_day">
                                <select *ngIf="!hide_day" class="form-control custom-select" formControlName="day" [(ngModel)]="daySelect" (change)="selectday()" [ngClass]="{ 'is-invalid': submitted && f.week.errors }">
                                    <option *ngFor='let option of days' [ngValue]="option">
                                        {{option.day}}</option>
                                </select>
                                <div *ngIf="submitted && f.day.errors" class="invalid-feedback">
                                    <div *ngIf="f.day.errors.required">Day is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Intelligent</label>
                                <ng-select [items]="intelligencelist" bindLabel="IntelligentName" placeholder="Select Intelligent" appendTo="body" multiple="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedIntelligent">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Skill</label>
                            <ng-select [items]="skill_list" bindLabel="SkillName" placeholder="Select Skill" appendTo="body" multiple="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSkill">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="inputDescription">Learning Outcome</label>
                                <!-- <quill-editor [(ngModel)]="Learningdata" [ngModelOptions]="{standalone: true}">
                                </quill-editor> -->
                                <ckeditor [(ngModel)]="Learningdata" [ngModelOptions]="{standalone: true}" [editor]="Editor" [config]="config"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="inputDescription">Teacher Notes</label>
                                <!-- <quill-editor [(ngModel)]="notesdata" [ngModelOptions]="{standalone: true}">
                                </quill-editor> -->
                                <ckeditor [(ngModel)]="notesdata" [ngModelOptions]="{standalone: true}" [editor]="Editor" [config]="config"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="inputDescription">Course of Action</label>
                                <!-- <quill-editor [(ngModel)]="coursedata" [ngModelOptions]="{standalone: true}">
                                </quill-editor> -->
                                <ckeditor [(ngModel)]="coursedata" [ngModelOptions]="{standalone: true}" [editor]="Editor" [config]="config"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="inputDescription">Resources</label>
                                <!-- <quill-editor [(ngModel)]="worksheetdata" [ngModelOptions]="{standalone: true}">
                                </quill-editor> -->
                                <ckeditor [(ngModel)]="worksheetdata" [ngModelOptions]="{standalone: true}" [editor]="Editor" [config]="config"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="inputDescription">Materials Required</label>
                                <!-- <quill-editor [(ngModel)]="materialdata" [ngModelOptions]="{standalone: true}">
                                </quill-editor> -->
                                <ckeditor [(ngModel)]="materialdata" [ngModelOptions]="{standalone: true}" [editor]="Editor" [config]="config"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="inputDescription">Reference</label>
                                <!-- <quill-editor [(ngModel)]="referencedata" [ngModelOptions]="{standalone: true}">
                                </quill-editor> -->
                                <ckeditor [(ngModel)]="referencedata" [ngModelOptions]="{standalone: true}" [editor]="Editor" [config]="config"></ckeditor>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <input type="submit" value="Update" class="btn btn-success" data-dismiss="modal" (click)="submit()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>