import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { api } from '../Api/api.service';
import { swal } from 'src/app/Common-Service/swal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { User } from '../Model/user';

interface item {
  sno: any;
  RoleName: any;
  RoleId: any;
  child: any;
  permissionid: any;
}

interface item1 {
  Sno: any;
  InstituteName: any;
  InstituteId: any;
  Branchcode: any;
  BranchId: any;
  ProgramName: any;
  ProgramId: any;
  child: any;
  sectionid: any;
}

@Component({
  selector: 'app-listuser',
  templateUrl: './listuser.component.html',
  styleUrls: ['./listuser.component.css']
})
export class ListuserComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  userlist = [];
  myform: FormGroup;
  submitted = false;
  loadTable: boolean;
  subjectid: any;
  create: any;
  edit: any;
  del: any;

  rolelist = [];
  roledata = [];
  permissionlist = [];
  branchlist = [];
  programlist = [];
  institutelist = [];
  sectionlist = [];
  selectedSection = [];
  institute_id: any;
  institute_name: any;
  selectedinstitute: any;
  selectedbranch: any;
  selectedprogram: any;
  orderItem: item;
  orderItem1: item1;
  items: Array<item>;
  items1: Array<item1>;
  role_id: any;
  role_name: any;
  roleSelect: any;
  selectedPermission = [];
  branch_id: any;
  branch_name: any;
  program_id: any;
  program_name: any;
  user_id: any;
  selected_roletype: any;
  section_all: boolean;
  section_institute: boolean;
  section_branch: boolean;
  // section_program: boolean;
  role_section: boolean;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiservice: api, private swalservice: swal) {
    this.items = [];
    this.items1 = [];
    this.myform = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.pattern("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$")]],
      confirm_password: ['', [Validators.pattern("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$")]],
      role: [''],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.email]],
      institute: [''],
      branch: [''],
      program: [''],
      role_type: ['', Validators.required]
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }

  load() {
    this.loadTable = false;
    let mytype = "List_User";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_User", data);
        if (data.result == 1) {
          this.userlist = data.data;
          this.loadTable = true;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype1 = "Getpermission_user";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "user_id": this.userid,
      "menu_id": 3,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("Getpermission_user", data);
        if (data.result == 1) {
          this.create = !data.is_create;
          this.edit = !data.is_write;
          this.del = !data.is_delete;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    this.items = [];
    this.items1 = [];
    this.branchlist = [];
    this.rolelist = [];
    this.institutelist = [];
    let mytype2 = "List_ActiveRole";
    let mydata2 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata2, mytype2)
      .subscribe((data: any) => {
        console.log("List_ActiveRole", data);
        if (data.result == 1) {
          this.rolelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
    let mytype3 = "ListInstitute";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("ListInstitute", data);
        if (data.result == 1) {
          this.institutelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  add() {
    this.router.navigateByUrl("AddUser");
  }
  get f() {
    return this.myform.controls;
  }
  get Email() {
    return this.myform.get('email');
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.myform.value.password != this.myform.value.confirm_password) {
      this.swalservice.warningalert("confirm password not match");
      return;
    }
    if (this.selected_roletype != "Institution Admin" && this.selected_roletype != "Branch Admin" && this.selected_roletype != "Staff") {
      if (this.items.length == 0) {
        this.swalservice.warningalert("add permissions");
        return;
      }
    }
    if (this.items1.length == 0 && this.selected_roletype != "Backend Only") {
      this.swalservice.warningalert("add section");
      return;
    }
    if (this.myform.value.password == "" || this.myform.value.password == undefined) {
      this.myform.controls['password'].setValue('0');
    }
    var per = false;
    var sec = false;
    let mytype = "Edit_User";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "UserId": this.user_id,
      "Firstname": this.myform.value.firstname,
      "Lastname": this.myform.value.lastname,
      "Username": this.myform.value.username,
      "Password": this.myform.value.password,
      "Phone": this.myform.value.phone,
      "Email": this.myform.value.email,
      "RoleType": this.myform.value.role_type,
      "user_id": this.userid,
    }
    if (this.selected_roletype != "Institution Admin" && this.selected_roletype != "Branch Admin" && this.selected_roletype != "Staff") {
      mydata["role_data"] = [];
      this.items.forEach(item => {
        var child = {};
        child["RoleId"] = item.RoleId;
        let permissiondata = {};
        if (item.child.length == 0) {
          this.swalservice.warningalert("add permission for given role");
          per = true;
          return;
        }
        item.child.forEach(item => {
          if (permissiondata["permission_id"]) {
            permissiondata["permission_id"] = permissiondata["permission_id"] + "," + item.PermissionId;
          } else {
            permissiondata["permission_id"] = item.PermissionId;
          }
        });
        child["PermissionId"] = permissiondata["permission_id"];
        mydata["role_data"].push(child);
      });
    }
    if (per == false && this.selected_roletype != "Backend Only") {
      mydata["section_data"] = [];
      this.items1.forEach(item1 => {
        if (item1.ProgramId == "" || item1.ProgramId == undefined) {
          item1.ProgramId = '0';
        }
        var child = {};
        child["InstituteId"] = item1.InstituteId;
        child["BranchId"] = item1.BranchId;
        child["ProgramId"] = item1.ProgramId;
        let seciondata = {};
        if (this.selected_roletype != "Institution Admin" && this.selected_roletype != "Branch Admin") {
          item1.child.forEach(item => {
            let sectionid;
            if (item.section_id == undefined) {
              sectionid = item.SectionId;
            } else if (item.SectionId == undefined) {
              sectionid = item.section_id;
            }
            if (seciondata["section_id"]) {
              seciondata["section_id"] = seciondata["section_id"] + "," + sectionid;
            } else {
              seciondata["section_id"] = sectionid;
            }
          });
        }
        if (seciondata["section_id"] == 0 || seciondata["section_id"] == undefined) {
          seciondata["section_id"] = "0";
        }
        child["SectionId"] = seciondata["section_id"];
        mydata["section_data"].push(child);
      });
    }
    if (per == false && sec == false) {
      this.apiservice.apipost(mydata, mytype)
        .subscribe((data: any) => {
          console.log("AddUser", data);
          if (data.result == 1) {
            this.swalservice.successalert(data.msg);
            this.submitted = false;
            this.myform.reset();
            this.ngOnInit();
          } else {
            this.swalservice.warningalert(data.msg);
          }
        });
    }
  }
  delete(id, status, msgtext, confirm) {
    Swal.fire({
      title: 'Are you sure?',
      text: msgtext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let mytype = "DeleteUser";
        let mydata = {
          "secret_key": "2020140715947422055f0dd5bd3ba8f",
          "userid": id,
          "status": status,
          "user_id": this.userid,
        }
        this.apiservice.apipost(mydata, mytype)
          .subscribe((data: any) => {
            console.log("DeleteUser", data);
            if (data.result == 1) {
              this.swalservice.successalert(data.msg);
              this.load();
              this.submitted = false;
              this.myform.reset();
            } else {
              this.swalservice.warningalert(data.msg);
            }
          });
      }
    });
  }
  open(data) {
    this.user_id = data.user_id;
    this.myform.controls['firstname'].setValue(data.first_name);
    this.myform.controls['lastname'].setValue(data.last_name);
    this.myform.controls['username'].setValue(data.username);
    this.myform.controls['phone'].setValue(data.phone_number);
    this.myform.controls['email'].setValue(data.email);
    this.myform.controls['role_type'].setValue(data.RoleType);
    this.selected_roletype = data.RoleType;
    if (data.RoleType == "Institution Admin") {
      this.section_all = false;
      this.section_branch = false;
      this.section_institute = true;
      this.role_section = false;
    } else if (data.RoleType == "Branch Admin") {
      this.section_all = false;
      this.section_branch = true;
      this.section_institute = false;
      this.role_section = false;
    } else if (data.RoleType == "Staff") {
      this.section_all = true;
      this.section_branch = false;
      this.section_institute = false;
      this.role_section = false;
    } else if (data.RoleType == "Backend Only") {
      this.section_all = false;
      this.section_branch = false;
      this.section_institute = false;
      this.role_section = true;
    } else {
      this.section_all = true;
      this.section_branch = false;
      this.section_institute = false;
      this.role_section = true;
    }
    let mytype = "GetUser_Child";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "UserId": this.user_id,
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("GetUser_Child", data);
        if (data.result == 1) {
          this.items = data.data.Role;
          if (this.items != undefined) {
            let index1 = 0;
            this.items.forEach(element => {
              index1 = index1 + 1;
              element.sno = index1;
            });
          }
          this.items1 = data.data.Section[0];
          if (this.items1 != undefined) {
            let index = 0;
            this.items1.forEach(element => {
              index = index + 1;
              element.Sno = index;
            });
          }

          if (this.items != undefined) {
            this.items.forEach(element => {
              let mytype1 = "GetRole_Child";
              let mydata1 = {
                "secret_key": "2020140715947422055f0dd5bd3ba8f",
                "RoleId": element.RoleId,
              }
              this.apiservice.apipost(mydata1, mytype1)
                .subscribe((data: any) => {
                  console.log("GetRole_Child", data);
                  if (data.result == 1) {
                    this.permissionlist = data.data;
                  } else {
                    this.swalservice.warningalert(data.msg);
                  }
                });
            });
          }
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  select_roletype() {
    this.items1 = [];
    this.items = [];
    this.selected_roletype = this.myform.value.role_type;
    if (this.selected_roletype == "Institution Admin") {
      this.section_all = false;
      this.section_branch = false;
      this.section_institute = true;
      this.role_section = false;
    } else if (this.selected_roletype == "Branch Admin") {
      this.section_all = false;
      this.section_branch = true;
      this.section_institute = false;
      this.role_section = false;
    } else if (this.selected_roletype == "Staff") {
      this.section_all = true;
      this.section_branch = false;
      this.section_institute = false;
      this.role_section = false;
    } else if (this.selected_roletype == "Backend Only") {
      this.section_all = false;
      this.section_branch = false;
      this.section_institute = false;
      this.role_section = true;
    } else {
      this.section_all = true;
      this.section_branch = false;
      this.section_institute = false;
      this.role_section = true;
    }
  }
  searchbranch() {
    this.institute_id = this.selectedinstitute.InstituteId;
    this.institute_name = this.selectedinstitute.InstituteName;
    this.branchlist = [];
    let mytype1 = "GetBranchByInstituteid";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "InstituteId": this.selectedinstitute.InstituteId,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetBranchByInstituteid", data);
        if (data.result == 1) {
          this.branchlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchprogram() {
    this.branch_id = this.selectedbranch.branch_id;
    this.branch_name = this.selectedbranch.branch;
    this.programlist = [];
    this.sectionlist = [];
    let mytype1 = "GetProgram_Branchid";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "BranchId": this.selectedbranch.branch_id,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetProgram_Branchid", data);
        if (data.result == 1) {
          this.programlist = data.data;
          this.sectionlist = data.section;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  searchsection() {
    this.program_id = this.selectedprogram.program_id;
    this.program_name = this.selectedprogram.program_name;
    // this.sectionlist = [];
    // let mytype1 = "GetSection_Programid";
    // let mydata1 = {
    //   "secret_key": "2020140715947422055f0dd5bd3ba8f",
    //   "ProgramId": this.selectedprogram.program_id,
    // }
    // this.apiservice.apipost(mydata1, mytype1)
    //   .subscribe((data: any) => {
    //     console.log("GetSection_Programid", data);
    //     if (data.result == 1) {
    //       this.sectionlist = data.data;
    //     } else {
    //       this.swalservice.warningalert(data.msg);
    //     }
    //   });
  }
  selectrole() {
    this.role_id = this.roleSelect.id;
    this.role_name = this.roleSelect.RoleName;
    let mytype1 = "GetRole_Child";
    let mydata1 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "RoleId": this.role_id,
    }
    this.apiservice.apipost(mydata1, mytype1)
      .subscribe((data: any) => {
        console.log("GetRole_Child", data);
        if (data.result == 1) {
          this.permissionlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  addItems() {
    if (this.role_id != "") {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        RoleName: this.role_name,
        RoleId: this.role_id,
        child: this.selectedPermission,
        sno: index,
      };
      this.items.push(this.orderItem);
      this.myform.controls['role'].setValue("");
      this.selectedPermission = [];
      this.role_id = "";
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].sno === sno) {
        this.items.splice(i, 1);
      }
    }
    if (this.items != undefined) {
      let index1 = 0;
      this.items.forEach(element => {
        index1 = index1 + 1;
        element.sno = index1;
      });
    }
  }
  addItems1() {

    if (this.selected_roletype == "Institution Admin") {
      if (this.items1.length == 0) {
        if (this.institute_id != undefined) {
          let index = 1;
          this.items1.forEach(element => {
            index = index + 1;
          });
          this.orderItem1 = <item1>{
            InstituteName: this.institute_name,
            InstituteId: this.institute_id,
            BranchId: '0',
            ProgramId: '0',
            child: '0',
            Sno: index,
          };
          this.items1.push(this.orderItem1);
          this.myform.controls['institute'].setValue("");
          this.institute_id = "";
        }
        else {
          this.swalservice.warningalert("Fill Details");
        }
      } else {
        this.swalservice.warningalert("added only one institution/user");
      }
    } else if (this.selected_roletype == "Branch Admin") {
      if (this.institute_id != undefined && this.branch_id != undefined) {
        let index = 1;
        this.items1.forEach(element => {
          index = index + 1;
        });
        this.orderItem1 = <item1>{
          InstituteName: this.institute_name,
          InstituteId: this.institute_id,
          Branchcode: this.branch_name,
          BranchId: this.branch_id,
          ProgramId: '0',
          child: '0',
          Sno: index,
        };
        this.items1.push(this.orderItem1);
        this.myform.controls['institute'].setValue("");
        this.myform.controls['branch'].setValue("");
        this.institute_id = "";
        this.branch_id = "";
      }
      else {
        this.swalservice.warningalert("Fill Details");
      }
    }
    else {
      if (this.institute_id != undefined && this.branch_id != undefined && this.program_id != undefined) {
        let index = 1;
        this.items1.forEach(element => {
          index = index + 1;
        });
        this.orderItem1 = <item1>{
          InstituteName: this.institute_name,
          InstituteId: this.institute_id,
          Branchcode: this.branch_name,
          BranchId: this.branch_id,
          ProgramName: this.program_name,
          ProgramId: this.program_id,
          child: this.selectedSection,
          Sno: index,
        };
        this.items1.push(this.orderItem1);
        this.myform.controls['institute'].setValue("");
        this.myform.controls['branch'].setValue("");
        this.myform.controls['program'].setValue("");
        this.selectedSection = [];
        this.institute_id = "";
        this.branch_id = "";
        this.program_id = "";
      }
      else {
        this.swalservice.warningalert("Fill Details");
      }
    }
  }
  remove1(Sno) {
    for (let i = 0; i < this.items1.length; ++i) {
      if (this.items1[i].Sno === Sno) {
        this.items1.splice(i, 1);
      }
    }
    if (this.items1 != undefined) {
      let index = 0;
      this.items1.forEach(element => {
        index = index + 1;
        element.Sno = index;
      });
    }
  }
}
