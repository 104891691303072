<div class="wrapper">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Create Role</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Create Role</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Role</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Collapse">
                    <i class="fas fa-minus"></i></button>
                </div>
              </div>
              <div class="card-body">
                <form [formGroup]="myform">
                  <div class="form-group">
                    <label for="inputName">Role Name</label>
                    <input type="text" id="inputName" class="form-control" formControlName="name"
                      [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">Role Name is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="inputDescription">Role Definition</label>
                    <textarea id="inputDescription" class="form-control" rows="4" formControlName="definition"
                      [ngClass]="{ 'is-invalid': submitted && f.definition.errors }"></textarea>
                    <div *ngIf="submitted && f.definition.errors" class="invalid-feedback">
                      <div *ngIf="f.definition.errors.required">Role is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <!-- <label>Permission</label>
                    <select class="form-control custom-select" formControlName="permission">
                      <option *ngFor='let option of permissionlist' [value]="option.PermissionId">
                        {{option.PermissionName}}</option>
                    </select> -->
                    <label>Permission</label>
                    <ng-select [items]="permissionlist" bindLabel="PermissionName"
                        placeholder="Select Permission" appendTo="body" multiple="true"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedPermission">
                    </ng-select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <input type="submit" value="Create new Role" class="btn btn-success" (click)="submit()">
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
  <aside class="control-sidebar control-sidebar-dark">
  </aside>
</div>