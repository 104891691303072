<div class="wrapper">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Create Program</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Create Program</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <form [formGroup]="myform">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="inputName">Program Name</label>
                <input type="text" id="" class="form-control" formControlName="program_name"
                  [ngClass]="{ 'is-invalid': submitted && f.program_name.errors }">
                <div *ngIf="submitted && f.program_name.errors" class="invalid-feedback">
                  <div *ngIf="f.program_name.errors.required">Program Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="inputDescription">Total Duration</label>
                <div class="ngx-timepicker-field-example">
                  <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'" formControlName="total_duration"
                    [ngClass]="{ 'is-invalid': submitted && f.total_duration.errors }"></ngx-timepicker-field>
                </div>
                <div *ngIf="submitted && f.total_duration.errors" class="invalid-feedback">
                  <div *ngIf="f.total_duration.errors.required">Total Duration is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-4">

            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">Add Subject </h3>
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                      title="Collapse">
                      <i class="fas fa-minus"></i></button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                        <label for="">Subject</label>
                        <select class="form-control custom-select" [(ngModel)]="mySelect" (change)="selectsubject()"
                          formControlName="subject">
                          <option *ngFor='let option of subjectlist' [ngValue]="option">{{option.SubjectCode}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="">Duration</label>
                        <div class="ngx-timepicker-field-example">
                          <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'" formControlName="duration">
                          </ngx-timepicker-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 offset-md-1 mt-4">
                      <input type="submit" value="Add" class="btn btn-info" (click)="addItems()">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th style="display: none;">#</th>
                            <th>Subject</th>
                            <th style="display: none;">Subject Id</th>
                            <th>Duration</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of items;let i= index">
                            <td style="display: none;">{{item.sno}}</td>
                            <td>{{item.subject}}</td>
                            <td style="display: none;">{{item.subjectid}}</td>
                            <td>
                              <div class="ngx-timepicker-field-example">
                                <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'"
                                  [ngModelOptions]="{standalone: true}" [(ngModel)]="item.duration">
                                </ngx-timepicker-field>
                              </div>
                            </td>
                            <td class="project-actions text-right">
                              <a class="btn btn-danger btn-sm" (click)="remove(item.sno)" style="color: white;">
                                <i class="fas fa-trash">
                                </i>
                                Remove
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <input type="submit" value="Save" class="btn btn-success" (click)="submit()">
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
  <aside class="control-sidebar control-sidebar-dark">
  </aside>
</div>