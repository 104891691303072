import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../Api/api.service';
import { Router } from '@angular/router';
import { swal } from 'src/app/Common-Service/swal.service';
import { User } from '../Model/user';

interface item {
  sno: any;
  title: any;
  description: any;
  order_by: any;
}

@Component({
  selector: 'app-addmonth',
  templateUrl: './addmonth.component.html',
  styleUrls: ['./addmonth.component.css']
})
export class AddmonthComponent implements OnInit {

  capricsAdminUser: User;
  userid: any;
  myform: FormGroup;
  submitted = false;
  orderItem: item;
  items: Array<item>;
  mySelect: any;
  acSelect: any;
  pgSelect: any;
  academic_id: any;
  program_id: any;
  theme_id: any;
  themelist = [];
  academiclist = [];
  programlist = [];
  academic_code: any;
  program_name: any;

  constructor(private formBuilder: FormBuilder, private apiservice: api, private router: Router, private swalservice: swal) {
    this.items = [];
    this.myform = this.formBuilder.group({
      theme: ['', Validators.required],
      orderby: ['', Validators.required],
      academic: [''],
      program: [''],
      title: [''],
      description: [''],
      order_by: ['']
    });
    this.capricsAdminUser = localStorage.getItem('CapricsAdminUser') ? JSON.parse(localStorage.getItem("CapricsAdminUser")) : undefined;
    if (this.capricsAdminUser != undefined) {
      this.userid = this.capricsAdminUser.Userid;
    }
  }
  load() {
    this.items = [];
    this.academiclist = [];
    let mytype = "List_ActiveAcademic";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
    }
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("List_ActiveAcademic", data);
        if (data.result == 1) {
          this.academiclist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  get f() {
    return this.myform.controls;
  }
  submit() {
    this.submitted = true;
    if (this.myform.invalid) {
      return;
    }
    if (this.theme_id == "" || this.theme_id == undefined) {
      this.swalservice.warningalert("select theme");
      return;
    }
    if (this.items.length == 0) {
      this.swalservice.warningalert("Add Details");
      return;
    }
    let mytype = "AddMonth";
    let mydata = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ThemeId": this.theme_id,
      "AcademicId": this.academic_id,
      "ProgramId": this.program_id,
      "OrderBy": this.myform.value.orderby,
      "user_id": this.userid,
    }
    mydata["child_data"] = [];

    this.items.forEach(item => {
      var child = {};
      child["Sno"] = item.sno;
      child["Title"] = item.title;
      child["Description"] = item.description;
      child["OrderBy"] = item.order_by;
      mydata["child_data"].push(child);
    });
    this.apiservice.apipost(mydata, mytype)
      .subscribe((data: any) => {
        console.log("AddMonth", data);
        if (data.result == 1) {
          this.swalservice.successalert(data.msg);
          this.submitted = false;
          this.myform.reset();
          this.load();
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  ngOnInit(): void {
    this.load();
  }
  addItems() {
    if (this.myform.value.title != "" && this.myform.value.description != "" && this.myform.value.order_by != "") {
      let index = 1;
      this.items.forEach(element => {
        index = index + 1;
      });
      this.orderItem = <item>{
        title: this.myform.value.title,
        description: this.myform.value.description,
        order_by: this.myform.value.order_by,
        sno: index,
      };
      this.items.push(this.orderItem);
      this.myform.controls['title'].setValue("");
      this.myform.controls['description'].setValue("");
      this.myform.controls['order_by'].setValue("");
    }
    else {
      this.swalservice.warningalert("Fill Details");
    }
  }
  remove(sno) {
    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].sno === sno) {
        this.items.splice(i, 1);
      }
    }
  }
  selectacademic() {
    this.academic_id = this.acSelect.AcademicId;
    this.academic_code = this.acSelect.AcademicCode;
    this.programlist = [];
    let mytype3 = "GetProgramByAcademic";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "AcademicCode": this.academic_code,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetProgramByAcademic", data);
        if (data.result == 1) {
          this.programlist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selectprogram() {
    this.program_id = this.pgSelect.ProgramId;
    this.program_name = this.pgSelect.ProgramName;
    this.themelist = [];
    let mytype3 = "GetThemeByProgram";
    let mydata3 = {
      "secret_key": "2020140715947422055f0dd5bd3ba8f",
      "ProgramName": this.program_name,
    }
    this.apiservice.apipost(mydata3, mytype3)
      .subscribe((data: any) => {
        console.log("GetThemeByProgram", data);
        if (data.result == 1) {
          this.themelist = data.data;
        } else {
          this.swalservice.warningalert(data.msg);
        }
      });
  }
  selecttheme() {
    this.theme_id = this.mySelect.ThemeId;
  }
}
