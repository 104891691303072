<div class="wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Create Branch</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Create Branch</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Add</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                        data-toggle="tooltip" title="Collapse">
                                        <i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="myform">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Branch Name</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.branch.errors }"
                                                    class="form-control" formControlName="branch">
                                                <div *ngIf="submitted && f.branch.errors" class="invalid-feedback">
                                                    <div *ngIf="f.branch.errors.required">Branch is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Branch Code</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.branch_code.errors }"
                                                    class="form-control" formControlName="branch_code">
                                                <div *ngIf="submitted && f.branch_code.errors" class="invalid-feedback">
                                                    <div *ngIf="f.branch_code.errors.required">Branch Code is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Start Date</label>
                                                <div class="input-group date" id="reservationdate"
                                                    data-target-input="nearest">
                                                    <input type="text" formControlName="start_date"
                                                        [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                                        class="form-control datetimepicker-input"
                                                        data-target="#reservationdate" />
                                                    <div class="input-group-append" data-target="#reservationdate"
                                                        data-toggle="datetimepicker">
                                                        <div class="input-group-text"><i class="fa fa-calendar"></i>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="submitted && f.start_date.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.start_date.errors.required">Start Date is
                                                            required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Institution Name</label>
                                                <select class="form-control custom-select"
                                                    formControlName="institution_name" [(ngModel)]="instituteSelect"
                                                    [ngClass]="{ 'is-invalid': submitted && f.institution_name.errors }"
                                                    (change)="selectInstitute()">
                                                    <option *ngFor="let option of institutelist" [ngValue]="option">
                                                        {{option.InstituteName}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.institution_name.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.institution_name.errors.required">Institution Name is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email ID</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                    class="form-control" formControlName="email">
                                                <div *ngIf="Email.errors?.email" class="alert alert-danger">
                                                    Email not valid.
                                                </div>
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Alternate Email ID</label>
                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.alter_email.errors }"
                                                    formControlName="alter_email">
                                                <div *ngIf="AlterEmail.errors?.alter_email" class="alert alert-danger">
                                                    Alternate Email not valid.
                                                </div>
                                                <div *ngIf="submitted && f.alter_email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.alter_email.errors.required">Alternate Email is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Contact No</label>
                                                <input type="text" id="inputName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                                    class="form-control" formControlName="phone">
                                                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                    <div *ngIf="f.phone.errors.required">Contact Number is required
                                                    </div>
                                                </div>
                                                <div *ngIf="f.phone.touched && f.phone.invalid"
                                                    class="alert alert-danger">
                                                    <div *ngIf="f.phone.errors.required">Contact Number is required.
                                                    </div>
                                                    <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Phone
                                                        Number.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <textarea class="form-control" rows="3" formControlName="address"
                                                    [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                                    placeholder="Enter Address"></textarea>
                                                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                                    <div *ngIf="f.address.errors.required">Address is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color-palette-set">
                                        <div class="bg-light color-palette">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label for="">Program</label>
                                                        <select class="form-control custom-select"
                                                            [(ngModel)]="mySelect" (change)="selectprogram()"
                                                            formControlName="program">
                                                            <option *ngFor='let option of programlist'
                                                                [ngValue]="option">
                                                                {{option.ProgramName}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="">Section</label>
                                                        <ng-select [items]="sectionlist" bindLabel="ClassCode"
                                                            placeholder="Select Section" appendTo="body" multiple="true"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="selectedSection">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 offset-md-1 mt-4">
                                                    <input type="submit" value="Add" class="btn btn-info"
                                                        (click)="addItems()">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th style="display: none;">#</th>
                                                                <th>Program</th>
                                                                <th style="display: none;">Program Id</th>
                                                                <th>Section</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of items;let i= index">
                                                                <td style="display: none;">{{item.sno}}</td>
                                                                <td>{{item.program}}</td>
                                                                <td style="display: none;">{{item.programid}}</td>
                                                                <td>
                                                                    <ng-select [items]="sectionlist"
                                                                        bindLabel="ClassCode"
                                                                        placeholder="Select Section" appendTo="body"
                                                                        multiple="true"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [(ngModel)]="item.section">
                                                                    </ng-select>
                                                                </td>
                                                                <td class="project-actions text-right">
                                                                    <a class="btn btn-danger btn-sm"
                                                                        (click)="remove(item.sno)"
                                                                        style="color: white;">
                                                                        <i class="fas fa-trash">
                                                                        </i>
                                                                        Remove
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="Add Branch" class="btn btn-success" (click)="submit()">
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>