<div class="wrapper">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Create Academic</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Create Academic</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Academic</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Collapse">
                    <i class="fas fa-minus"></i></button>
                </div>
              </div>
              <div class="card-body">
                <form [formGroup]="myform">
                  <div class="form-group">
                    <label for="inputName">Academic Code</label>
                    <input type="text" id="inputName" class="form-control" formControlName="code"
                      [ngClass]="{ 'is-invalid': submitted && f.code.errors }">
                    <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                      <div *ngIf="f.code.errors.required">Academic Code is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="inputDescription">From Year</label>
                    <select class="form-control custom-select" formControlName="year_from"
                      [ngClass]="{ 'is-invalid': submitted && f.year_from.errors }">
                      <option selected disabled>Select From Year</option>
                      <option value="2026">2026</option>
                      <option value="2025">2025</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                    </select>
                    <div *ngIf="submitted && f.year_from.errors" class="invalid-feedback">
                      <div *ngIf="f.year_from.errors.required">From Year is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="inputDescription">To Year</label>
                    <select class="form-control custom-select" formControlName="year_to"
                      [ngClass]="{ 'is-invalid': submitted && f.year_to.errors }">
                      <option selected disabled>Select To Year</option>
                      <option value="2026">2026</option>
                      <option value="2025">2025</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                    </select>
                    <div *ngIf="submitted && f.year_to.errors" class="invalid-feedback">
                      <div *ngIf="f.year_to.errors.required">To Year is required</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input type="submit" value="Create new Academic" class="btn btn-success" (click)="submit()">
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
  <aside class="control-sidebar control-sidebar-dark">
  </aside>
</div>